import React, { FC } from "react";
import { Typography, useTheme } from "utils/material-ui-core";
import useTypographyStyles from "hooks/useTypographyStyles";
import { usePageContent } from "customProviders/LocationProvider";

export interface AddToCartErrorMessageProps {
  errorMessage?: string;
}

export const AddToCartErrorMessage: FC<AddToCartErrorMessageProps> = ({
  errorMessage
}) => {
  const theme = useTheme();
  const typographyStyles = useTypographyStyles({
    keys: ["rhBaseBody1"]
  });
  const { pageContent } = usePageContent();

  return (
    <section style={{ minHeight: theme.spacing(14) }}>
      <Typography
        id={"dialog-add-to-cart_error"}
        data-testid={"dialog-add-to-cart_error"}
        align="center"
        style={{ paddingTop: theme.spacing(4) }}
        className={typographyStyles.rhBaseBody1}
        color="error"
      >
        {errorMessage ? errorMessage : pageContent?.SOMETHING_WRONG_TRY_AGAIN}
      </Typography>
    </section>
  );
};

AddToCartErrorMessage.defaultProps = {};

export default AddToCartErrorMessage;
