import React, { FC } from "react";
import { DialogContent, useMediaQuery, Theme } from "utils/material-ui-core";
import { DialogProps } from "@material-ui/core/Dialog";
import SwatchDetail from "component-swatch-detail";
import RHDialogTitle from "component-rh-dialog-title";
import memoize from "utils/memoize";
import Drawer from "component-drawer";
import TailwindDrawer from "@RHCommerceDev/component-tailwind-drawer";
import { useEnv } from "hooks/useEnv";
import yn from "yn";

export interface SwatchDetailDialogContentProps {
  swatch?: ProductSwatch;
  productSwatchImage: Maybe<ProductImage>;
  onClose?: (event: {}, reason: "backdropClick" | "escapeKeyDown") => void;
}

export const SwatchDetailDialogContent: FC<SwatchDetailDialogContentProps> = ({
  swatch,
  productSwatchImage,
  onClose
}) => {
  if (!swatch) {
    return null;
  }

  return (
    <>
      <RHDialogTitle
        title={swatch?.title}
        onClose={event => onClose?.(event, "escapeKeyDown")}
      />
      <DialogContent style={{ overflow: "auto" }}>
        <SwatchDetail
          swatch={swatch}
          productSwatchImage={productSwatchImage}
          onSelect={() => onClose?.({}, "backdropClick")}
        />
      </DialogContent>
    </>
  );
};

SwatchDetailDialogContent.defaultProps = {};

export default memoize(
  ({
    swatch,
    productSwatchImage,
    ...rest
  }: SwatchDetailDialogContentProps & Omit<DialogProps, "children">) => {
    const mdUp = useMediaQuery<Theme>(theme => theme.breakpoints.up("md"));
    const env = useEnv();
    const FEATURE_TAILWIND_COMPONENTS = yn(env.FEATURE_TAILWIND_COMPONENTS);
    const DrawerComponent = FEATURE_TAILWIND_COMPONENTS
      ? TailwindDrawer
      : Drawer;
    return (
      <DrawerComponent
        anchor="right"
        {...rest}
        BackdropProps={{ invisible: true }}
        PaperProps={{
          ...rest.PaperProps,
          style: {
            width: mdUp ? "50%" : "100%",
            ...rest.PaperProps?.style
          }
        }}
      >
        <SwatchDetailDialogContent
          swatch={swatch}
          onClose={rest.onClose}
          productSwatchImage={productSwatchImage}
        />
      </DrawerComponent>
    );
  }
);
