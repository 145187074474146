import React from "react";
import {
  Grid,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  makeStyles,
  Theme,
  createStyles
} from "utils/material-ui-core";
import classNames from "classnames";
import { usePageContent } from "customProviders/LocationProvider";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    textCenter: { textAlign: "center" },
    membersButtonSpacing: {
      marginBottom: theme.typography.pxToRem(12)
    },
    cellSize: {
      padding: `${theme.typography.pxToRem(14.4)} 0`
    },
    cellBorder: {
      borderBottom: "1px solid #F5F5F5"
    },
    bodySpacing: {
      marginTop: theme.typography.pxToRem(20),
      marginBottom: theme.typography.pxToRem(30),
      [theme.breakpoints.down("sm")]: {
        marginTop: theme.typography.pxToRem(20),
        marginBottom: theme.typography.pxToRem(40)
      }
    },
    textSpacing: {
      marginBottom: theme.typography.pxToRem(22)
    },
    link: {
      textDecoration: "underline",
      color: "#666666"
    }
  })
);

const ShipmentsToCanadaContent = () => {
  const classes = useStyles();
  const { pageContent } = usePageContent();

  return (
    <Grid item>
      <Typography variant="h3">
        {pageContent?.shippingDialog?.shipmentsToCanada?.header1}
      </Typography>
      <Grid item className={classNames([classes.bodySpacing])}>
        <Typography
          variant="body1"
          className={classNames([classes.textSpacing])}
        >
          {pageContent?.shippingDialog?.shipmentsToCanada?.bodyText1}
        </Typography>
        <Typography
          variant="body1"
          className={classNames([classes.textSpacing])}
        >
          {pageContent?.shippingDialog?.shipmentsToCanada?.bodyText2}
        </Typography>
        <Typography
          variant="body1"
          className={classNames([classes.textSpacing])}
        >
          {pageContent?.shippingDialog?.shipmentsToCanada?.bodyText3}
        </Typography>
        <Typography
          variant="body1"
          className={classNames([classes.textSpacing])}
        >
          {pageContent?.shippingDialog?.shipmentsToCanada?.bodyText4}
        </Typography>
        <Typography
          variant="body1"
          className={classNames([classes.textSpacing])}
        >
          {pageContent?.shippingDialog?.shipmentsToCanada?.bodyText10}
        </Typography>
      </Grid>
      <Typography variant="h3">
        {pageContent?.shippingDialog?.shipmentsToCanada?.header2}
      </Typography>
      <Typography variant="h3">
        {pageContent?.shippingDialog?.shipmentsToCanada?.header2_1}
      </Typography>
      <Grid item className={classNames([classes.bodySpacing])}>
        <Typography
          variant="body1"
          className={classNames([classes.textSpacing])}
        >
          {pageContent?.shippingDialog?.shipmentsToCanada?.bodyText5}
        </Typography>
        <Typography
          variant="body1"
          className={classNames([classes.textSpacing])}
        >
          {pageContent?.shippingDialog?.shipmentsToCanada?.bodyText6}
        </Typography>
      </Grid>
      <TableContainer className={classNames([classes.bodySpacing])}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell
                className={classNames([classes.cellSize, classes.cellBorder])}
              >
                <Typography variant="h5">
                  {pageContent?.shippingDialog?.shipmentsToCanada?.header4}
                </Typography>
              </TableCell>
              <TableCell
                align="right"
                className={classNames([classes.cellSize, classes.cellBorder])}
              >
                <Typography variant="h5">
                  {pageContent?.shippingDialog?.shipmentsToCanada?.header5}
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {pageContent?.shippingDialog?.shipmentsToCanada?.UFDContentRows?.map(
              (row, idx) => (
                <TableRow key={idx}>
                  <TableCell
                    component="th"
                    scope="row"
                    className={classNames([
                      classes.cellSize,
                      classes.cellBorder
                    ])}
                  >
                    <Typography variant="body1">{row.col1}</Typography>
                  </TableCell>
                  <TableCell
                    align="right"
                    className={classNames([
                      classes.cellSize,
                      classes.cellBorder
                    ])}
                  >
                    <Typography variant="body1">{row.col2}</Typography>
                  </TableCell>
                </TableRow>
              )
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <Typography variant="h3">
        {pageContent?.shippingDialog?.shipmentsToCanada?.header6}
      </Typography>
      <Typography variant="h3">
        {pageContent?.shippingDialog?.shipmentsToCanada?.header6_1}
      </Typography>
      <Grid item className={classNames([classes.bodySpacing])}>
        <Typography variant="body1">
          {pageContent?.shippingDialog?.shipmentsToCanada?.bodyText7}
        </Typography>
      </Grid>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell
                className={classNames([classes.cellSize, classes.cellBorder])}
              >
                <Typography variant="h5">
                  {pageContent?.shippingDialog?.shipmentsToCanada?.header8}
                </Typography>
              </TableCell>
              <TableCell
                align="right"
                className={classNames([classes.cellSize, classes.cellBorder])}
              >
                <Typography variant="h5">
                  {pageContent?.shippingDialog?.shipmentsToCanada?.header9}
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {pageContent?.shippingDialog?.shipmentsToCanada?.standardShippingToCanadaContentRows?.map(
              (row, idx) => (
                <TableRow key={idx}>
                  <TableCell
                    component="th"
                    scope="row"
                    className={classNames([
                      classes.cellSize,
                      classes.cellBorder
                    ])}
                  >
                    <Typography variant="body1">{row.col1}</Typography>
                  </TableCell>
                  <TableCell
                    align="right"
                    className={classNames([
                      classes.cellSize,
                      classes.cellBorder
                    ])}
                  >
                    <Typography variant="body1">{row.col2}</Typography>
                  </TableCell>
                </TableRow>
              )
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <Grid item className={classNames([classes.cellSize])}>
        <Typography variant="body1">
          {pageContent?.shippingDialog?.shipmentsToCanada?.bodyText8}
          <a
            className={classNames(classes.link)}
            href="https://rh.com/customer-service/shipping-and-delivery-info.jsp?renderdesktop=true"
            target="_blank"
          >
            {pageContent?.shippingDialog?.shipmentsToCanada?.bodyText9}
          </a>
        </Typography>
      </Grid>
    </Grid>
  );
};

export default ShipmentsToCanadaContent;
