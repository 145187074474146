import React, { FC } from "react";
import {
  Button,
  makeStyles,
  createStyles,
  Theme
} from "utils/material-ui-core";
import { useTheme } from "@material-ui/core";
import { usePageContent } from "customProviders/LocationProvider";
import { memoryStorage } from "utils/analytics/storage";
import { useParams2 } from "hooks/useParams";

export interface PanelAddToCartButtonProps {
  isDisabled: boolean;
  handleAddToCartClick?: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    MuiButton: {
      root: {
        color: "#999999"
      }
    },
    containedPrimary: {
      height: "48px",
      color: "#FFFFFF",
      backgroundColor: "#000000",
      "&:hover": {
        backgroundColor: "#404040",
        borderColor: "#404040"
      }
    }
  })
);

export const PanelAddToCartButton: FC<PanelAddToCartButtonProps> = ({
  handleAddToCartClick,
  isDisabled
}) => {
  const theme = useTheme();

  const { pageContent } = usePageContent();
  const classes = useStyles();
  const { params } = useParams2<{ [key: string]: string }>(
    { productId: "", version: "" },
    { toLowerCase: true }
  );

  const isNewPDPLayOut: boolean =
    params.version === "v2" || memoryStorage.getItem("newPdpLayout");

  return (
    <div
      style={{
        paddingBottom: 80
      }}
    >
      <Button
        id="addon-addToCart"
        variant="contained"
        color="primary"
        onClick={() => {
          handleAddToCartClick?.();
        }}
        className={classes.containedPrimary}
        disabled={isDisabled}
        fullWidth
      >
        {isNewPDPLayOut
          ? pageContent?.ADD_TO_CART
          : pageContent?.AGREE_AND_ADD_TO_CART}
      </Button>
    </div>
  );
};

PanelAddToCartButton.defaultProps = {};

export default PanelAddToCartButton;
