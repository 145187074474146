import React, { FC, MouseEvent } from "react";
import {
  Button,
  Typography,
  Grid,
  makeStyles,
  createStyles,
  Theme,
  Divider
} from "utils/material-ui-core";
import { createMuiTheme } from "@material-ui/core";
import createBreakpoints from "@material-ui/core/styles/createBreakpoints";

import { useTheme } from "@material-ui/core";
import classNames from "classnames";
import useTypographyStyles from "hooks/useTypographyStyles";
import he from "he";
import { ProductSkuPriceInfoProps } from "component-product-sku-price-info/types";
import { usePageContent } from "customProviders/LocationProvider";

export interface RHRSpecialOrderConfirmationProps {
  confirmationText: string;
  onConfirmed: (event: MouseEvent) => void;
  productDisplayName: string;
  options: (ProductAvailableOption | CustomOption)[];
  fullSkuId?: string;
  qty?: number;
  salePriceLabel?: string;
  pricing?: ProductSkuPriceInfoProps["data"];
  SPOAddonVariables?: any;
  setAddedFromPanel?: React.Dispatch<React.SetStateAction<boolean>>;
}

// Breakpoints by the numbers
export const BREAKPOINT_XS = 0;
export const BREAKPOINT_SM = 768;
export const BREAKPOINT_MD = 992;
export const BREAKPOINT_LG = 1200;
export const BREAKPOINT_XL = 1600;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    specialOrderHeaderText: {
      textAlign: "center"
    },
    termsOfUseText: {
      lineHeight: "13.2px",
      textDecoration: "none"
    },
    specialOrderButton: {
      padding: "17px 15px 18px 15px"
    },
    specialOrderNumber: {
      padding: "36px 0 0 12px"
    },
    separator: {
      color: "#DDDDDD",
      width: "100%",
      height: "3px",
      margin: "8px 0 12px 0"
    },
    infoBox: {
      padding: "4px 12px 0 12px !important"
    },
    skuListColumnOne: {
      color: "#999999 !important"
    },
    skuListColumnTwo: {
      color: "#666666 !important"
    }
  })
);

export const RHRSpecialOrderConfirmation: FC<
  RHRSpecialOrderConfirmationProps
> = ({
  confirmationText,
  onConfirmed,
  productDisplayName,
  options,
  fullSkuId,
  qty,
  salePriceLabel = "Sale",
  pricing,
  SPOAddonVariables
}) => {
  const theme = useTheme();
  const typographyClasses = useTypographyStyles({
    keys: [
      "skuList",
      "skuListColumn1",
      "skuListColumn2",
      "rhBaseH2",
      "rhBaseH3",
      "rhBaseBody1",
      "rhBaseBody2",
      "rhBaseCaption",
      "uppercaseText"
    ]
  });

  const rhTextTheme = createMuiTheme({
    breakpoints: createBreakpoints({
      values: {
        xs: BREAKPOINT_XS,
        sm: BREAKPOINT_SM,
        md: BREAKPOINT_MD,
        lg: BREAKPOINT_LG,
        xl: BREAKPOINT_XL
      },
      get up() {
        return (key: number | string) => {
          let values = this.values as any;
          return `@media (min-width: ${values[key]}px)`;
        };
      },
      get down() {
        return (key: number | string) => {
          let values = this.values as any;
          return `@media (max-width: ${values[key] - 0.05}px)`;
        };
      }
    })
  });

  const classes = useStyles();

  const { pageContent } = usePageContent();

  return (
    <div
      id={"dialog-add-to-cart_rhr-special-order-confirmation"}
      style={{
        minHeight: theme.spacing(44),
        paddingBottom: 80,
        overflow: "hidden"
      }}
    >
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography
            className={classNames([
              typographyClasses.rhBaseH3,
              typographyClasses.uppercaseText,
              classes.specialOrderHeaderText
            ])}
          >
            {pageContent?.AGREE_TO_SPECIAL_ORDER_TERMS}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography
                className={`${typographyClasses.rhBaseBody1} ${classes.termsOfUseText}`}
                component="p"
                paragraph
                dangerouslySetInnerHTML={{
                  __html: confirmationText
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Button
                id="rhrSpecialOrderConfirmation_addToCart-btn"
                variant="contained"
                color="primary"
                onClick={onConfirmed}
                fullWidth
                className={classes.specialOrderButton}
              >
                {pageContent?.AGREE_AND_ADD_TO_CART}
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <div>
          <Typography
            className={`${typographyClasses.rhBaseBody1} ${classes.specialOrderNumber}`}
          >
            {pageContent?.ONE_SPECIAL_ORDER}
          </Typography>
        </div>
        <div className={classes.separator}>
          <Divider />
        </div>
        <Grid item xs={12}>
          <Typography
            className={classNames([
              typographyClasses.rhBaseBody2,
              typographyClasses.uppercaseText
            ])}
            dangerouslySetInnerHTML={{
              __html: productDisplayName
            }}
          ></Typography>
        </Grid>
        <Grid item xs={12} className={classes.infoBox}>
          {fullSkuId && (
            <Grid item container spacing={2} key={`item-fullSkuId`}>
              <Grid item xs={3}>
                <Typography
                  className={classNames(
                    typographyClasses.skuList,
                    typographyClasses.skuListColumn1,
                    classes.skuListColumnOne
                  )}
                >
                  {pageContent?.ITEM_NUMBER}
                </Typography>
              </Grid>
              <Grid item xs={9}>
                <Typography
                  className={classNames(
                    typographyClasses.skuList,
                    typographyClasses.skuListColumn2,
                    classes.skuListColumnTwo
                  )}
                >
                  {fullSkuId}
                </Typography>
              </Grid>
            </Grid>
          )}

          {options &&
            options.map((option, index) => (
              <Grid
                item
                container
                spacing={2}
                key={`productOption-${option.value}`}
              >
                <Grid item xs={3}>
                  <Typography
                    className={classNames(
                      typographyClasses.skuList,
                      typographyClasses.skuListColumn1,
                      classes.skuListColumnOne
                    )}
                  >
                    {option.type}
                  </Typography>
                </Grid>
                <Grid item xs={9}>
                  <Typography
                    className={classNames(
                      typographyClasses.skuList,
                      typographyClasses.skuListColumn2,
                      classes.skuListColumnTwo
                    )}
                  >
                    {he.decode(option?.value || "")}
                  </Typography>
                </Grid>
              </Grid>
            ))}
          {qty && (
            <Grid item container spacing={2} key={`item-quantity`}>
              <Grid item xs={3}>
                <Typography
                  className={classNames(
                    typographyClasses.skuList,
                    typographyClasses.skuListColumn1,
                    classes.skuListColumnOne
                  )}
                >
                  {pageContent?.QTY}
                </Typography>
              </Grid>
              <Grid item xs={9}>
                <Typography
                  className={classNames(
                    typographyClasses.skuList,
                    typographyClasses.skuListColumn2,
                    classes.skuListColumnTwo
                  )}
                >
                  {qty}
                </Typography>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
    </div>
  );
};

RHRSpecialOrderConfirmation.defaultProps = {};

export default RHRSpecialOrderConfirmation;
