import React, { FC } from "react";
import {
  Button,
  Grid,
  Typography,
  Theme,
  useTheme,
  FormControl
} from "utils/material-ui-core";
import { useLocalization } from "hooks/useLocalization";
import { createStyles, makeStyles } from "@material-ui/core";
import useTypographyStyles from "hooks/useTypographyStyles";
import classNames from "classnames";
import useButtonStyles from "hooks/useButtonStyles";
import { useEnv } from "hooks/useEnv";
import { usePageContent } from "customProviders/LocationProvider";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      minHeight: theme.spacing(34)
    },
    addedText: {
      textAlign: "center",
      [theme.breakpoints.up("md")]: {
        textAlign: "left"
      }
    },
    keepShoppingStyles: {
      fontSize: "11px !important"
    }
  })
);

export interface ConfirmedProps {
  onClose?: (event: {}, reason: "backdropClick" | "escapeKeyDown") => void;
  qty: number;
  isAddon?: boolean;
}

export const Confirmed: FC<ConfirmedProps> = ({
  qty,
  onClose,
  isAddon = false
}) => {
  const { pageContent } = usePageContent();
  const theme = useTheme();
  const classes = useStyles();
  const prefix = useLocalization();
  const env = useEnv();
  const typographyStyles = useTypographyStyles({
    keys: ["rhBaseBody2", "rhBaseBody1"]
  });
  const buttonStyles = useButtonStyles({
    keys: ["primaryBlackBtn", "secondaryBtn"]
  });
  return (
    <>
      <Grid
        id={"dialog-add-to-cart_confirmed"}
        item
        xs={12}
        style={{
          paddingBottom: theme.spacing(6.5),
          display: "flex",
          justifyContent: "center"
        }}
      >
        <Typography
          component="p"
          style={{ textTransform: "uppercase" }}
          className={classNames([
            classes.addedText,
            env?.FEATURE_PDP
              ? typographyStyles.rhBaseBody1
              : typographyStyles.rhBaseBody2
          ])}
        >
          {`${qty} ${qty > 1 ? pageContent?.ITEMS : pageContent?.ITEM} ${
            pageContent?.ADDED_TO_YOUR_CART
          }`}
        </Typography>
      </Grid>
      <Grid container spacing={isAddon ? 2 : 0}>
        <Grid item xs={isAddon ? 6 : 12}>
          <FormControl margin={"normal"} fullWidth>
            <Button
              id="ajax-proceed-to-cart"
              href={`${prefix}/checkout/shopping_cart.jsp`}
              className={classNames([buttonStyles.primaryBlackBtn])}
              autoFocus
            >
              {pageContent?.VIEW_CART}
            </Button>
          </FormControl>
        </Grid>
        <Grid item xs={isAddon ? 6 : 12}>
          <FormControl margin={"normal"} fullWidth>
            <Button
              id="ajax-continue-shopping"
              className={classNames([
                buttonStyles.secondaryBtn,
                classes.keepShoppingStyles
              ])}
              onClick={event => onClose?.(event, "escapeKeyDown")}
            >
              {pageContent?.KEEP_SHOPPING}
            </Button>
          </FormControl>
        </Grid>
      </Grid>
    </>
  );
};

Confirmed.defaultProps = {};

export default Confirmed;
