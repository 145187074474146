import React from "react";
import {
  Grid,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  makeStyles,
  Theme,
  createStyles
} from "utils/material-ui-core";
import classNames from "classnames";
import { usePageContent } from "customProviders/LocationProvider";
import getCountryFromUrl, { getLanguageFromUrl } from "utils/getCountryFromUrl";
import { europeanCountries } from "resources/countries-config.json";
import useLocale from "hooks/useLocale/useLocale";
import { checkCountryIsEu } from "utils/checkCountryIsEu";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    textCenter: { textAlign: "center" },
    membersButtonSpacing: {
      marginBottom: theme.typography.pxToRem(12)
    },
    cellSize: {
      padding: `${theme.typography.pxToRem(14.4)} 0`
    },
    cellBorder: {
      borderBottom: "1px solid #F5F5F5"
    },
    bodySpacing: {
      marginTop: `${theme.typography.pxToRem(20)} !important`,
      marginBottom: `${theme.typography.pxToRem(30)} !important`,
      [theme.breakpoints.down("sm")]: {
        marginTop: `${theme.typography.pxToRem(20)} !important`,
        marginBottom: `${theme.typography.pxToRem(40)} !important`
      }
    }
  })
);

const FurnitureDeliveryContent = () => {
  const classes = useStyles();
  const { pageContent } = usePageContent();
  const country = getCountryFromUrl();

  const language = getLanguageFromUrl().mapped;
  const locale = useLocale();
  const isNA = !checkCountryIsEu({ country });

  return (
    <Grid item>
      <Typography variant="h3">
        {pageContent?.shippingDialog?.furnitureDelivery?.header1}
      </Typography>
      <Typography variant="body1" className={classNames([classes.bodySpacing])}>
        {europeanCountries.includes(country) && !locale?.startsWith("en")
          ? pageContent?.shippingDialog?.furnitureDelivery?.bodyText2
          : pageContent?.shippingDialog?.furnitureDelivery?.bodyText1}
      </Typography>

      {isNA && (
        <>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell
                    className={classNames([
                      classes.cellSize,
                      classes.cellBorder
                    ])}
                  >
                    <Typography variant="h5">
                      {pageContent?.shippingDialog?.furnitureDelivery?.header3}
                    </Typography>
                  </TableCell>
                  <TableCell
                    align="right"
                    className={classNames([
                      classes.cellSize,
                      classes.cellBorder
                    ])}
                  >
                    <Typography variant="h5">
                      {pageContent?.shippingDialog?.furnitureDelivery?.header4}
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {pageContent?.shippingDialog?.furnitureDelivery?.furnitureDeliveryContentRows?.map(
                  (row, idx) => (
                    <TableRow key={idx}>
                      <TableCell
                        component="th"
                        scope="row"
                        className={classNames([
                          classes.cellSize,
                          classes.cellBorder
                        ])}
                      >
                        <Typography variant="body1">{row.col1}</Typography>
                      </TableCell>
                      <TableCell
                        align="right"
                        className={classNames([
                          classes.cellSize,
                          classes.cellBorder
                        ])}
                      >
                        <Typography variant="body1">{row.col2}</Typography>
                      </TableCell>
                    </TableRow>
                  )
                )}
              </TableBody>
            </Table>
          </TableContainer>

          <Grid item className={classNames([classes.cellSize])}>
            <Typography variant="body1">
              {pageContent?.shippingDialog?.furnitureDelivery?.footer}
            </Typography>
          </Grid>
        </>
      )}
    </Grid>
  );
};

export default FurnitureDeliveryContent;
