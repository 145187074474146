import React, { FC } from "react";
import {
  Dialog,
  DialogTitle,
  IconButton,
  DialogContent,
  useTheme,
  Typography
} from "utils/material-ui-core";
import { DialogProps } from "@material-ui/core/Dialog";
import { useQuery } from "@apollo/client";
import useLocale from "hooks/useLocale/useLocale";
import { querySurchargeInfo } from "graphql-client/queries/surcharge-info";
import htmlToJson, { HtmlJson } from "utils/htmlToJson";
import RHCloseIcon from "icon-close";
import { usePageContent } from "customProviders/LocationProvider";
import { ADDITIONAL_DELIVERY_SURCHARGES } from "resources/page-level-resources-schemas/products.json";

export interface SurchargeInfoDialogContentProps {
  surchargeInfoText: HtmlJson;
  onClose:
    | ((event: {}, reason: "backdropClick" | "escapeKeyDown") => void)
    | undefined;
}

export const SurchargeInfoDialogContent: FC<
  SurchargeInfoDialogContentProps
> = ({ surchargeInfoText, onClose }) => {
  const theme = useTheme();
  const { pageContent } = usePageContent();

  return (
    <>
      <DialogTitle disableTypography>
        <IconButton
          style={{
            position: "absolute",
            top: 0,
            right: 0
          }}
          onClick={event => !!onClose && onClose(event, "escapeKeyDown")}
        >
          <RHCloseIcon data-testid="close-icon" />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <section
          style={{
            marginBottom: theme.spacing(2)
          }}
        >
          <Typography variant="h5">
            {pageContent?.ADDITIONAL_DELIVERY_SURCHARGES ??
              ADDITIONAL_DELIVERY_SURCHARGES}
          </Typography>
          {surchargeInfoText.textContent}
        </section>
      </DialogContent>
    </>
  );
};

SurchargeInfoDialogContent.defaultProps = {};

export default (props: Omit<DialogProps, "children">) => {
  const locale = useLocale();

  const { data: { contentFragment } = {} as Query } = useQuery<Query>(
    querySurchargeInfo,
    { variables: { locale } }
  );

  const surchargeInfoText = htmlToJson(
    contentFragment ? contentFragment.text : ""
  );

  return (
    <Dialog {...props}>
      <SurchargeInfoDialogContent
        onClose={props.onClose}
        surchargeInfoText={surchargeInfoText}
      />
    </Dialog>
  );
};
