import React, { FC, useState, useEffect } from "react";
import { Grid, Theme, makeStyles, createStyles } from "utils/material-ui-core";
import FurnitureDeliveryContent from "./modalContent/FurnitureDeliveryContent";
import StandardShippingContent from "./modalContent/StandardShippingContent";
import ShipmentsToCanadaContent from "./modalContent/ShipmentsToCanadaContent";
import ContractContent from "./modalContent/ContractContent";

import classNames from "classnames";
import FormDialog from "layout-form-dialog";
import { BREAKPOINT_SM } from "utils/constants";
import InnerMenu from "component-inner-menu";
import useTypographyStyles from "hooks/useTypographyStyles";
import { usePageContent } from "customProviders/LocationProvider";
import getCountryFromUrl from "utils/getCountryFromUrl";
import RHShippingDialogTitle from "./components/title";
import { checkCountryIsEu } from "utils/checkCountryIsEu";

const menuItems = (messages: Record<string, any>, country: string) => {
  const commonMenuItems = [
    {
      title: messages?.shippingDialog?.furnitureDelivery?.title,
      id: 0
    },
    {
      title: messages?.shippingDialog?.standardShipping?.title,
      id: 1
    }
  ];

  if (!checkCountryIsEu({ country })) {
    commonMenuItems.push(
      {
        title: messages?.shippingDialog?.shipmentsToCanada?.title,
        id: 2
      },
      {
        title: messages?.shippingDialog?.contract?.title,
        id: 3
      }
    );
  }
  return commonMenuItems;
};

export interface RHShippingDialogProps {
  open: boolean;
  menuIdx: number;
  onCloseModal(): void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    textCenter: { textAlign: "center" },
    fullWidthModal: {
      width: `${BREAKPOINT_SM}px !important`,
      [theme.breakpoints.down("sm")]: {
        width: "100% !important",
        maxWidth: "100% !important",
        margin: "0 !important",
        position: "absolute !important",
        top: "0 !important"
      }
    },
    menuSpacing: {
      marginTop: `${theme.typography.pxToRem(30)} !important`,
      marginBottom: `${theme.typography.pxToRem(30)} !important`,
      [theme.breakpoints.down("sm")]: {
        marginTop: `${theme.typography.pxToRem(20)} !important`,
        marginBottom: `${theme.typography.pxToRem(40)} !important`
      }
    }
  })
);

const RHShippingDialog: FC<RHShippingDialogProps> = ({
  open,
  menuIdx,
  onCloseModal
}) => {
  const shippingDialogClasses = useStyles();
  const { pageContent } = usePageContent();

  const country = getCountryFromUrl();

  const [selectedContent, setSelectedContent] = useState(menuIdx);
  useEffect(() => {
    if (menuIdx !== selectedContent) {
      setSelectedContent(menuIdx);
    }
  }, [menuIdx, setSelectedContent]);

  const handleMenuClick = (id: number) => {
    setSelectedContent(id);
  };

  const contentsCollection = [
    FurnitureDeliveryContent(),
    StandardShippingContent(),
    ShipmentsToCanadaContent(),
    ContractContent()
  ];

  const typographyStyles = useTypographyStyles({
    keys: ["rhBaseH2"]
  });

  return (
    <FormDialog
      onClose={onCloseModal}
      open={open}
      PaperProps={{
        className: shippingDialogClasses.fullWidthModal,
        square: true
      }}
    >
      <Grid container>
        <RHShippingDialogTitle />
        <Grid
          container
          item
          direction="row"
          justify="space-between"
          alignItems="flex-start"
          className={classNames(shippingDialogClasses.menuSpacing)}
        >
          <InnerMenu
            handler={handleMenuClick}
            selectedId={menuIdx}
            items={menuItems(pageContent, country)}
          />
        </Grid>
        <Grid container item>
          {contentsCollection[selectedContent]}
        </Grid>
      </Grid>
    </FormDialog>
  );
};

export default RHShippingDialog;
