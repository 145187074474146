import React, { FC, useCallback, useEffect, useState, useMemo } from "react";
import {
  Typography,
  Button,
  Theme,
  DialogContent,
  Grid,
  useTheme,
  useMediaQuery,
  FormControl
} from "utils/material-ui-core";
import { DrawerProps } from "@material-ui/core/Drawer";
import { addItemToWishlistMutation } from "graphql-client/queries/wishlist";
import { useMutation } from "@apollo/client";
import { createStyles, makeStyles } from "@material-ui/core";
import RHSpinner from "component-rh-spinner";
import { AddToCartErrorMessage } from "dialog-add-to-cart/AddToCartErrorMessage";
import RHDialogTitle from "component-rh-dialog-title";
import { useEnv } from "hooks/useEnv";
import { CustomProductMapper } from "component-custom-product";
import isEmpty from "lodash.isempty";
import useSite from "hooks/useSite";
import { MonogramOrderProps } from "page-monogram-dialog";
import { cleanMonogramOrder } from "utils/monogramUtils";
import { useHistory } from "react-router";
import Drawer from "component-drawer";
import useTypographyStyles from "hooks/useTypographyStyles";
import classNames from "classnames";
import { useIsoCookies } from "hooks/useIsoCookies";
import useUserPreferences from "hooks/useUserPreferences";
import useButtonStyles from "hooks/useButtonStyles";
import { useLocalization } from "hooks/useLocalization";
import { usePageContent } from "customProviders/LocationProvider";
import useAppData from "hooks/useAppData";
import { useCurrencyCode } from "hooks/useCurrencyCode";
import yn from "yn";
import { useParams2 } from "hooks/useParams";
import { useRhUserAtomValue } from "hooks/atoms";
import TailwindDrawer from "@RHCommerceDev/component-tailwind-drawer";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    addedText: {
      textAlign: "center",
      [theme.breakpoints.up("lg")]: {
        textAlign: "left"
      }
    },
    paper: {
      [theme.breakpoints.only("xl")]: {
        width: "25%"
      },
      [theme.breakpoints.only("lg")]: {
        width: "30%"
      },
      [theme.breakpoints.only("md")]: {
        width: "35%"
      },
      [theme.breakpoints.only("sm")]: {
        width: "50%"
      },
      [theme.breakpoints.only("xs")]: {
        width: "80%"
      }
    },
    rhrPaper: {
      [theme.breakpoints.up("md")]: {
        width: "33.3%"
      },
      [theme.breakpoints.only("sm")]: {
        width: "50%"
      },
      [theme.breakpoints.only("xs")]: {
        width: "80%"
      }
    },
    content: {
      overflow: "auto"
    },
    rhrContent: {
      overflow: "auto",
      [theme.breakpoints.only("xl")]: {
        padding: "88px 80px"
      },
      [theme.breakpoints.only("lg")]: {
        padding: "88px 41px"
      },
      [theme.breakpoints.only("md")]: {
        padding: "88px 40px"
      },
      [theme.breakpoints.down("sm")]: {
        padding: "88px 32px"
      }
    }
  })
);

export interface AddToWishlistDialogProps extends DrawerProps {
  data?: Product;
  productId: string;
  fullSkuId: string;
  atgSkuId?: string;
  qty: number;
  loading?: boolean;
  error?: boolean;
  customInfo?: CustomProductMapper | SkuCustomizationType;
  isCart?: boolean;
  monogramOrder?: Maybe<MonogramOrderProps>;
  giftWrap?: boolean;
  multiSkuComponents?: MultiSkuComponentDetails[];
}

export const AddToWishlistDialog: FC<AddToWishlistDialogProps> = ({
  qty,
  onClose,
  loading,
  error
}) => {
  const { pageContent } = usePageContent();
  const prefix = useLocalization();
  const classes = useStyles();
  const theme = useTheme();
  const { push: historyPush } = useHistory();
  const env = useEnv();
  const typographyStyles = useTypographyStyles({
    keys: ["rhBaseH4", "rhBaseBody1"]
  });

  const buttonStyles = useButtonStyles({
    keys: ["primaryBlackBtn", "secondaryBtn"]
  });

  const handleViewWishlist = useCallback(
    event => {
      onClose?.(event, "escapeKeyDown");
      historyPush(`${prefix}/my-account/wish-list.jsp`);
    },
    [historyPush, onClose, prefix]
  );

  const handleKeepShopping = useCallback(
    event => onClose?.(event, "escapeKeyDown"),
    []
  );

  if (loading) {
    return <RHSpinner />;
  }

  if (error) {
    return <AddToCartErrorMessage />;
  }

  return (
    <>
      <Grid
        item
        xs={12}
        style={{
          paddingBottom: theme.spacing(6.5),
          display: "flex",
          justifyContent: "center"
        }}
      >
        <Typography
          component="p"
          style={{ textTransform: "uppercase" }}
          className={classNames([
            classes.addedText,
            env?.FEATURE_PDP
              ? typographyStyles.rhBaseBody1
              : typographyStyles.rhBaseH4
          ])}
        >
          {`${qty} ${qty > 1 ? pageContent?.ITEMS : pageContent?.ITEM} ${
            pageContent?.ADDED_TO_YOUR_WISHLIST
          }`}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <FormControl margin="normal" fullWidth>
          <Button
            id="addToWishlistDialog_viewWishList-btn"
            data-testid={"addToWishlistDialog_viewWishList-btn"}
            className={buttonStyles.primaryBlackBtn}
            autoFocus
            onClick={event => handleViewWishlist(event)}
          >
            {pageContent?.VIEW_WISHLIST}
          </Button>
        </FormControl>
        <FormControl margin="normal" fullWidth>
          <Button
            id="ajax-continue-shopping"
            data-testid="ajax-continue-shopping"
            className={buttonStyles.secondaryBtn}
            onClick={event => handleKeepShopping(event)}
          >
            {pageContent?.KEEP_SHOPPING}
          </Button>
        </FormControl>
      </Grid>
    </>
  );
};

AddToWishlistDialog.defaultProps = {};

export default ({
  data,
  productId,
  fullSkuId,
  atgSkuId,
  qty,
  customInfo,
  monogramOrder,
  multiSkuComponents: multiSkuComponentsProp,
  ...rest
}: AddToWishlistDialogProps) => {
  const mdUp = useMediaQuery<Theme>(theme => theme.breakpoints.up("md"));
  const classes = useStyles();
  const [loadingAddToWishlist, setAddToWishlistLoading] = useState(true);

  const env = useEnv();
  const theme = useTheme();
  const brand = useSite();
  const { app } = useAppData();
  const pc = useIsoCookies(["pc"], true)?.pc;
  const { email, id: userId, userType } = useRhUserAtomValue();
  const FEATURE_TAILWIND_COMPONENTS = yn(env.FEATURE_TAILWIND_COMPONENTS);
  const DrawerComponent = FEATURE_TAILWIND_COMPONENTS ? TailwindDrawer : Drawer;
  const { params: queryParams } = useParams2<{ [key: string]: string }>(
    { version: "" },
    { toLowerCase: true }
  );

  const isNewPDPLayout = queryParams?.version === "v2" || data?.newPdpLayout;
  const {
    previousState: { country }
  } = useUserPreferences();

  const currencyCode = useCurrencyCode({
    postalCode: app.postalCode || pc,
    userType,
    country
  });

  const isNorthAmerica = currencyCode === "USA" || currencyCode === "CAD";

  const multiSkuComponents =
    yn(env.FEATURE_MULTISKU_PHASE_4) &&
    (yn(env.FEATURE_MULTISKU_PHASE_4_NA) ? isNorthAmerica : true)
      ? ([] as any)
      : multiSkuComponentsProp;

  const multiskuBundleComponents = useMemo(
    () =>
      multiSkuComponents?.length
        ? multiSkuComponents.map(component => ({
            sku: component?.fullSkuId,
            quantity: qty * component?.quantity,
            productId: component?.productId,
            brand: brand,
            multiSkuId: fullSkuId, // fullSkuId will contain a multiSkuId m1010101
            ...(!isEmpty(customInfo) ? { customInfo } : null),
            ...(monogramOrder?.fontCode ||
            monogramOrder?.fontColorCode ||
            monogramOrder?.borderCode
              ? { monogram: cleanMonogramOrder(monogramOrder) }
              : null),
            ...(rest?.giftWrap ? { giftWrap: rest?.giftWrap } : null)
          }))
        : [],
    [multiSkuComponents, qty, fullSkuId]
  );

  const variable = {
    lineItems: multiSkuComponents?.length
      ? multiskuBundleComponents
      : [
          {
            sku: fullSkuId,
            quantity: qty,
            productId,
            brand,
            ...(!isEmpty(customInfo) ? { customInfo } : null),
            ...(monogramOrder?.fontCode ||
            monogramOrder?.fontColorCode ||
            monogramOrder?.borderCode
              ? { monogram: cleanMonogramOrder(monogramOrder) }
              : null),
            ...(rest?.giftWrap ? { giftWrap: rest?.giftWrap } : null)
          }
        ],
    email,
    guest: {
      userId,
      postalCode: pc,
      country: country
    }
  };
  const [addItemsToWishlist, { loading, error }] = useMutation<Mutation>(
    addItemToWishlistMutation,
    {
      variables: variable,
      onCompleted: () => {
        setAddToWishlistLoading(false);
      },
      context: {
        fetchOptions: {
          method: "POST"
        }
      },
      onError: () => {
        setAddToWishlistLoading(false);
      }
    }
  );
  useEffect(() => {
    if (rest.open) {
      addItemsToWishlist();
    }
  }, [rest.open, addItemsToWishlist]);

  return (
    <DrawerComponent
      anchor={"right"}
      {...rest}
      BackdropProps={{
        style: isNewPDPLayout
          ? {
              backgroundColor: "var(--ReimagineWarm-RH-Black, #000)",
              opacity: 0.15
            }
          : {},
        invisible: false
      }}
      PaperProps={{
        className: env?.FEATURE_PDP ? classes.rhrPaper : classes.paper,
        ...rest.PaperProps
      }}
      isNewPdpLayout={isNewPDPLayout}
    >
      <RHDialogTitle
        style={{
          backgroundColor: env.FEATURE_BG_GREY
            ? theme.palette.background.default
            : "initial"
        }}
        solidWhite
        onClose={rest.onClose}
      />
      <DialogContent
        className={env?.FEATURE_PDP ? classes.rhrContent : classes.content}
      >
        <AddToWishlistDialog
          productId={productId}
          fullSkuId={fullSkuId}
          atgSkuId={atgSkuId}
          qty={qty}
          open={rest.open}
          onClose={rest.onClose}
          loading={loadingAddToWishlist}
          error={!!error}
        />
      </DialogContent>
    </DrawerComponent>
  );
};
