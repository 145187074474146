import React, { FC } from "react";
import RHDialog from "component-rh-dialog";
import { createStyles, makeStyles, Typography } from "utils/material-ui-core";
import useTypographyStyles from "hooks/useTypographyStyles";

export interface Prop65DialogProps {
  open: boolean;
  onClose: () => void;
  content: string;
}
const useStyles = makeStyles(() =>
  createStyles({
    traverse: {
      "& img": {
        display: "none"
      },
      "& a": {
        color: "#666666"
      },
      fontSize: "14px"
    },
    container: {
      maxWidth: "650px",
      textAlign: "center",
      margin: "0 auto",
      height: "100%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      "& h1": {
        marginBottom: "32px"
      }
    }
  })
);

const Prop65Dialog: FC<Prop65DialogProps> = ({ open, onClose, content }) => {
  const classes = useStyles();
  const handleClose = () => {
    onClose?.();
  };

  const typographyStyles = useTypographyStyles({
    keys: ["rhBaseH1"]
  });

  return (
    <RHDialog
      solidWhite
      open={open}
      onClose={handleClose}
      fullScreen={false}
      DialogContentProps={{
        style: { marginTop: "-30px" }
      }}
    >
      <div className={classes.container}>
        <Typography className={typographyStyles.rhBaseH1}>
          Warning: California Prop 65
        </Typography>
        <Typography
          className={classes.traverse}
          dangerouslySetInnerHTML={{
            __html: content
          }}
        ></Typography>
      </div>
    </RHDialog>
  );
};

export default Prop65Dialog;
