import React, { FC } from "react";
import { Theme, useTheme, Button, Typography } from "utils/material-ui-core";
import FormDialog from "layout-form-dialog";
import { DrawerProps } from "@material-ui/core/Drawer";
import { createStyles, makeStyles } from "@material-ui/core";
import memoize from "utils/memoize";
import RHCheckmarkIcon from "icon-checkmark";
import RHSpinner from "component-rh-spinner";
import { useEnv } from "hooks/useEnv";
import useTypographyStyles from "hooks/useTypographyStyles";
import classNames from "classnames";
import { useFetchModel } from "hooks/useFetchModel";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    icon: {
      "& svg": {
        fontSize: theme.typography.pxToRem(30)
      }
    },
    MuiButton: {
      root: {
        color: "#999999"
      }
    }
  })
);

interface Props extends DrawerProps {
  title?: string;
  succeed?: boolean;
  succeedText?: string;
  buttonText?: string;
  loading?: boolean;
  onClick?: () => void;
}

const Succeed: FC<Pick<Props, "succeedText">> = ({ succeedText }) => {
  const classes = useStyles();
  const theme = useTheme();
  const env = useEnv();
  const rhrTypo = useTypographyStyles({
    keys: ["rhBaseBody2", "uppercaseText"]
  });

  return (
    <>
      <div
        className={classNames(
          classes.icon,
          "w-[77px] h-[77px] flex items-center justify-center rounded-full border border-solid border-gray-300"
        )}
      >
        <RHCheckmarkIcon />
      </div>

      {succeedText && (
        <Typography
          align="center"
          className={
            env.FEATURE_RHR
              ? classNames([rhrTypo.rhBaseBody2, rhrTypo.uppercaseText])
              : classNames(["!pt-5", rhrTypo.uppercaseText])
          }
        >
          {succeedText}
        </Typography>
      )}
    </>
  );
};

const Confirm: FC<Props> = ({
  title,
  buttonText,
  onClick,
  onClose,
  loading
}) => {
  const env = useEnv();
  const rhrTypo = useTypographyStyles({
    keys: ["rhBaseBody2", "uppercaseText"]
  });
  const { pageContent } = useFetchModel("admin/home", true);

  if (loading) return <RHSpinner />;

  return (
    <>
      <Typography
        align="center"
        className={
          env.FEATURE_RHR
            ? classNames([rhrTypo.rhBaseBody2, rhrTypo.uppercaseText])
            : classNames(["!mb-5 !px-5", rhrTypo.uppercaseText])
        }
      >
        {title}
      </Typography>

      <Button
        onClick={onClick}
        variant="contained"
        color="primary"
        className={classNames([
          "!mt-2.5 w-[265px] h-[42px] bg-black text-white hover:bg-gray-1 hover:border-gray-1"
        ])}
      >
        {buttonText}
      </Button>

      <Button
        onClick={e => onClose?.(e, "escapeKeyDown")}
        variant="outlined"
        color="primary"
        className={classNames([
          "!mt-2.5 w-[265px] h-[42px]",
          "text-[#212121] border border-[rgba(33,33,33,0.5)]"
        ])}
      >
        {pageContent?.authPages?.cancel}
      </Button>
    </>
  );
};

export default memoize(
  ({
    open,
    onClose,
    succeed,
    title,
    succeedText,
    buttonText,
    loading,
    onClick
  }: Props) => {
    return (
      <FormDialog
        maxWidth="xs"
        data-testid="confirm-dialog"
        open={!!open}
        onClose={onClose}
      >
        <div
          className={
            "h-[410px] !p-0 flex flex-col items-center justify-center m-0 mx-auto"
          }
        >
          {!!open ? (
            succeed ? (
              <Succeed succeedText={succeedText} />
            ) : (
              <Confirm
                loading={loading}
                title={title}
                succeed={succeed}
                succeedText={succeedText}
                buttonText={buttonText}
                onClick={onClick}
                onClose={onClose}
              />
            )
          ) : null}
        </div>
      </FormDialog>
    );
  }
);
