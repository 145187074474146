import React, { FC, useCallback, useMemo, CSSProperties } from "react";
import {
  useMediaQuery,
  Theme,
  DialogContent,
  Typography,
  makeStyles,
  createStyles,
  Grid,
  Container,
  Box,
  Tab,
  Tabs,
  Hidden
} from "utils/material-ui-core";
import { DialogProps } from "@material-ui/core/Dialog";
import RHDialogTitle from "component-rh-dialog-title";
import SwatchGroups, { SwatchGroupsProps } from "component-swatch-groups";
import useTypographyStyles from "hooks/useTypographyStyles";
import memoize from "utils/memoize";
import Drawer from "component-drawer";
import classNames from "classnames";
import { useEnv } from "hooks/useEnv";
import yn from "yn";
import { processEnvServer } from "hooks/useSsrHooks";
import {
  BREAKPOINT_LG,
  BREAKPOINT_MD,
  BREAKPOINT_SM,
  BREAKPOINT_XL
} from "utils/constants";
import { usePageContent } from "customProviders/LocationProvider";
import TailwindDrawer from "@RHCommerceDev/component-tailwind-drawer";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      marginTop: theme.spacing(2)
    },
    delivery: {
      marginTop: theme.spacing(1)
    },
    swatchGroupListItem: {
      marginTop: theme.spacing(1),
      "&:first-child": {
        marginTop: theme.spacing(2)
      }
    },
    containerRoot: {
      [theme.breakpoints.up("xl")]: {
        paddingLeft: theme.gutters.lg,
        paddingRight: theme.gutters.lg
      },
      background: theme?.palette?.common?.white || "#ffffff"
    },

    removeBlur: {
      "& .MuiBackdrop-root ": {
        opacity: "0 !important"
      }
    },
    containerRootV2: {
      paddingLeft: 0,
      paddingRight: 0,
      height: "calc(100% - 56px)"
    },
    tabWrapper: {
      display: "flex",
      width: "100%",
      "& .MuiTabs-flexContainer": {
        gap: "3px",
        display: "flex",
        width: "100%",
        borderBottom: ".5px solid #D4D2CF",
        [theme.breakpoints.up("lg")]: {
          gap: "4px"
        }
      }
    },
    tabItem: {
      flex: 1,
      border: "0.5px solid #D4D2CF",
      borderBottom: "none",
      background: "#D4D2CF",
      color: "#000",
      textAlign: "center",
      fontFamily: "RHSans",
      fontSize: "11px",
      lineHeight: "115%",
      textTransform: "uppercase",
      fontStyle: "normal",
      fontWeight: 400,
      opacity: "unset",
      maxWidth: "100%",
      minHeight: "44px",
      maxHeight: "44px",
      borderRadius: "2px 2px 0px 0px"
    },
    tabItemActive: {
      background: "#fff",
      borderBottom: "0.5px solid white",
      marginBottom: "-1px",
      textDecorationLine: "underline",
      maxHeight: "45px"
    },
    gridContainer: {
      "& > *": {
        padding: "0 55px 0 53px !important",
        [theme?.breakpoints?.between(BREAKPOINT_LG, BREAKPOINT_XL)]: {
          padding: "0 39px 0 43px !important"
        },
        [theme?.breakpoints?.between(BREAKPOINT_MD, BREAKPOINT_LG)]: {
          padding: "0 43px 0 45px !important"
        },
        [theme?.breakpoints?.down(BREAKPOINT_MD)]: {
          padding: "0 19px 0 20px !important"
        }
      }
    },
    swatchHeading: {
      color: "#000",
      fontFamily: "RHSans-UltraThin",
      fontSize: "32px",
      fontStyle: "normal",
      fontWeight: 75,
      lineHeight: "32px",
      letterSpacing: "-0.64px",
      marginBottom: "4px",
      [theme?.breakpoints?.down(BREAKPOINT_MD)]: {
        marginBottom: "6px"
      },
      [theme.breakpoints.down(BREAKPOINT_SM)]: {
        marginTop: "-39px",
        width: "90%"
      },
      [theme.breakpoints.up(BREAKPOINT_SM)]: {
        marginTop: "-13px"
      },
      [theme.breakpoints.up(BREAKPOINT_LG)]: {
        marginTop: "5px"
      }
    },
    chooseFabric: {
      color: "#000",
      fontFamily: "RHSans",
      fontSize: "13px",
      fontStyle: "normal",
      fontWeight: 100,
      lineHeight: "20px",
      letterSpacing: " 0.015em",
      marginBottom: "30px",
      textTransform: "lowercase",
      "&::first-letter": {
        textTransform: "uppercase"
      },
      [theme?.breakpoints?.down(BREAKPOINT_MD)]: {
        marginBottom: "36px"
      }
    },
    heroImageV2: {
      width: "100%",
      height: "43.9vh",
      padding: "0px !important",
      marginBottom: "20px"
    },
    applyCTAConatiner: {
      width: "100%",
      background: "#000",
      border: "2px solid black",
      minHeight: "64px",
      bottom: "0",
      zIndex: 99,
      position: "fixed",
      display: "flex"
    },
    fabricTitleStyles: {
      color: "#fff",
      fontSize: "13px !important",
      fontStyle: "normal",
      fontWeight: 300,
      lineHeight: "120% !important",
      letterSpacing: "0.52px !important"
    },
    colorTitle: {
      color: "#404040",
      fontSize: "13px",
      fontStyle: "normal",
      fontWeight: 100,
      lineHeight: "120%",
      letterSpacing: "0.52px",
      marginTop: "5px"
    },
    applyBottonStyle: {
      display: "flex",
      width: "109px",
      height: "32px",
      justifyContent: "center",
      alignItems: "center",
      flexShrink: 0,
      marginRigth: "19px",
      fontFamily: "Baron Sans",
      fontSize: "11px",
      fontWeight: 300,
      lineHeight: "13px",
      letterSpacing: "0.04em",
      textAlign: "center"
    },
    fabricDetails: {
      marginTop: "14px",
      textDecoration: "underline"
    },
    fabricTitleContainer: {
      marginTop: "14px",
      marginLeft: "16px",
      marginBottom: "14px"
    }
  })
);

export interface SwatchPanelDialogContentV2Props {
  onClose?: (event: {}, reason: "backdropClick" | "escapeKeyDown") => void;
  product: Product;
  stockedSwatchesLength: number;
  customSwatchesLength: number;
  groupMaterial: string;
  template: "inline" | "panel" | "split-panel";
  selectedSwatches: {
    [groupMaterial: string]: ProductSwatch;
  };
  onSwatchClick: SwatchGroupsProps["onClick"];
  imageComponent?: any;
}

export const SwatchPanelDialogContentV2: FC<
  SwatchPanelDialogContentV2Props
> = ({
  onClose,
  product,
  stockedSwatchesLength,
  customSwatchesLength,
  groupMaterial,
  selectedSwatches,
  onSwatchClick,
  imageComponent
}) => {
  const [tabValue, setTabValue] = React.useState(+!stockedSwatchesLength);
  const { pageContent } = usePageContent();
  const mdUp = useMediaQuery<Theme>(theme => theme.breakpoints.up("md"));
  const smDown = useMediaQuery<Theme>(theme => theme.breakpoints.down("xs"));
  const classes = useStyles();
  const typographyClasses = useTypographyStyles({
    keys: [
      "rhBaseH3",
      "rhBaseBody1",
      "uppercaseText",
      "rhBaseCaption",
      "rhBaseBody0",
      "rhBaseBody2"
    ]
  });
  const onSwatchClickCb = useCallback(
    data => {
      onSwatchClick?.(data);
      if (!processEnvServer) {
        document.body.dispatchEvent(
          new CustomEvent("pre-pdp-click", {
            detail: {
              item: {
                action: "Swatch Item selection",
                label:
                  data.swatchGroup.swatchGroupName +
                  " - " +
                  data.selectedSwatch.title
              }
            }
          })
        );
      }
    },
    [onSwatchClick]
  );

  const fabricTitle = useMemo(() => {
    return (
      (selectedSwatches?.Fabric?.optionCopy || "")
        ?.split("<br>")[0]
        ?.split("<strong>")[1]
        ?.split("</strong>")[0] || ""
    );
  }, [selectedSwatches]);

  const applyCTAContent = useCallback(
    (styles?: CSSProperties, ctaTitle?: String) => {
      return (
        <Hidden smUp>
          {Boolean(Object.keys(selectedSwatches).length) && (
            <div
              className={classes.applyCTAConatiner}
              style={{ ...(styles || {}) }}
            >
              <div style={{ width: "60%" }}>
                <div className={classes.fabricTitleContainer}>
                  <Typography
                    style={{ color: "#FFF" }}
                    className={classNames(
                      typographyClasses.rhBaseBody2,
                      classes.fabricTitleStyles
                    )}
                    dangerouslySetInnerHTML={{
                      __html: fabricTitle || ""
                    }}
                  />
                  <Typography
                    className={classNames(
                      typographyClasses.rhBaseBody1,
                      classes.colorTitle
                    )}
                    style={{ color: "#fff" }}
                  >
                    {selectedSwatches?.Fabric?.title}
                  </Typography>
                </div>
              </div>
              <div
                style={{
                  width: "40%",
                  position: "relative",
                  left: "19px",
                  display: "grid",
                  alignItems: "center"
                }}
              >
                <button
                  onClick={event => onClose?.(event, "backdropClick")}
                  className={classNames(classes.applyBottonStyle)}
                >
                  {ctaTitle ? ctaTitle : pageContent?.APPLY}
                </button>
              </div>
            </div>
          )}
        </Hidden>
      );
    },
    [selectedSwatches, smDown]
  );

  return (
    <>
      {applyCTAContent({})}
      <DialogContent
        style={{
          overflowY: "auto",
          padding: 0,
          marginTop: 0,
          backgroundColor: "white"
        }}
        className={classes.container}
      >
        <RHDialogTitle
          solidWhite
          onClose={event => onClose?.(event, "escapeKeyDown")}
        />
        <Container className={classNames(classes.containerRoot)}>
          <Grid container spacing={0}>
            <Grid
              item
              xs={12}
              className={classes.gridContainer}
              style={{ zIndex: 1 }}
            >
              <>
                <Typography
                  style={{ textTransform: "uppercase" }}
                  className={classes.swatchHeading}
                >
                  {groupMaterial}
                </Typography>

                <Typography className={classes.chooseFabric} variant={"body1"}>
                  {pageContent?.CHOOSE_YOUR_FABRIC_AND_COLOR}
                </Typography>
                {smDown ? (
                  <div className={classes.heroImageV2} id="heroImageV2">
                    {imageComponent}
                  </div>
                ) : null}
                <Box>
                  <Tabs
                    className={classes.tabWrapper}
                    onChange={(e, value) => setTabValue(value)}
                    value={tabValue}
                    TabIndicatorProps={{
                      style: { display: "none" }
                    }}
                  >
                    <Tab
                      value={0}
                      className={`${classes.tabItem} ${
                        tabValue === 0 ? classes.tabItemActive : ""
                      }`}
                      label={
                        <>
                          {pageContent?.STOCKED} ({stockedSwatchesLength})
                        </>
                      }
                    />
                    <Tab
                      value={1}
                      className={`${classes.tabItem} ${
                        tabValue === 1 ? classes.tabItemActive : ""
                      }`}
                      label={`${pageContent?.view_all} (${
                        (stockedSwatchesLength || 0) +
                        (customSwatchesLength || 0)
                      })`}
                    />
                  </Tabs>
                </Box>
              </>
              <Grid container spacing={2}>
                {!!stockedSwatchesLength && (
                  <>
                    <Grid item xs={12}>
                      {product.layout === "outdoor" && (
                        <Typography
                          className={classNames([
                            typographyClasses.rhBaseCaption,
                            classes.delivery
                          ])}
                          data-testid="swatch-panel-dialog-delivery-message"
                        >
                          {pageContent?.SWATCH_DELIVERY_MESSAGE}
                        </Typography>
                      )}
                    </Grid>
                    <Grid item xs={12}>
                      <SwatchGroups
                        listItemClassName={classes.swatchGroupListItem}
                        productSwatchImage={product.productSwatchImage}
                        swatchGroups={product.swatchData.swatchGroups}
                        swatches={["stockedSwatches"]}
                        selectedSwatches={selectedSwatches}
                        onClick={onSwatchClickCb}
                        detail={!mdUp}
                        data={product}
                        applyCTAContent={applyCTAContent}
                        tabValue={tabValue}
                      />
                      {Boolean(Object.keys(selectedSwatches).length) && (
                        <div style={{ height: "95px" }}></div>
                      )}
                    </Grid>
                  </>
                )}
                {/* this is giving extra line below the tabs button - removed in tab button design*/}
                {/* {!!stockedSwatchesLength && !!customSwatchesLength && (
                  <Grid item xs={12}>
                    <RHDivider />
                  </Grid>
                )} */}
                {tabValue === 1 && !!customSwatchesLength && (
                  <>
                    <Grid item xs={12}>
                      <Typography
                        className={classNames([
                          typographyClasses.rhBaseCaption,
                          classes.delivery
                        ])}
                        data-testid="swatch-panel-dialog-spo-delivery-messsage"
                      >
                        {pageContent?.SWATCH_DELIVERY_MESSAGE}
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <SwatchGroups
                        listItemClassName={classes.swatchGroupListItem}
                        productSwatchImage={product.productSwatchImage}
                        swatchGroups={product.swatchData.swatchGroups}
                        swatches={["customSwatches"]}
                        selectedSwatches={selectedSwatches}
                        onClick={onSwatchClickCb}
                        detail={!mdUp}
                        data={product}
                        applyCTAContent={applyCTAContent}
                        tabValue={tabValue}
                      />
                      {Boolean(Object.keys(selectedSwatches).length) && (
                        <div style={{ height: "95px" }}></div>
                      )}
                    </Grid>
                  </>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </DialogContent>
    </>
  );
};

SwatchPanelDialogContentV2.defaultProps = {};

export default memoize(
  ({
    product,
    stockedSwatchesLength,
    customSwatchesLength,
    groupMaterial,
    template,
    selectedSwatches,
    onSwatchClick,
    imageComponent,
    ...rest
  }: SwatchPanelDialogContentV2Props & Omit<DialogProps, "children">) => {
    const mdUp = useMediaQuery<Theme>(theme => theme.breakpoints.up("md"));
    const smUp = useMediaQuery<Theme>(theme => theme.breakpoints.up("sm"));

    const classes = useStyles();
    const env = useEnv();
    const FEATURE_TAILWIND_COMPONENTS = yn(env.FEATURE_TAILWIND_COMPONENTS);
    const DrawerComponent = FEATURE_TAILWIND_COMPONENTS
      ? TailwindDrawer
      : Drawer;
    return (
      <DrawerComponent
        anchor="right"
        {...rest}
        data-testid="swatch-panel-dialog"
        className={`${yn(env.FEATURE_RHR) ? classes.removeBlur : ""}`}
        PaperProps={{
          ...rest.PaperProps,
          style: {
            width: mdUp ? "50%" : smUp ? "49%" : "100%",
            backdropFilter: yn(env.FEATURE_RHR) ? "none" : "inherit",
            inset: rest.open ? "unset" : "auto",
            ...rest.PaperProps?.style
          }
        }}
        disableBackdropClick={true}
        // style={{
        //   inset: rest.open ? "unset" : "auto"
        // }}
      >
        <SwatchPanelDialogContentV2
          onClose={rest.onClose}
          product={product}
          stockedSwatchesLength={stockedSwatchesLength}
          customSwatchesLength={customSwatchesLength}
          groupMaterial={groupMaterial}
          template={template}
          selectedSwatches={selectedSwatches}
          onSwatchClick={onSwatchClick}
          imageComponent={imageComponent}
        />
      </DrawerComponent>
    );
  }
);
