import React from "react";
import {
  Grid,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  makeStyles,
  Theme,
  createStyles
} from "utils/material-ui-core";
import classNames from "classnames";
import { usePageContent } from "customProviders/LocationProvider";
import getCountryFromUrl from "utils/getCountryFromUrl";
import { checkCountryIsEu } from "utils/checkCountryIsEu";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    textCenter: { textAlign: "center" },
    membersButtonSpacing: {
      marginBottom: theme.typography.pxToRem(12)
    },
    cellSize: {
      padding: `${theme.typography.pxToRem(14.4)} 0`
    },
    cellBorder: {
      borderBottom: "1px solid #F5F5F5"
    },
    bodySpacing: {
      marginTop: theme.typography.pxToRem(20),
      marginBottom: theme.typography.pxToRem(30),
      [theme.breakpoints.down("sm")]: {
        marginTop: theme.typography.pxToRem(20),
        marginBottom: theme.typography.pxToRem(40)
      }
    },
    link: {
      textDecoration: "underline",
      color: "#666666"
    }
  })
);

const StandardShippingContent = () => {
  const classes = useStyles();
  const { pageContent } = usePageContent();
  const country = getCountryFromUrl();

  return (
    <Grid item>
      <Typography variant="h3">
        {pageContent?.shippingDialog?.standardShipping?.header1}
      </Typography>
      <Typography variant="body1" className={classNames([classes.bodySpacing])}>
        {pageContent?.shippingDialog?.standardShipping?.bodyText1}
      </Typography>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow style={{ textTransform: "uppercase", fontSize: 12 }}>
              <TableCell
                className={classNames([classes.cellSize, classes.cellBorder])}
              >
                <Typography variant="h5">
                  {pageContent?.shippingDialog?.standardShipping?.header3}
                </Typography>
              </TableCell>
              <TableCell
                align="right"
                className={classNames([classes.cellSize, classes.cellBorder])}
              >
                <Typography variant="h5">
                  {pageContent?.shippingDialog?.standardShipping?.header4}
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {pageContent?.shippingDialog?.standardShipping?.standardShippingContentRows?.map(
              (row, idx) => (
                <TableRow key={idx}>
                  <TableCell
                    component="th"
                    scope="row"
                    className={classNames([
                      classes.cellSize,
                      classes.cellBorder
                    ])}
                  >
                    <Typography variant="body1">{row.col1}</Typography>
                  </TableCell>
                  <TableCell
                    align="right"
                    className={classNames([
                      classes.cellSize,
                      classes.cellBorder
                    ])}
                  >
                    <Typography variant="body1">{row.col2}</Typography>
                  </TableCell>
                </TableRow>
              )
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {!checkCountryIsEu({ country }) && (
        <Grid item className={classNames([classes.cellSize])}>
          <Typography variant="body1">
            {pageContent?.shippingDialog?.standardShipping?.bodyText6}
            <a
              className={classNames(classes.link)}
              href="https://rh.com/customer-service/shipping-and-delivery-info.jsp?renderdesktop=true"
              target="_blank"
            >
              {pageContent?.shippingDialog?.standardShipping?.bodyText7}
            </a>
          </Typography>
        </Grid>
      )}
    </Grid>
  );
};

export default StandardShippingContent;
