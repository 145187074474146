import { Theme, createStyles, makeStyles } from "@material-ui/core";
import { BREAKPOINT_SM, BREAKPOINT_XL } from "utils/constants";

const cartConfirmationUseStyle = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      gap: "32px"
    },
    innerRootDiv: {
      width: "116px"
    },
    headerTextTypo: {
      color: "#000",
      fontFamily: "RHSans-Light !important",
      fontSize: "11px",
      fontStyle: "normal",
      fontWeight: 300,
      lineHeight: "9.90px",
      letterSpacing: "0.22px",
      textAlign: "center",
      textTransform: "uppercase"
    },
    productDetailsRoot: {
      display: "grid",
      gap: "12px"
    },
    productTitleTypo: {
      fontFamily: "RHC-Thin !important",
      color: "#000",
      textAlign: "center",
      fontSize: "30px",
      fontStyle: "normal",
      fontWeight: 100,
      lineHeight: "27px",
      letterSpacing: "0.6px"
    },
    smallProducttitle: {
      fontSize: "20px",
      lineHeight: "18px",
      letterSpacing: "0.4px"
    },
    deliveryDetailRoot: {
      color: "#000",
      fontFamily: "RHSans-ExtraLight !important",
      fontSize: "11px",
      fontStyle: "normal",
      fontWeight: 200,
      lineHeight: "13.20px",
      letterSpacing: "0.44px",
      textAlign: "center"
    },

    deliveryStyles: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center"
    },

    optionsRoot: {
      display: "grid",
      gap: "12px"
    },

    optionTypeTypo: {
      color: "#000",
      textAlign: "left",
      fontFamily: "RHSans-Light !important",
      fontSize: "11px",
      fontStyle: "normal",
      fontWeight: 300,
      lineHeight: "13.20px",
      letterSpacing: "0.22px",
      textTransform: "uppercase"
    },
    optionLabelTypo: {
      color: "#000",
      fontFamily: "RHSans-Light !important",
      fontSize: "11px",
      fontStyle: "normal",
      fontWeight: 300,
      lineHeight: "13.20px",
      letterSpacing: "0.44px"
    },

    deliveryDetailsRoot: {
      display: "grid",
      justifyContent: "center",
      alignItems: "center",
      gap: "10px",
      textAlign: "center",
      [theme.breakpoints.down(BREAKPOINT_XL)]: {
        width: "298px",
        [theme.breakpoints.down(BREAKPOINT_SM)]: {
          width: "268px"
        }
      }
    },

    viewDetailsRoot: {
      color: "#000",
      fontSize: "13px",
      fontFamily: "RHSans-Light !important",
      fontWeight: 300,
      lineHeight: "13px",
      letterSpacing: "0.26px"
    },

    deliveryTypoStyle: {
      color: "#000!important",
      fontFamily: "RHSans-ExtraLight !important",
      fontSize: "11px",
      fontStyle: "normal",
      fontWeight: 200,
      lineHeight: "13.20px",
      letterSpacing: "0.44px",
      "&> div > p": {
        color: "#000!important",
        fontFamily: "RHSans-ExtraLight !important",
        fontSize: "11px",
        fontStyle: "normal",
        fontWeight: 200,
        lineHeight: "13.20px",
        letterSpacing: "0.44px",
        "& #component-rh-link ": {
          textDecoration: "underline !important"
        }
      }
    },
    typoUnderLine: {
      textDecoration: "underline"
    },

    unlimitedDetailsRoot: {
      "& > div > span > p": {
        color: "#000",
        fontSize: "11px",
        fontFamily: "RHSans-ExtraLight !important",
        fontWeight: 200,
        textDecoration: "underline",
        lineHeight: "13.20px",
        letterSpacing: "0.44px"
      }
    },

    buttonRangeRoot: {
      height: "36px",
      padding: "0 0.53px 0 1.78px !important",
      background: "transparent",
      color: "#000000",
      border: "0.5px solid #666666",
      "&:hover": {
        border: "0.5px solid #AEAEAE"
      },
      "&:disabled": {
        border: "0.5px solid #E6E6E6",
        color: "#C4C4C4"
      },
      [theme.breakpoints.down(1300)]: {
        width: "100%",
        padding: "0 0.45px 0 1.52px !important",
        [theme.breakpoints.down(BREAKPOINT_SM)]: {
          width: "100%",
          padding: "0 0.41px 0 1.37px !important"
        }
      }
    },

    priceRangeRoot: {
      "& > div > div": {
        "& #swatchMemberPrice > p": {
          color: "#201f1f !important",
          fontFamily: "RHSans-Light !important",
          fontSize: "16px !important",
          fontWeight: "300 !important",
          lineHeight: "16px !important"
        },
        "& #regularPriceContainer > p": {
          color: "#201f1f !important",
          fontFamily: "RHSans-ExtraLight !important",
          fontSize: "13px !important",
          fontWeight: "200 !important",
          lineHeight: "14.30px !important",
          letterSpacing: "0.26px !important"
        }
      }
    },
    addOnPriceRangeRoot: {
      "& > div > div": {
        "& #swatchMemberPrice > p": {
          color: "#201f1f !important",
          fontFamily: "RHSans-Light !important",
          fontSize: "13px !important",
          fontWeight: "300 !important",
          lineHeight: "13px !important"
        },
        "& #regularPriceContainer > p": {
          color: "#201f1f !important",
          fontFamily: "RHSans-ExtraLight !important",
          fontSize: "11px !important",
          fontWeight: "200 !important",
          lineHeight: "12.1px !important",
          letterSpacing: "0.22px !important"
        }
      }
    }
  })
);

export default cartConfirmationUseStyle;
