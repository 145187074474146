import React, { FC, useEffect, useCallback, useMemo } from "react";
import {
  DialogContent,
  Theme,
  makeStyles,
  createStyles,
  useTheme
} from "utils/material-ui-core";
import { DrawerProps } from "@material-ui/core/Drawer";
import SpecialOrderConfirmation from "./SpecialOrderConfirmation";
import Confirmed from "./Confirmed";
import { useEnv } from "hooks/useEnv";
import yn from "yn";
import AddToCartErrorMessage from "./AddToCartErrorMessage";
import { useMutation } from "@apollo/client";
import RHSpinner from "component-rh-spinner";
import RHDialogTitle from "component-rh-dialog-title";
import useState from "hooks/useState";
import { addLineItemsToCart } from "graphql-client/queries/add-line-items-to-cart";
import { queryGetCart } from "graphql-client/queries/get-cart";
import { MonogramOrderProps } from "page-monogram-dialog";
import EventEmitter from "utils-event-emitter";
import { cleanMonogramOrder } from "utils/monogramUtils";
import isEmpty from "lodash.isempty";
import { ApolloError } from "@apollo/client";
import Drawer from "component-drawer";
import { useIsoCookies } from "hooks/useIsoCookies";
import { processEnvServer } from "hooks/useSsrHooks";
import { ProductSkuPriceInfoProps } from "component-product-sku-price-info/types";
import PanelAddToCartButton from "./PanelAddToCartButton";
import RHRSpecialOrderConfirmation from "./RHRSpecialOrderConfirmation";
import useUserPreferences from "hooks/useUserPreferences";
import getCountryFromUrl from "utils/getCountryFromUrl";
import useSite from "hooks/useSite";
import { addOnDataType } from "component-product-addon/types";
import ProductAddon from "component-product-addon";
import {
  AddOnDataType,
  AddOnConfigModes,
  AddOnCheckboxProductV2,
  AddOnRenderButtonOptionInfo
} from "@RHCommerceDev/component-addon-checkbox-v2";
import { usePageContent } from "customProviders/LocationProvider";
import { memoryStorage } from "utils/analytics/storage";
import useAppData from "hooks/useAppData";
import { useCurrencyCode } from "hooks/useCurrencyCode";
import CartConfirmation from "./CartConfirmation";
import { useParams2 } from "hooks/useParams";
import { useUserSessionAtomValue, useUserSessionSetAtom } from "hooks/atoms";
import { useGetCartProjectLazyQuery } from "hooks/queries";
import { useKeycloak } from "utils/Keycloak/KeyCloak";
import TailwindDrawer from "@RHCommerceDev/component-tailwind-drawer";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      [theme.breakpoints.only("xl")]: {
        width: "33.33%"
      },
      [theme.breakpoints.only("lg")]: {
        width: "44.42%"
      },
      [theme.breakpoints.only("md")]: {
        width: "53.73%"
      },
      [theme.breakpoints.only("sm")]: {
        width: "69.4%"
      },
      [theme.breakpoints.only("xs")]: {
        width: "100%"
      }
    },
    rhrPaper: {
      [theme.breakpoints.up("md")]: {
        width: "33.3%"
      },
      [theme.breakpoints.only("sm")]: {
        width: "50%"
      },
      [theme.breakpoints.only("xs")]: {
        width: "80%"
      }
    },
    content: {
      overflow: "auto",
      padding: "0px 93.5px",
      [theme.breakpoints.only("xs")]: {
        padding: "16px"
      }
    },
    addonPanelContent: {
      overflow: "auto",
      padding: "0px 93.5px",
      [theme.breakpoints.only("xs")]: {
        padding: "0px 16px"
      }
    },
    rhrContent: {
      overflow: "auto",
      [theme.breakpoints.only("xl")]: {
        padding: "80px 80px"
      },
      [theme.breakpoints.only("lg")]: {
        padding: "40px 40px"
      },
      [theme.breakpoints.only("md")]: {
        padding: "40px 40px"
      },
      [theme.breakpoints.down("sm")]: {
        padding: "40px 32px"
      }
    }
  })
);

export interface AddToCartDialogProps extends DrawerProps {
  data?: Product;
  monogramOrder?: MonogramOrderProps;
  productId: string;
  fullSkuId: string;
  atgSkuId?: string;
  qty: number;
  spo?: boolean;
  preBillMessage?: string;
  productDisplayName?: string;
  productAddonDisplayOptions?: string;
  imgURL?: Maybe<string>;
  productAddonsInfo?: Maybe<Array<ProductAddonsInfo>>;
  onAddOnConfigChange?: (
    addOnData: addOnDataType,
    addOnConfigModes: AddOnConfigModes
  ) => void;
  productAddOnsData?: ProductAddonsInfo[];
  handlePanelAddToCartClick?: () => void;
  panelProdConfigurationData?: Maybe<addOnDataType>;
  panelAddOnsData?: AddOnDataType[];
  panelProdData?: Maybe<ProductAddonsInfo>;
  showPanelAddon?: boolean;
  productAddedFromPanel?: boolean;
  productAddons?: any;
  mainProductAvailableOptions?: ProductAvailableOptionSet[];
  mainProductAvailableSwatch?: any;
  productLineItemOptions?: ProductAvailableOptionSet[];
  onCompleted?: Function;
  options?: (ProductAvailableOption | CustomOption)[];
  confirmed?: boolean;
  onConfirmed?: () => void;
  loading?: boolean;
  incomingPanelProdConfigData?: boolean;
  error?: boolean;
  giftCardTo?: string;
  giftCardFrom?: string;
  salePriceLabel?: string;
  pricing?: ProductSkuPriceInfoProps["data"];
  customInfo?: SkuCustomizationType;
  errorMessage?: string;
  multiSkuComponentsDataV2?: any;
  multiSkuComponents?: MultiSkuComponents[];
  setConfirmed?: React.Dispatch<React.SetStateAction<boolean>>;
  panelProd?: Maybe<addOnDataType>;
  panelProds?: Array<ProductAddonsInfo>;
  panelQty?: number;
  setPanelQty?: React.Dispatch<React.SetStateAction<number>>;
  setIsSpo?: React.Dispatch<React.SetStateAction<boolean>>;
  isSpo?: boolean;
  addToCartItemDetails?: addToCartItemDetailsProps;
  onPostalCode?: () => void;
  fetchOptionStatus?: (variable: any) => Promise<ProductLineItem>;
  mainProductChoosenOptions?: any;
  swatchAddToCartItemDetails?: Array<SwatchSkuIds>;
  setAddedFromPanel?: React.Dispatch<React.SetStateAction<boolean>>;
}

export const AddToCartDialog: FC<AddToCartDialogProps> = ({
  qty,
  spo,
  preBillMessage,
  productDisplayName,
  options,
  confirmed,
  onConfirmed,
  loading,
  error,
  onClose,
  fullSkuId,
  salePriceLabel,
  pricing,
  productAddonsInfo,
  imgURL,
  onAddOnConfigChange,
  handlePanelAddToCartClick,
  productAddOnsData,
  panelProdConfigurationData,
  panelAddOnsData,
  productAddonDisplayOptions,
  panelProdData,
  incomingPanelProdConfigData,
  showPanelAddon,
  multiSkuComponents,
  productLineItemOptions,
  mainProductAvailableOptions,
  mainProductAvailableSwatch,
  panelQty,
  setPanelQty,
  productAddons,
  panelProds,
  addToCartItemDetails,
  productId,
  onPostalCode,
  fetchOptionStatus,
  productAddedFromPanel,
  mainProductChoosenOptions,
  setAddedFromPanel,
  ...rest
}) => {
  const env = useEnv();
  const FEATURE_PDP = useIsoCookies(["FEATURE_PDP"]).FEATURE_PDP;
  const isPDP = yn(env.FEATURE_PDP) || yn(FEATURE_PDP);

  const [disableATC, setDisableATC] = useState<boolean>(true);
  const [
    selectedPanelAddOnsNotFullyConfigured,
    setSelectedPanelAddOnsNotFullyConfigured
  ] = useState<boolean>(false);

  const { params } = useParams2<{ [key: string]: string }>(
    { productId: "", version: "" },
    { toLowerCase: true }
  );

  const isNewPDPLayOut: boolean =
    params.version === "v2" || memoryStorage.getItem("newPdpLayout");

  const addOnsExist =
    yn(env.FEATURE_PRODUCT_ADDON) && !!productAddonsInfo?.length;

  const shouldDisplayAddOns = productAddonDisplayOptions
    ? ["Panel", "Checkbox and Panel"].includes(productAddonDisplayOptions || "")
    : true;

  const panelAddOnOptionInfoToRenderButtons = useMemo(() => {
    const addOnsInfo = panelProds?.map(addOn => ({
      id: addOn?.id,
      addOnAvailableOptions: addOn?.productConfiguration?.optionDetails,
      preSelectableOptionTypeIds: mainProductAvailableOptions
        ?.map(mainItemOptionSet => {
          const matchingOptionSet =
            addOn?.productConfiguration?.optionDetails?.find(
              addOnOptionSet => addOnOptionSet?.type === mainItemOptionSet?.type
              // addOnOptionSet?.options[0]?.typeId ===
              // mainItemOptionSet?.options[0]?.typeId
            );

          return matchingOptionSet?.options[0]?.typeId;
        })
        .filter(opt => opt)
    }));

    const addOnsWithPossibleButtonOptions = addOnsInfo?.map(addOn => ({
      ...addOn,
      remainingOptionsAfterPreselection: addOn?.addOnAvailableOptions?.filter(
        addOnOptionSet =>
          !(addOn?.preSelectableOptionTypeIds ?? []).includes(
            addOnOptionSet?.options[0]?.typeId
          )
      )
    }));
    const addOn = addOnsWithPossibleButtonOptions
      ?.filter(
        addOn =>
          addOn?.addOnAvailableOptions?.length &&
          addOn?.remainingOptionsAfterPreselection?.length
      )
      ?.find(Boolean);

    return {
      id: addOn?.id,
      renderButtonOption:
        addOn?.remainingOptionsAfterPreselection?.find(Boolean)
    };
  }, [panelProds]);

  useEffect(() => {
    if (addOnsExist && !isNewPDPLayOut) {
      setDisableATC(
        (panelQty ?? 0) < 1 ||
          (panelProdConfigurationData?.selectedOptions?.length !==
            (panelProdData?.productLineItem?.availableOptions?.length ?? 0) &&
            !panelProdConfigurationData?.preBillMessage) ||
          (incomingPanelProdConfigData ?? false)
      );
    }
  }, [panelProdConfigurationData, incomingPanelProdConfigData]);

  useEffect(() => {
    // if it's the main special order product then no need to show  SwitchSpecialOrderConfirmation else if it's from related special order product then SwitchSpecialOrderConfirmation will be showed
    // Removed SPO panel for relatedproducts as well
    if (isNewPDPLayOut || yn(env.FEATURE_CART_SPO)) {
      onConfirmed?.();
    }
  }, []);

  const handleAddOnData = (
    addOnData: addOnDataType,
    addOnConfigModes: AddOnConfigModes
  ) => {
    // setPanelQty?.(spo ? qty : addOnData?.qty ?? qty);
    setPanelQty?.(addOnData?.qty ?? qty);
    onAddOnConfigChange?.(addOnData, addOnConfigModes);
    if (panelProds?.length && isNewPDPLayOut) {
      const remainingAddOnsConfigState =
        panelAddOnsData
          ?.filter(addOn => addOn?.productId !== addOnData?.productId)
          ?.map(addOn => {
            return {
              productId: addOn?.productId,
              productChecked: addOn?.productSelected,
              fullyConfigured:
                addOn?.optionsLength === addOn?.selectedOptions?.length
            };
          }) ?? [];

      const selectedAddOnsConfigState = addOnData?.productSelected
        ? [
            ...remainingAddOnsConfigState,
            {
              productId: addOnData?.productId,
              productChecked: addOnData?.productSelected,
              fullyConfigured:
                addOnData?.optionsLength === addOnData?.selectedOptions?.length
            }
          ]
        : [...remainingAddOnsConfigState];

      const areAllSelectedAddOnsConfigured = selectedAddOnsConfigState
        ?.filter(addOn => addOn.productChecked)
        ?.every(addOn => addOn.fullyConfigured);

      setSelectedPanelAddOnsNotFullyConfigured(!areAllSelectedAddOnsConfigured);
    }
  };

  useEffect(() => {
    if (panelProds?.length && isNewPDPLayOut) {
      setDisableATC(
        panelAddOnsData?.filter(addon => addon?.productSelected)?.length
          ? selectedPanelAddOnsNotFullyConfigured ||
              (incomingPanelProdConfigData ?? false)
          : true
      );
    }
  }, [panelAddOnsData, incomingPanelProdConfigData]);

  const SPOAddon = productAddons?.filter(item => item?.spo)?.length;
  const SPOVariables = [
    {
      pricing: pricing,
      fullSkuId,
      displayName: productDisplayName,
      ProductAddonOptions: options,
      qty: panelQty,
      spo,
      preBillMessage
    },
    ...(productAddons?.filter(item => item?.spo) ?? [])
  ];

  if (loading) {
    return <RHSpinner />;
  }

  if (error) {
    return <AddToCartErrorMessage errorMessage={rest.errorMessage} />;
  }

  if (confirmed && addOnsExist && panelProdData && showPanelAddon) {
    return (
      <>
        <Confirmed qty={qty} onClose={onClose} isAddon={addOnsExist} />
        {shouldDisplayAddOns && (
          <>
            <ProductAddon
              loading={loading}
              data={panelProdData ?? {}}
              opts={panelProdData?.productLineItem?.availableOptions}
              imgURL={panelProdData?.productLineItem?.image?.imageUrl}
              handleAddonData={handleAddOnData}
              productLineItemOptions={productLineItemOptions}
              panelProdConfigurationData={panelProdConfigurationData}
            />
            <PanelAddToCartButton
              handleAddToCartClick={handlePanelAddToCartClick}
              isDisabled={disableATC}
            />
          </>
        )}
      </>
    );
  }

  if (confirmed && addOnsExist && panelProds?.length && showPanelAddon) {
    if (!isEmpty(addToCartItemDetails)) {
      return (
        <>
          <CartConfirmation
            addToCartItemDetails={addToCartItemDetails}
            onClose={onClose}
            productAddons={productAddons}
            panelAddOnsData={panelAddOnsData}
            panelProds={panelProds}
            showPanelAddon={showPanelAddon}
            productAddedFromPanel={productAddedFromPanel}
            isAddon={addOnsExist}
            shouldDisplayAddOns={shouldDisplayAddOns}
            onPostalCode={onPostalCode}
            qty={qty}
          />
          {shouldDisplayAddOns &&
            panelProds?.map((item, index) => {
              const panelAddOnData = panelAddOnsData?.find(
                prod => prod?.productId === item?.id
              );
              return (
                <>
                  <AddOnCheckboxProductV2
                    data={item}
                    productId={item?.id}
                    productConfiguration={item?.productConfiguration}
                    addOnData={panelAddOnData}
                    addOnOptionInfoToRenderButtons={
                      panelAddOnOptionInfoToRenderButtons as unknown as AddOnRenderButtonOptionInfo
                    }
                    handleAddOnData={handleAddOnData}
                    fetchOptionStatus={fetchOptionStatus}
                    mainProductAvailableOptions={mainProductAvailableOptions}
                    mainProductAvailableSwatch={mainProductAvailableSwatch}
                    mainProductChoosenOptions={mainProductChoosenOptions}
                    isPanel={true}
                  />
                  {index < panelProds?.length - 1 && (
                    <hr
                      key={`divider-end-${index}`}
                      style={{
                        height: "1px",
                        width: "100%",
                        backgroundColor: "var(--reimagine-warm-rh-medium)",
                        margin: "0px"
                      }}
                    />
                  )}
                </>
              );
            })}
          {shouldDisplayAddOns && (
            <PanelAddToCartButton
              handleAddToCartClick={handlePanelAddToCartClick}
              isDisabled={disableATC}
            />
          )}
        </>
      );
    }
  }

  if (confirmed && !!showPanelAddon) {
    if (!isEmpty(addToCartItemDetails)) {
      return (
        <CartConfirmation
          addToCartItemDetails={addToCartItemDetails}
          onClose={onClose}
          productAddons={productAddons}
          panelProds={panelProds}
          panelAddOnsData={panelAddOnsData}
          showPanelAddon={showPanelAddon}
          productAddedFromPanel={productAddedFromPanel}
          isAddon={addOnsExist}
          shouldDisplayAddOns={shouldDisplayAddOns}
          onPostalCode={onPostalCode}
          qty={qty}
        />
      );
    }
    return <Confirmed qty={qty} onClose={onClose} isAddon={false} />;
  }

  if (confirmed) {
    if (!isEmpty(addToCartItemDetails)) {
      return (
        <CartConfirmation
          addToCartItemDetails={addToCartItemDetails}
          onClose={onClose}
          productAddons={productAddons}
          panelProds={panelProds}
          panelAddOnsData={panelAddOnsData}
          showPanelAddon={showPanelAddon}
          productAddedFromPanel={productAddedFromPanel}
          isAddon={addOnsExist}
          shouldDisplayAddOns={shouldDisplayAddOns}
          onPostalCode={onPostalCode}
          qty={qty}
        />
      );
    }

    return <Confirmed qty={qty} onClose={onClose} isAddon={false} />;
  }

  const SwitchSpecialOrderConfirmation = isPDP
    ? RHRSpecialOrderConfirmation
    : SpecialOrderConfirmation;

  if ((spo && !confirmed) || (SPOAddon && !confirmed)) {
    return (
      <SwitchSpecialOrderConfirmation
        confirmationText={preBillMessage ?? ""}
        productDisplayName={productDisplayName ?? ""}
        options={options ?? []}
        fullSkuId={fullSkuId}
        qty={qty}
        salePriceLabel={salePriceLabel}
        pricing={pricing}
        onConfirmed={() => {
          onConfirmed?.();
        }}
        SPOAddonVariables={SPOVariables}
        setAddedFromPanel={setAddedFromPanel!}
      />
    );
  }

  return null;
};

AddToCartDialog.defaultProps = {};

export default ({
  data,
  monogramOrder,
  productId,
  onAddOnConfigChange,
  mainProductAvailableOptions,
  mainProductAvailableSwatch,
  productAddonDisplayOptions,
  productLineItemOptions,
  productAddons,
  productAddonsInfo,
  panelProdConfigurationData,
  panelAddOnsData,
  panelProdData,
  panelProds,
  incomingPanelProdConfigData,
  fullSkuId,
  atgSkuId,
  qty,
  spo,
  preBillMessage,
  productDisplayName,
  multiSkuComponentsDataV2,
  multiSkuComponents: multiSkuComponentsProp,
  onCompleted,
  giftCardTo,
  giftCardFrom,
  pricing,
  customInfo,
  confirmed,
  setConfirmed,
  isSpo,
  setIsSpo,
  addToCartItemDetails,
  onPostalCode,
  fetchOptionStatus,
  swatchAddToCartItemDetails,
  mainProductChoosenOptions,
  ...rest
}: AddToCartDialogProps) => {
  const env = useEnv();
  const enabled = yn(env.FEATURE_PDP_CART_BROKER);
  const addOnsExist = !!productAddonsInfo?.length;
  const classes = useStyles();
  const { keycloak } = useKeycloak();
  const { currentCartId: cartId, rhUser } = useUserSessionAtomValue();
  const [getCartProjection] = useGetCartProjectLazyQuery();
  const brand = useSite();
  const { pageContent } = usePageContent();
  const FEATURE_TAILWIND_COMPONENTS = yn(env.FEATURE_TAILWIND_COMPONENTS);
  const DrawerComponent = FEATURE_TAILWIND_COMPONENTS ? TailwindDrawer : Drawer;
  let spoTerms = preBillMessage;
  if (keycloak?.authenticated && rhUser?.userType === "CONTRACT") {
    spoTerms = pageContent?.spoTerms;
  }

  const [spoAgreed, setSpoAgreed] = useState(false);
  const [showPanelAddon, setShowPanelAddon] = useState(false);
  const [addedFromPanel, setAddedFromPanel] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [cartIdForPanelAddon, setCartIdForPanelAddon] = useState("");
  const cookies = useIsoCookies(["search_access", "navigating_from_search"]);
  const theme = useTheme();
  const { app } = useAppData();
  const pc = useIsoCookies(["pc"], true)?.pc;
  const {
    previousState: { country }
  } = useUserPreferences();
  const userCountry = useMemo(
    () => getCountryFromUrl() || country || "US",
    [country]
  );
  const setUserSession = useUserSessionSetAtom();

  const currencyCode = useCurrencyCode({
    postalCode: app.postalCode || pc,
    userType: rhUser?.userType,
    country
  });
  const { params: queryParams } = useParams2<{ [key: string]: string }>(
    { version: "" },
    { toLowerCase: true }
  );

  const isNewPDPLayout =
    queryParams?.version === "v2" || memoryStorage.getItem("newPdpLayout");
  const isNorthAmerica =
    currencyCode === "USA" || currencyCode === "CAD" || currencyCode === "USD";

  const multiSkuComponents =
    yn(env.FEATURE_MULTISKU_PHASE_4) &&
    (yn(env.FEATURE_MULTISKU_PHASE_4_NA) ? isNorthAmerica : true)
      ? []
      : multiSkuComponentsProp;

  const fusionData = !processEnvServer && localStorage.getItem("fusion_data");
  const searchObj =
    !processEnvServer && localStorage.getItem("analytics-search");
  const access =
    cookies["search_access"] ||
    (!processEnvServer && localStorage.getItem("analytics-access"));
  const analyticsPrevUrl =
    !processEnvServer && localStorage.getItem("analytics-previous-url");
  const isPrevSearchPage =
    analyticsPrevUrl &&
    JSON.parse(analyticsPrevUrl)?.prevURL?.includes("results");
  const isFromSearch =
    cookies["navigating_from_search"] ||
    (!processEnvServer &&
      localStorage.getItem("analytics-navigatingFromSearch"));

  /***
   * HACK gift message is not part of the UI yet but the BE
   * requires it so just loading the query with empty string
   * until field is added to UI
   */

  const getFusionId = useMemo(() => {
    let fusionId = "";
    if (fusionData && searchObj && (isFromSearch || isPrevSearchPage)) {
      const tempFusionData = JSON.parse(fusionData);
      const tempSearchObj = JSON.parse(searchObj);
      const searchTerm = tempSearchObj.term;
      const searchAccess = access === "direct" ? "search" : access;
      if (tempFusionData.fusion_id) {
        fusionId = `${tempFusionData.fusion_id}~${searchTerm}~${searchAccess}`;
      }
    }

    return fusionId;
  }, [fusionData, searchObj, isFromSearch, access, isPrevSearchPage]);

  const [panelLineItems, setPanelLineItems] = useState<Array<LineItemInput>>(
    []
  );

  const [panelQty, setPanelQty] = useState<number>(qty);

  const panelVariables = useMemo(() => {
    return {
      email: rhUser?.email || "",
      cartId: cartId || cartIdForPanelAddon,
      lineItemsInput: {
        items: panelLineItems
      }
    };
  }, [cartId, rhUser?.email, panelLineItems, cartIdForPanelAddon]);

  useEffect(() => {
    if (addOnsExist) {
      setPanelLineItems([
        {
          productId: panelProdConfigurationData?.productId,
          sku: panelProdConfigurationData?.fullSkuId,
          fusionId: getFusionId,
          quantity: panelQty,
          brand: brand,
          giftTo: giftCardTo ?? "",
          giftFrom: giftCardFrom ?? "",
          giftMessage: "",
          spoTermsAccepted: spoAgreed,
          spoTerms: spoAgreed ? spoTerms : null,
          // ...(monogramOrder?.fontCode ||
          // monogramOrder?.fontColorCode ||
          // monogramOrder?.borderCode
          //   ? { monogram: cleanMonogramOrder(monogramOrder) }
          //   : null),
          ...(!isEmpty(customInfo) ? { customInfo } : null)
        }
      ]);
    }
  }, [
    productAddons,
    brand,
    customInfo,
    getFusionId,
    giftCardFrom,
    giftCardTo,
    monogramOrder,
    qty,
    panelQty,
    panelProdConfigurationData,
    spoAgreed,
    spoTerms
  ]);

  const multiskuBundleComponents = useMemo(
    () =>
      multiSkuComponents?.length
        ? multiSkuComponents.map(component => {
            return {
              productId: component?.productId,
              sku: component?.fullSkuId,
              multiSkuId: fullSkuId, // fullSkuId will contain a multiSkuId m1010101
              fusionId: getFusionId,
              quantity: qty * component?.quantity!,
              brand: brand,
              giftTo: giftCardTo ?? "",
              giftFrom: giftCardFrom ?? "",
              giftMessage: "",
              spoTermsAccepted: multiSkuComponentsDataV2?.isSPO
                ? spoAgreed
                : false,
              spoTerms: spoAgreed ? spoTerms : null,
              ...(monogramOrder?.fontCode ||
              monogramOrder?.fontColorCode ||
              monogramOrder?.borderCode
                ? { monogram: cleanMonogramOrder(monogramOrder) }
                : null),
              ...(!isEmpty(customInfo) ? { customInfo } : null)
            };
          })
        : [],
    [multiSkuComponents, qty, spoAgreed]
  );

  const addOnComponents = useMemo(
    () =>
      productAddons?.map(item => ({
        productId: item?.productId,
        sku: item?.fullSkuId,
        fusionId: getFusionId,
        quantity: item?.qty ?? 1,
        brand: brand,
        giftTo: giftCardTo ?? "",
        giftFrom: giftCardFrom ?? "",
        giftMessage: "",
        spoTermsAccepted: item?.spo ? spoAgreed : false,
        spoTerms: spoAgreed ? item?.preBillMessage : null
        // ...(monogramOrder?.fontCode ||
        // monogramOrder?.fontColorCode ||
        // monogramOrder?.borderCode
        //   ? { monogram: cleanMonogramOrder(monogramOrder) }
        //   : null),
        // ...(!isEmpty(customInfo) ? { customInfo } : null)
      })) ?? [],
    [productAddons, qty, spoAgreed]
  );
  const addOnCartData = [
    ...multiskuBundleComponents,
    ...addOnComponents,
    ...(yn(env.FEATURE_MULTISKU_PHASE_4_NA) && isNorthAmerica
      ? [
          {
            productId: productId,
            sku: fullSkuId,
            fusionId: getFusionId,
            quantity: qty,
            brand: brand,
            giftTo: giftCardTo ?? "",
            giftFrom: giftCardFrom ?? "",
            giftMessage: "",
            spoTermsAccepted: spoAgreed,
            spoTerms: spoAgreed ? spoTerms : null,
            ...(monogramOrder?.fontCode ||
            monogramOrder?.fontColorCode ||
            monogramOrder?.borderCode
              ? { monogram: cleanMonogramOrder(monogramOrder) }
              : null),
            ...(!isEmpty(customInfo) ? { customInfo } : null)
          }
        ]
      : [])
  ];

  const swatchItemDetails = useMemo(
    () =>
      swatchAddToCartItemDetails?.map(item => ({
        productId: item?.swatchProductId,
        sku: item?.skuIds,
        fusionId: getFusionId,
        quantity: qty ?? 1,
        brand: brand,
        giftTo: giftCardTo ?? "",
        giftFrom: giftCardFrom ?? "",
        giftMessage: "",
        spoTermsAccepted: spoAgreed,
        spoTerms: spoAgreed ? spoTerms : null,
        ...(monogramOrder?.fontCode ||
        monogramOrder?.fontColorCode ||
        monogramOrder?.borderCode
          ? { monogram: cleanMonogramOrder(monogramOrder) }
          : null),
        ...(!isEmpty(customInfo) ? { customInfo } : null)
      })) ?? [],
    [swatchAddToCartItemDetails, qty]
  );

  const variables: MutationAddLineItemsToCartArgs = {
    email: rhUser?.email || "",
    cartId: cartId || "",
    lineItemsInput: {
      items:
        addOnsExist && productAddons?.length
          ? multiSkuComponents?.length
            ? addOnCartData
            : [
                ...addOnComponents,
                ...(!isEmpty(swatchItemDetails)
                  ? swatchItemDetails
                  : [
                      {
                        productId: productId,
                        sku: fullSkuId,
                        fusionId: getFusionId,
                        quantity: qty,
                        brand: brand,
                        giftTo: giftCardTo ?? "",
                        giftFrom: giftCardFrom ?? "",
                        giftMessage: "",
                        spoTermsAccepted: spo ? spoAgreed : false,
                        spoTerms: spoAgreed ? spoTerms : null,
                        ...(monogramOrder?.fontCode ||
                        monogramOrder?.fontColorCode ||
                        monogramOrder?.borderCode
                          ? { monogram: cleanMonogramOrder(monogramOrder) }
                          : null),
                        ...(!isEmpty(customInfo) ? { customInfo } : null)
                      }
                    ])
              ]
          : multiSkuComponents?.length
          ? multiskuBundleComponents
          : [
              ...(!isEmpty(swatchItemDetails)
                ? swatchItemDetails
                : [
                    {
                      productId: productId,
                      sku: fullSkuId,
                      fusionId: getFusionId,
                      quantity: qty,
                      brand: brand,
                      giftTo: giftCardTo ?? "",
                      giftFrom: giftCardFrom ?? "",
                      giftMessage: "",
                      spoTermsAccepted: spo ? spoAgreed : false,
                      spoTerms: spoAgreed ? spoTerms : null,
                      ...(monogramOrder?.fontCode ||
                      monogramOrder?.fontColorCode ||
                      monogramOrder?.borderCode
                        ? { monogram: cleanMonogramOrder(monogramOrder) }
                        : null),
                      ...(!isEmpty(customInfo) ? { customInfo } : null)
                    }
                  ])
            ]
    },
    ...(yn(env.FEATURE_ADD_ITEM_CREATE_CART)
      ? {
          createCartInfo: {
            guest: {
              email: rhUser?.email || "",
              userId: rhUser?.id
            },
            postalCode: pc,
            country: userCountry
          }
        }
      : null)
  };

  const numberOfItemsAddedToCartFromPDP = useMemo(() => {
    if (addOnsExist) {
      const panelLineItem = variables?.lineItemsInput?.items
        ?.map(lineItem => {
          const addOnData = productAddons?.find(
            productAddOn => productAddOn?.productId === lineItem?.productId
          );
          return addOnData
            ? {
                name: addOnData?.displayName,
                sku: addOnData?.fullSkuId,
                id: addOnData?.productId,
                quantity: addOnData?.qty,
                inStockProductCard: false,
                pricing: addOnData?.pricing,
                color: addOnData?.itemOptions
                  ?.find(opt => opt.type === "Color")
                  ?.options?.find(opt => opt?.status === "selected")?.value
              }
            : null;
        })
        .filter(item => item !== null);
      if (!processEnvServer) {
        memoryStorage.setItem("panelLineItem", panelLineItem);
      }
    }
    return variables?.lineItemsInput?.items.reduce(
      (totalQty, item) => totalQty + item?.quantity,
      0
    );
  }, [variables]);

  const getProductNamesByFullSkuId = useCallback(
    (skuIds: string[]) => {
      const productNames: Maybe<string>[] = [];
      skuIds?.forEach(skuId => {
        if (skuId === fullSkuId) {
          productNames.push(productDisplayName || "");
        } else {
          let productAddOnData: Maybe<addOnDataType & ProductAddonsInfo>;
          if (addedFromPanel) {
            if (isNewPDPLayout) {
              const addedPanelProdId = panelAddOnsData?.find(
                addOn => addOn?.fullSkuId === skuId
              )?.productId;
              productAddOnData = productAddonsInfo?.find(
                addOn => addOn?.id === addedPanelProdId
              );
            } else {
              const addedPanelProdId = panelProdConfigurationData?.productId;
              productAddOnData = productAddonsInfo?.find(
                addOn => addOn?.id === addedPanelProdId
              );
            }
          } else {
            productAddOnData = productAddons?.find(
              addOn => addOn?.fullSkuId === skuId
            );
          }
          !!productAddOnData &&
            productNames.push(productAddOnData?.displayName);
        }
      });

      return productNames?.join(", ");
    },
    [panelAddOnsData, productAddons, panelProdConfigurationData, addedFromPanel]
  );

  const [addItemsToCart, { loading, error }] = useMutation<
    Pick<Mutation, "addLineItemsToCart">,
    MutationAddLineItemsToCartArgs
  >(addLineItemsToCart, {
    update(cache, result) {
      cache.writeQuery({
        query: queryGetCart,
        variables: { email: rhUser?.email, cartId },
        data: { getCart: result?.data?.addLineItemsToCart }
      });
    },
    onCompleted: async data => {
      setConfirmed?.(true);
      onCompleted?.(data?.addLineItemsToCart);
      getCartProjection();
      if (yn(env.FEATURE_ADD_ITEM_CREATE_CART)) {
        setCartIdForPanelAddon(data?.addLineItemsToCart?.id || "");
      }
      // if (yn(env.FEATURE_ADD_ITEM_CREATE_CART))
      //   setUserSession(prev =>
      //     prev
      //       ? {
      //           ...prev,
      //           currentCartId: data?.addLineItemsToCart?.id
      //         }
      //       : prev
      //   );
      EventEmitter.dispatch("header-refetch", {});
    },
    context: {
      fetchOptions: {
        method: "POST"
      }
    },
    onError: (responseError: ApolloError) => {
      try {
        const networkError = JSON.parse(
          JSON.stringify(responseError?.networkError || {}, null, 2)
        )?.result?.errors?.[0];
        const { errorCode, message, errors } = JSON.parse(
          networkError?.message.substring(6)
        );
        let productNames = "";
        if (errors?.length) {
          productNames =
            getProductNamesByFullSkuId(errors?.map(error => error?.sku)) || "";
        }
        if (errorCode === "INVALID_ITEM_MAX_QUANTITY") {
          return setErrorMessage(message);
        } else if (
          errorCode === "INSUFFICIENT_INVENTORY" ||
          errorCode === "ITEM_QUANTITY_LIMIT_REACHED"
        ) {
          return setErrorMessage(
            `Insufficient inventory for ${productNames || productDisplayName}`
          );
        } else if (errorCode === "MULTIPART_ERROR") {
          return setErrorMessage(
            `Insufficient inventory for ${productNames || productDisplayName}`
          );
        } else if (errorCode === "GIFT_CARD_AMOUNT_EXCEEDS_MAX_ALLOWED") {
          return setErrorMessage(
            `${productDisplayName} EXCEEDS THE MAXIMUM GIFT CART LIMIT OF $10,000.`
          );
        }
        return <AddToCartErrorMessage />;
      } catch (error) {
        console.error(error);
        return <AddToCartErrorMessage />;
      }
    },
    variables
  });

  const numberOfItemsAddedToCartFromPanel = useMemo(
    () =>
      panelVariables?.lineItemsInput?.items.reduce(
        (totalQty, item) => totalQty + item?.quantity,
        0
      ),
    [panelVariables]
  );

  const [addPanelItemsToCart, { loading: panelLoading, error: panelError }] =
    useMutation<
      Pick<Mutation, "addLineItemsToCart">,
      MutationAddLineItemsToCartArgs
    >(addLineItemsToCart, {
      onCompleted: async data => {
        setConfirmed?.(true);
        onCompleted?.(data?.addLineItemsToCart);
        await getCartProjection();
        EventEmitter.dispatch("header-refetch", {});
      },
      context: {
        fetchOptions: {
          method: "POST"
        }
      },
      onError: (responseError: ApolloError) => {
        const networkError = JSON.parse(
          JSON.stringify(responseError?.networkError || {}, null, 2)
        )?.result?.errors?.[0];
        const {
          errorCode,
          message,
          errors,
          sku: errorSku
        } = JSON.parse(networkError?.message.substring(6));
        let productNames = getProductNamesByFullSkuId([errorSku]) || "";
        if (errors?.length) {
          productNames =
            getProductNamesByFullSkuId(errors?.map(error => error?.sku)) || "";
        }
        try {
          if (errorCode === "INVALID_ITEM_MAX_QUANTITY") {
            return setErrorMessage(message);
          } else if (
            errorCode === "INSUFFICIENT_INVENTORY" ||
            errorCode === "ITEM_QUANTITY_LIMIT_REACHED"
          ) {
            return setErrorMessage(
              `Insufficient inventory for ${productNames || productDisplayName}`
            );
          } else if (errorCode === "MULTIPART_ERROR") {
            return setErrorMessage(
              `Insufficient inventory for ${productNames || productDisplayName}`
            );
          } else if (errorCode === "GIFT_CARD_AMOUNT_EXCEEDS_MAX_ALLOWED") {
            return setErrorMessage(
              `${productDisplayName} EXCEEDS THE MAXIMUM GIFT CART LIMIT OF $10,000.`
            );
          }
          return <AddToCartErrorMessage />;
        } catch (error) {
          return <AddToCartErrorMessage />;
        }
      },
      variables: panelVariables
    });

  const [isEffectCalled, setIsEffectCalled] = useState<boolean>(false);
  useEffect(() => {
    if (rest?.open) {
      let isAddonSpo: addOnDataType[] | undefined = [];
      if (!spoAgreed) {
        isAddonSpo = productAddons?.filter(item => item?.spo) || [];
        isAddonSpo && setSpoAgreed(false);
      }
      if (
        (enabled && !spo && isAddonSpo?.length === 0) ||
        (enabled && spo && spoAgreed)
      ) {
        if (!isEffectCalled) {
          setIsEffectCalled(true);
          addItemsToCart({
            update(cache, result) {
              cache.writeQuery({
                query: queryGetCart,
                variables: { email: rhUser?.email, cartId },
                data: { getCart: result?.data?.addLineItemsToCart }
              });
            }
          });
        }
      }
    }
  }, [
    isEffectCalled,
    rhUser?.email,
    cartId,
    addItemsToCart,
    enabled,
    spoAgreed,
    rest?.open,
    spo
  ]);

  const handleSpoAgreed = useCallback(() => {
    setSpoAgreed(true);
  }, []);

  useEffect(() => {
    if (addOnsExist) {
      setShowPanelAddon(true);
      // if (spo) setAddedFromPanel(true);
    }
  }, [productAddons, panelProdConfigurationData]);

  const qtyAddedToCart = addedFromPanel
    ? numberOfItemsAddedToCartFromPanel
    : numberOfItemsAddedToCartFromPDP;

  useEffect(() => {
    if (isNewPDPLayout) {
      setPanelLineItems(
        panelAddOnsData?.map(addOn => ({
          productId: addOn?.productId,
          sku: addOn?.fullSkuId,
          fusionId: getFusionId,
          quantity: 1,
          brand: brand,
          giftTo: giftCardTo ?? "",
          giftFrom: giftCardFrom ?? "",
          giftMessage: "",
          spoTermsAccepted: spoAgreed,
          spoTerms: spoAgreed ? spoTerms : null,
          // ...(monogramOrder?.fontCode ||
          // monogramOrder?.fontColorCode ||
          // monogramOrder?.borderCode
          //   ? { monogram: cleanMonogramOrder(monogramOrder) }
          //   : null),
          ...(!isEmpty(customInfo) ? { customInfo } : null)
        })) ?? []
      );
    }
  }, [panelAddOnsData]);

  const handlePanelAddToCartClick = useCallback(() => {
    setAddedFromPanel(true);
    setShowPanelAddon(false);
    if (!isNewPDPLayout) {
      setPanelLineItems([
        {
          productId: panelProdConfigurationData?.productId,
          sku: panelProdConfigurationData?.fullSkuId,
          fusionId: getFusionId,
          quantity: panelQty,
          brand: brand,
          giftTo: giftCardTo ?? "",
          giftFrom: giftCardFrom ?? "",
          giftMessage: "",
          spoTermsAccepted: spoAgreed,
          spoTerms: spoAgreed ? spoTerms : null,
          // ...(monogramOrder?.fontCode ||
          // monogramOrder?.fontColorCode ||
          // monogramOrder?.borderCode
          //   ? { monogram: cleanMonogramOrder(monogramOrder) }
          //   : null),
          ...(!isEmpty(customInfo) ? { customInfo } : null)
        }
      ]);
    }
    addPanelItemsToCart({
      update(cache, result) {
        cache.writeQuery({
          query: queryGetCart,
          variables: { email: rhUser?.email, cartId },
          data: { getCart: result?.data?.addLineItemsToCart }
        });
      }
    });
  }, [
    productAddons,
    panelProdConfigurationData,
    getFusionId,
    panelQty,
    brand,
    giftCardTo,
    giftCardFrom,
    spoAgreed,
    spoTerms,
    monogramOrder,
    customInfo,
    addPanelItemsToCart,
    rhUser?.email,
    cartId
  ]);
  const SPOAddon = productAddons?.filter(item => item?.spo)?.length;

  return (
    <DrawerComponent
      id={rest?.id || "dialog-add-to-cart_drawer"}
      open={true}
      anchor={"right"}
      role="dialog"
      tabIndex={0}
      stopBackgroundScrolling={true}
      aria-label={
        (spo && !confirmed) || (SPOAddon && !confirmed)
          ? "Special Order"
          : `${qty} ${qty > 1 ? pageContent?.ITEMS : pageContent?.ITEM} ${
              pageContent?.ADDED_TO_YOUR_CART
            }`
      }
      {...rest}
      BackdropProps={{
        style: isNewPDPLayout
          ? {
              backgroundColor: "var(--ReimagineWarm-RH-Black, #000)",
              opacity: 0.15
            }
          : {},
        invisible: false
      }}
      PaperProps={{
        className: env?.FEATURE_PDP
          ? "w-[80%] sm:w-1/2 md:w-1/3"
          : "w-full sm:w-[69.4%] md:w-[53.73%] lg:w-[44.42%] xl:w-1/3",
        ...rest.PaperProps
      }}
    >
      <RHDialogTitle
        id={"dialog-add-to-cart_title"}
        style={{
          backgroundColor: env.FEATURE_BG_GREY
            ? theme.palette.background.default
            : "initial"
        }}
        solidWhite
        onClose={rest.onClose}
      />
      <DialogContent
        className={
          env?.FEATURE_PDP
            ? classes.rhrContent
            : !showPanelAddon
            ? classes.addonPanelContent
            : classes.content
        }
      >
        <AddToCartDialog
          productAddonDisplayOptions={productAddonDisplayOptions}
          fetchOptionStatus={fetchOptionStatus}
          monogramOrder={monogramOrder}
          productId={productId}
          productAddons={productAddons}
          productAddonsInfo={productAddonsInfo}
          panelProdConfigurationData={panelProdConfigurationData}
          panelProdData={panelProdData}
          panelProds={panelProds}
          panelAddOnsData={panelAddOnsData}
          onAddOnConfigChange={onAddOnConfigChange}
          mainProductAvailableOptions={mainProductAvailableOptions}
          mainProductAvailableSwatch={mainProductAvailableSwatch}
          mainProductChoosenOptions={mainProductChoosenOptions}
          productLineItemOptions={productLineItemOptions}
          handlePanelAddToCartClick={handlePanelAddToCartClick}
          fullSkuId={fullSkuId}
          atgSkuId={atgSkuId}
          qty={qtyAddedToCart}
          preBillMessage={spoTerms}
          productDisplayName={productDisplayName}
          onCompleted={onCompleted}
          options={rest.options}
          pricing={pricing}
          loading={loading || panelLoading}
          error={!!error || !!panelError}
          onClose={rest.onClose}
          confirmed={confirmed}
          onConfirmed={handleSpoAgreed}
          showPanelAddon={showPanelAddon}
          errorMessage={errorMessage}
          salePriceLabel={rest?.salePriceLabel}
          setConfirmed={setConfirmed}
          spo={spo}
          multiSkuComponents={multiSkuComponents}
          setAddedFromPanel={setAddedFromPanel}
          // lineItemOptionSelectionForPanelAddOns={
          //   rest?.lineItemOptionSelectionForPanelAddOns
          // }
          // refactoredAddOnData={refactoredAddOnData}
          // SPOAddonVariables={refactoredSPOAddonData()}
          productAddedFromPanel={addedFromPanel}
          panelQty={panelQty}
          setPanelQty={setPanelQty}
          addToCartItemDetails={addToCartItemDetails}
          incomingPanelProdConfigData={incomingPanelProdConfigData}
          onPostalCode={onPostalCode}
        />
      </DialogContent>
    </DrawerComponent>
  );
};
