import React, { FC } from "react";
import {
  DialogContent,
  List,
  ListItem,
  ListItemText,
  useTheme,
  DrawerProps
} from "utils/material-ui-core";
import RHDialogTitle, { RHDialogTitleProps } from "component-rh-dialog-title";
import useTypographyStyles from "hooks/useTypographyStyles";
import useState from "hooks/useState";
import RHArrowIcon from "icon-arrow";
import { useHistory } from "react-router";
import RHDivider from "component-rh-divider";
import NavigationDialog from "./";
import buildPath from "utils/buildPath";
import analyticsLoader from "analytics/loader";
import RHShippingSelections from "component-rh-shipping-selections";
import useBrand from "hooks-use-brand/useBrand";
import yn from "yn";
import { useEnv } from "hooks/useEnv";
import RHImage from "component-rh-image";
import { useCookies } from "hooks/useCookies";
import useUserPreferences from "hooks/useUserPreferences";
import useIsoRedirect from "hooks-use-isoredirect";
export interface CustomNavigationElement extends NavigationElement {
  childCategories?: Maybe<CustomNavigationElement[]>;
  props?: any;
  imgSrc?: string;
}

export interface NavigationDialogContentProps extends DrawerProps {
  navigation?: CustomNavigationElement;
  onBack?: (event: {}, reason: "backdropClick" | "escapeKeyDown") => void;
  DialogTitleProps?: RHDialogTitleProps;
}

export const NavigationDialogContent: FC<NavigationDialogContentProps> = ({
  navigation,
  onBack,
  onClose,
  DialogTitleProps,
  children,
  ...rest
}) => {
  const theme = useTheme();
  const history = useHistory();
  const environment = useEnv();
  const shippingEnabled = yn(environment.FEATURE_INTERNATIONAL);
  const [, , removeCookie] = useCookies(["navigating_from_search"]);

  const { previousState, setCountry, setLanguage, setMeasurement } =
    useUserPreferences();

  const brand = useBrand();
  const typographyClasses = useTypographyStyles({
    keys: ["menuCatalogItem"]
  });

  const [selectedChild, setSelectedChild] = useState<
    CustomNavigationElement | undefined
  >(undefined);

  return (
    <>
      {!(rest.variant === "permanent" && selectedChild) && (
        <>
          {navigation?.displayName && (
            <RHDialogTitle
              title={navigation?.displayName?.replace(/<br>/g, "") ?? ""}
              onBack={onBack}
              {...DialogTitleProps}
            />
          )}
          <DialogContent
            style={{
              padding: 0,
              overflow: "auto"
            }}
          >
            <List dense style={{ padding: theme.spacing(2, 0) }}>
              {navigation?.childCategories
                ?.filter(item =>
                  brand === "TN" ? item.displayName !== "Safety Recalls" : item
                )
                ?.map((item, index) =>
                  item.id === "divider" ? (
                    <ListItem
                      key={`navigation-divider-${index}`}
                      style={{
                        padding: theme.spacing(0.4, 3),
                        ...item.props?.style
                      }}
                    >
                      <RHDivider
                        style={{
                          width: "100%"
                        }}
                      />
                    </ListItem>
                  ) : item.id === "shipping" && shippingEnabled ? (
                    <ListItem>
                      <RHShippingSelections
                        onClose={onClose}
                        isGlobalNavigation
                      />
                    </ListItem>
                  ) : (
                    <ListItem
                      id={`dialog-navigation_navigation-${
                        item.uxAttributes?.selectorId ?? item.id
                      }`}
                      data-testid={item.id}
                      data-navigation-account-item-id={item.id}
                      key={`navigation-${item.id}`}
                      button
                      {...item.props}
                      onClick={e => {
                        analyticsLoader(a =>
                          a.emitAnalyticsEvent(
                            // @ts-ignore
                            document.querySelector(
                              "#spa-root > *"
                            )! as HTMLElement,
                            a.EVENTS.ADD_TO_LOCALSTORAGE.INT_TYPE,
                            { item }
                          )
                        );
                        item.props?.onClick?.();
                        handleNavigationClick(e, item);
                      }}
                      style={{
                        padding: theme.spacing(0.4, 3),
                        ...(item?.displayName?.includes("Shop RH")
                          ? {
                              backgroundColor: "#f5f5f5",
                              marginBottom: 1,
                              marginTop: 1
                            }
                          : {}),
                        ...item.props?.style
                      }}
                    >
                      {item?.imgSrc && (
                        <RHImage
                          rootProps={{
                            style: {
                              width: 28,
                              height: 18
                            }
                          }}
                          src={item?.imgSrc}
                        />
                      )}
                      <ListItemText
                        primary={
                          <span
                            className={typographyClasses.menuCatalogItem}
                            dangerouslySetInnerHTML={{
                              __html: item.displayName.replace(/<br>/g, "")
                            }}
                          />
                        }
                        primaryTypographyProps={{
                          style: {
                            ...(!item.displayName.includes("Shop RH")
                              ? {
                                  textTransform: "initial"
                                }
                              : {})
                          }
                        }}
                      />
                      {!!item.childCategories?.length && (
                        <RHArrowIcon
                          style={{
                            fontSize: 13,
                            width: "13px !important",
                            height: "13px !important"
                          }}
                        />
                      )}
                    </ListItem>
                  )
                )}
            </List>
            {children}
          </DialogContent>
        </>
      )}
      {!!navigation?.childCategories && (
        <NavigationDialog
          {...rest}
          data-testid="NavigationDialog"
          open={!!selectedChild}
          navigation={selectedChild}
          onBack={handleBack}
          onClose={handleClose}
          ModalProps={{
            ...rest.ModalProps,
            style: {
              ...rest.ModalProps?.style
            }
          }}
          PaperProps={{
            ...rest.PaperProps,
            style: {
              boxShadow: "none",
              ...rest.PaperProps?.style
            }
          }}
          DialogTitleProps={DialogTitleProps}
        />
      )}
    </>
  );

  function handleBack(
    event: { isSaving?: boolean },
    reason: "backdropClick" | "escapeKeyDown"
  ) {
    if (!event?.isSaving) {
      setCountry(previousState.country);
      setLanguage(previousState.language);
      setMeasurement(previousState.measurement);
    }
    if (reason === "escapeKeyDown") {
      analyticsLoader(a =>
        a.emitAnalyticsEvent(
          document.querySelector("#spa-root > *")! as HTMLElement,
          a.EVENTS.ADD_TO_LOCALSTORAGE.INT_TYPE,
          { type: "mob" }
        )
      );
    }
    setSelectedChild(undefined);
  }

  function handleClose(event: {}, reason: "backdropClick" | "escapeKeyDown") {
    setSelectedChild(undefined);
    onClose?.(event, reason);
  }

  function handleNavigationClick(
    event: React.MouseEvent,
    item: NavigationElement
  ) {
    const target = event.target as HTMLElement;
    const id = (target?.parentNode as HTMLElement)?.id;
    if ((item.childCategories?.length ?? 0) === 0) {
      analyticsLoader(a => {
        a.emitAnalyticsEvent(
          // @ts-ignore
          document.querySelector("#spa-root > *")!,
          a.EVENTS.ADD_TO_LOCALSTORAGE.INT_TYPE,
          { remove: "analytics-navigatingFromSearch" }
        );
      });
      removeCookie("navigating_from_search");
      if (item.targetUrl) {
        if (!item.id?.includes("sign-in")) {
          document?.body?.dispatchEvent(
            new CustomEvent("cta_click", {
              detail: {
                item: {
                  isDrawerNavigation: true,
                  navigationElement: item,
                  class: target?.className,
                  id: target?.id || id
                }
              }
            })
          );
        }
        if (item.targetUrl.startsWith("http")) {
          // link to outside React App
          useIsoRedirect(item.targetUrl, "windowAssign");
        } else if (
          item.targetUrl.includes("/catalog/category") ||
          item.targetUrl.includes("rooms")
        ) {
          history.push(buildPath(item.targetUrl));
        } else if (
          item.targetUrl.includes("new-results") ||
          item.targetUrl.includes("results")
        ) {
          history.push(buildPath(item.targetUrl, { fromNav: "true" }));
        } else {
          history.push(item.targetUrl);
        }
      }
      handleClose({}, "backdropClick");
    } else {
      setSelectedChild(item);
    }
  }
};

NavigationDialogContent.defaultProps = {
  anchor: "left"
};

export default NavigationDialogContent;
