import React, { FC } from "react";
import FormDialog from "layout-form-dialog";
import {
  createStyles,
  makeStyles,
  Theme,
  Grid,
  Typography,
  useTheme
} from "@material-ui/core";
import RHDivider from "component-rh-divider";
import { queryReturnContentModalFragment } from "graphql-client/queries/content";
import { useQuery } from "@apollo/client";
import RHSpinner from "component-rh-spinner";
import { KEY_GROUP_MAPPERS } from "utils/customProductHelper";
import memoize from "utils/memoize";
import { BREAKPOINT_SM } from "utils/constants";

export interface CustomProductInfoDialogProps {
  open: boolean;
  customProductType: string;
  dialogType: string;
  onClose: () => void;
}
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    textCenter: { textAlign: "center" },
    paper: {
      width: BREAKPOINT_SM,
      [theme.breakpoints.down("sm")]: {
        width: "100%",
        maxWidth: "100%",
        margin: 0,
        position: "absolute",
        top: 0
      }
    }
  })
);
export const CustomProductInfoDialog: FC<CustomProductInfoDialogProps> = ({
  open,
  onClose,
  dialogType,
  customProductType
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const { loading, data: { contentFragment } = {} as Query } = useQuery(
    queryReturnContentModalFragment,
    {
      variables: {
        group: KEY_GROUP_MAPPERS[customProductType].group,
        key: KEY_GROUP_MAPPERS[customProductType][dialogType].val
      },
      notifyOnNetworkStatusChange: true
    }
  );
  return (
    <FormDialog
      open={open}
      onClose={onClose}
      PaperProps={{ className: classes.paper, square: true }}
    >
      {loading && <RHSpinner />}
      <Grid container>
        {contentFragment && (
          <>
            <Grid item>
              <Typography variant="h2">
                {KEY_GROUP_MAPPERS[customProductType][dialogType].header}
              </Typography>
            </Grid>
            <Grid
              item
              container
              style={{
                marginTop: theme.spacing(2)
              }}
            >
              <RHDivider style={{ borderColor: "#E6E6E6", width: "100%" }} />
            </Grid>
            <Grid item>
              <Typography
                dangerouslySetInnerHTML={{ __html: contentFragment?.text }}
              />
            </Grid>
          </>
        )}
      </Grid>
    </FormDialog>
  );
};
CustomProductInfoDialog.defaultProps = {};
export default memoize(CustomProductInfoDialog);
