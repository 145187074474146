import React, {
  FC,
  useCallback,
  SetStateAction,
  Dispatch,
  useMemo
} from "react";
import { useFetchModel } from "hooks/useFetchModel";
import {
  Button as MUIButton,
  useMediaQuery,
  Theme,
  makeStyles,
  createStyles,
  useTheme
} from "@material-ui/core";
import memoize from "utils/memoize";
import RHUnderline from "component-rh-underline";
import { Skeleton } from "@material-ui/lab";
import { useMembership, useMembershipMutation } from "hooks/useMembership";
import { useMembershipInfoAtomValue } from "hooks/atoms";
import { useRhUserAtomValue } from "hooks/atoms";
import { useUserSessionAtomValue } from "hooks/atoms";
import { useEnv } from "hooks/useEnv";
import { useLocation } from "react-router-dom";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      [theme.breakpoints.only("xl")]: {
        width: "30%"
      },
      [theme.breakpoints.only("lg")]: {
        width: "35%"
      },
      [theme.breakpoints.only("sm")]: {
        width: "50%"
      },
      [theme.breakpoints.only("xs")]: {
        width: "80%"
      }
    },
    content: {
      overflow: "auto"
    },
    MuiButton: {
      root: {
        color: "#999999"
      }
    },
    containedPrimary: {
      color: "#FFFFFF",
      backgroundColor: "#000000",
      minWidth: "215px",
      fontSize: "11px",
      fontWeight: 400,
      height: "45px",
      transition: ".4s ease-in",
      "&:hover": {
        backgroundColor: "#666"
      }
    },
    fullWidthModalButtonSize: {
      color: "#FFFFFF",
      textTransform: "capitalize",
      backgroundColor: "#000000",
      minWidth: "50%",
      [theme.breakpoints.down(610)]: {
        minWidth: "0%",
        width: theme.typography.pxToRem(343)
      }
    },
    containedSecondary: {
      color: "#000000",
      borderStyle: "solid",
      minWidth: "50%",
      borderWidth: 1,
      background: "transparent",
      borderColor: "#cccccc",
      "&:hover": {
        backgroundColor: "#fafafa",
        borderColor: "#cccccc"
      }
    }
  })
);

export interface RHAddToCartMembershipDialog {
  label?: string;
  fullWidth?: boolean;
  cartType?: boolean;
  linkType?: boolean;
  removeMember?: boolean;
  hasActiveMembership?: boolean;
  dialogDisplay?: boolean;
  onJoinMembership?: () => void;
  removeMembershipHandler?: () => void;
  loading?: Dispatch<SetStateAction<Boolean>>;
  loadingHavingCart?: boolean;
  refetchGetCart?: () => void;
  onRestoreMembershipCompleted?: (
    data: Pick<Mutation, "restoreMembership">
  ) => void;
  isSPODialog?: boolean;
  className?: string;
  setMembershipLoading?: Dispatch<SetStateAction<Boolean>>;
  isMemberShipInCart?: boolean;
}

export const AddToCartMembershipDialog: FC<RHAddToCartMembershipDialog> = ({
  label: labelProp,
  loading: onLoading, // TODO fix this prop key name in the parents by using style-guide naming convention onLoading instead of loading
  cartType,
  linkType,
  removeMember,
  dialogDisplay,
  hasActiveMembership,
  onJoinMembership,
  removeMembershipHandler,
  loadingHavingCart,
  refetchGetCart,
  onRestoreMembershipCompleted,
  fullWidth,
  isSPODialog,
  className,
  setMembershipLoading,
  isMemberShipInCart = false
}) => {
  const { pathname } = useLocation();
  const isAemPage = !pathname?.includes(".jsp");
  /* theme and style */
  const theme = useTheme();
  const classes = useStyles();
  const isXs = useMediaQuery(theme.breakpoints.down("xs"));
  const { pageContent } = useFetchModel("/admin/membership");

  const { loading } = useUserSessionAtomValue();
  const { currentCartId: cartId } = useUserSessionAtomValue();
  const rhUser = useRhUserAtomValue();
  const membershipInfo = useMembershipInfoAtomValue();
  const env = useEnv();
  const { membership, loading: loadingMembership } = useMembership({
    skip: loading || !membershipInfo?.userHasActiveMembership
  });

  const {
    addMembershipItemsToCart,
    addMembershipLoading,
    removeMembership,
    removeMembershipLoading,
    restoreMembership,
    restoreMembershipLoading
  } = useMembershipMutation({
    membership,
    onRestoreMembershipCompleted,
    cartType,
    onJoinMembership,
    removeMembershipHandler,
    onLoading,
    isAutoMembershipAdd: isAemPage ? false : true,
    refetchGetCart,
    setMembershipLoading
  });

  /* add membership to cart handler */
  const handleAddMembershipToCart = useCallback(async () => {
    if (onLoading) {
      onLoading(true);
    }
    if (
      hasActiveMembership ||
      (membershipInfo?.userHasActiveMembership &&
        membershipInfo?.userHasCancelledMembership)
    )
      return restoreMembership();

    setMembershipLoading?.(true);
    await addMembershipItemsToCart();
  }, [
    addMembershipItemsToCart,
    hasActiveMembership,
    membershipInfo?.userHasActiveMembership,
    membershipInfo?.userHasCancelledMembership,
    restoreMembership,
    setMembershipLoading,
    onLoading
  ]);

  /* remove membership from cart handler */
  const handleRemoveMembershipFromCart = useCallback(async () => {
    if (onLoading) {
      onLoading(true);
    }
    setMembershipLoading?.(true);
    await removeMembership({
      variables: {
        email: rhUser?.email,
        cartId
      }
    });
  }, [
    cartId,
    onLoading,
    removeMembership,
    rhUser?.email,
    setMembershipLoading
  ]);
  const label = useMemo<string>(() => {
    if (isMemberShipInCart || membershipInfo?.membershipSkuIsOnCart) {
      return pageContent?.MEMBERSHIP_ADDED_TO_CART;
    } else if (membershipInfo?.userHasActiveMembership) {
      if (membershipInfo?.userHasCancelledMembership) {
        return labelProp || "";
      } else {
        return pageContent?.YOURE_ALREADY_MEMBER;
      }
    }
    return labelProp || pageContent?.JOIN_NOW;
  }, [
    labelProp,
    membershipInfo?.membershipSkuIsOnCart,
    membershipInfo?.userHasActiveMembership,
    membershipInfo?.userHasCancelledMembership,
    pageContent,
    isMemberShipInCart
  ]);

  const isDisabled: boolean =
    isMemberShipInCart ||
    addMembershipLoading ||
    restoreMembershipLoading ||
    membershipInfo?.membershipSkuIsOnCart === true ||
    (membershipInfo?.userHasActiveMembership === true &&
      membershipInfo?.userHasCancelledMembership === false);

  if (loading)
    return (
      <Skeleton
        width={linkType ? "10em" : "15em"}
        height={linkType ? "1em" : "5em"}
      />
    );

  const addMembershipStyle = dialogDisplay
    ? env?.FEATURE_MEMBERSHIP_BANNER_SIGNIN
      ? ({
          fontSize: "11px",
          letterSpacing: "0.44px",
          color: "black",
          fontFamily: "RHSans-Light",
          lineHeight: "12px",
          fontWeight: 300,
          cursor: "pointer",
          textTransform: "none",
          textDecoration: "none",
          paddingBottom: 1,
          borderBottom: "0.5px solid #000"
        } as const)
      : ({
          fontSize: theme.typography.pxToRem(12),
          letterSpacing: theme.typography.pxToRem(0.5),
          color: "black",
          textTransform: "uppercase"
        } as const)
    : undefined;

  const disableStyle =
    !removeMember && isDisabled
      ? { cursor: "not-allowed", textDecoration: "none" }
      : {};
  return (
    <>
      {linkType ? (
        <RHUnderline
          id="addToCartMembershipDialog_becomeMember-link"
          onClick={() => {
            if (!removeMember) {
              !addMembershipLoading &&
                !loadingMembership &&
                handleAddMembershipToCart();
            } else {
              !removeMembershipLoading && handleRemoveMembershipFromCart();
            }
          }}
          style={{ ...addMembershipStyle, ...disableStyle }}
          aria-label={removeMember ? labelProp : label}
          role="button"
          className={className}
          disabled={!removeMember && isDisabled}
        >
          {loadingHavingCart ? (
            <Skeleton width={"6em"} height={"3em"} />
          ) : removeMember ? (
            labelProp
          ) : (
            label
          )}
        </RHUnderline>
      ) : (
        <MUIButton
          id="addToCartMembershipDialog_becomeMember-btn"
          className={
            !!fullWidth
              ? classes.fullWidthModalButtonSize
              : isSPODialog
              ? classes.containedSecondary
              : classes.containedPrimary
          }
          variant="contained"
          color="primary"
          onClick={() => {
            if (!removeMember) {
              !addMembershipLoading &&
                !loadingMembership &&
                handleAddMembershipToCart();
            } else {
              !removeMembershipLoading && handleRemoveMembershipFromCart();
            }
          }}
          fullWidth={isXs ? true : false}
          disabled={isDisabled}
          style={{ width: isXs ? "100%" : "" }}
        >
          {removeMember ? labelProp : label}
        </MUIButton>
      )}
    </>
  );
};

export default memoize(AddToCartMembershipDialog);
