import React, { FC, useMemo, useCallback } from "react";
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
  IconButton,
  Theme,
  makeStyles,
  createStyles,
  MenuItem,
  Grid
} from "utils/material-ui-core";
import {
  BREAKPOINT_SM,
  FONT_BARON_SANS_THIN_BASICS,
  FONT_BOLD,
  FONT_LIGHT,
  FONT_BARON_SANS_LIGHT_BASICS
} from "utils/constants";
import { DialogProps } from "@material-ui/core/Dialog";
import { RHFormTextField } from "component-rh-text-field";
import { useQuery } from "@apollo/client";
import useState from "hooks/useState";
import RHCloseIcon from "icon-close";
import useAppData from "hooks/useAppData";
import EventEmitter from "utils-event-emitter";
import { useForm } from "react-hook-form";
import RHSpinner from "component-rh-spinner";
import yn from "yn";
import { useEnv } from "hooks/useEnv";
import RHStandardSelect from "component-rh-standard-select";
import {
  countries,
  localIdentifierToLanguageCodeMapper
} from "resources/countries-config.json";
import { queryValidatePostalCodeByCountryCode } from "graphql-client/queries/address";
import useTypographyStyles from "hooks/useTypographyStyles";
import classNames from "classnames";
import useUserPreferences from "hooks/useUserPreferences";
import { formatePostalCode } from "utils/postalcode-validation";
import { usePageContent } from "customProviders/LocationProvider";
import RHRFlagIcon from "icons/RHRFlagIcon";
import { useLanguage } from "@RHCommerceDev/hooks/useLanguage";
import { useIsoCookies } from "hooks/useIsoCookies";
import { useLocation } from "react-router";

export interface InternationalPostalCodeDialogProps {
  onClose?: (
    event: { postalCode?: string; country: string },
    reason?: string
  ) => void;
  onConfirm?: () => void;
  value?: string;
  error?: string;
  disableConfirmButton?: boolean;
  setScrollVal?: (scrollVal: number) => void;
  loading?: boolean;
  selectedCountry?: string;
  setSelectedCountry?: (country: string) => void;
  setPostalCode?: (zipCode: string) => void;
  openConfirmationDialog?: () => void;
  handleInStockDrawer?: () => void;
  refetchGetCart?: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialogWrapper: {
      maxWidth: "563px",
      [theme.breakpoints.down(BREAKPOINT_SM)]: {
        width: "419px"
      }
    },
    dialogRoot: {
      [theme.breakpoints.down(BREAKPOINT_SM)]: {
        paddingBottom: `0 !important`
      }
    },
    dialogTitle: { padding: 0 },
    dialogContentContainer: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      width: "calc(100% - 126px)",
      padding: theme.spacing(8),
      margin: "0 auto",
      [theme.breakpoints.down(BREAKPOINT_SM)]: {
        width: "100%",
        padding: theme.spacing(8, 9)
      },
      [theme.breakpoints.down(456)]: {
        width: "100%",
        padding: theme.spacing(7, 4)
      }
    },
    dialogHeaderTitle: {
      fontSize: theme.typography.pxToRem(15),
      fontFamily: FONT_BARON_SANS_THIN_BASICS,
      marginBottom: "40px",
      [theme.breakpoints.down(BREAKPOINT_SM)]: {
        marginBottom: theme.spacing(4)
      }
    },
    dialogHeaderText: {
      width: "100%",
      paddingBottom: theme.spacing(4),
      textAlign: "center",
      fontWeight: FONT_BOLD,
      fontSize: theme.typography.pxToRem(15),
      fontFamily: FONT_BARON_SANS_THIN_BASICS
    },
    dialogLastText: {
      paddingBottom: theme.spacing(3),
      textAlign: "center",
      fontWeight: FONT_LIGHT,
      fontSize: theme.typography.pxToRem(12),
      display: "block"
    },
    flagConatiner: {
      display: "flex",
      alignItems: "center",
      textTransform: "none",
      letterSpacing: 0,
      fontSize: 11,
      cursor: "pointer"
    },
    confirmButton: {
      fontFamily: FONT_BARON_SANS_LIGHT_BASICS,
      fontSize: theme.typography.pxToRem(11),
      height: "48px",
      marginBottom: theme.spacing(4)
    },
    formContainer: {
      maxWidth: theme.typography.pxToRem(250)
    },
    select: {
      color: "#404040",
      fontSize: "13px",
      fontWeight: 600
    },
    selectIcon: {
      color: "#000",
      right: theme.spacing(1.5),
      fontSize: theme.spacing(1.2)
    },
    imageStyle: {
      width: theme.spacing(1.6),
      height: theme.spacing(1),
      marginRight: theme.spacing(0.7)
    },
    selectCountry: {
      width: "100%",
      marginTop: theme.spacing(0),
      marginBottom: theme.spacing(3.5),
      [theme.breakpoints.down(BREAKPOINT_SM)]: {
        marginBottom: theme.spacing(3)
      }
    },
    postalCode: {
      marginTop: theme.spacing(0),
      marginBottom: theme.spacing(3.5),
      [theme.breakpoints.down(BREAKPOINT_SM)]: {
        marginBottom: theme.spacing(3)
      },
      "& #postal-code-international::-webkit-contacts-auto-fill-button": {
        visibility: "hidden",
        display: "none !important",
        pointerEvents: "none",
        position: "absolute",
        right: 0
      }
    },
    countrySelectError: {
      fontSize: "11px",
      fontFamily: FONT_BARON_SANS_LIGHT_BASICS,
      width: "100%",
      marginBottom: "4px",
      marginTop: theme.spacing(-3),
      color: "#CA6667"
    },
    reEnterCode: {
      paddingBottom: theme.spacing(2),
      textDecorationLine: "underline",
      cursor: "pointer",
      marginTop: theme.spacing(0)
    },
    iconButton: {
      position: "absolute",
      top: 0,
      right: 0
    },
    disabled: {
      pointerEvents: "none"
    },
    flagIcon: {
      marginRight: 10
    }
  })
);

const onlyENLanguages = Object.entries(localIdentifierToLanguageCodeMapper)
  .filter(([key, value]) => value === "en")
  .map(([key]) => key);

export const InternationalPostalCodeDialog: FC<
  Omit<InternationalPostalCodeDialogProps, "onConfirm" | "onClose">
> = ({
  value,
  error,
  disableConfirmButton,
  selectedCountry,
  openConfirmationDialog,
  loading,
  ...rest
}) => {
  const env = useEnv();
  const { control } = useForm({
    defaultValues: {
      postalCode: value || ""
    }
  });
  const { pageContent } = usePageContent();
  const lang = useLanguage();

  const FEATURE_SUPPRESS_UK = yn(env.FEATURE_SUPPRESS_UK);
  const FEATURE_EU_EXPANSION_DE = yn(env.FEATURE_EU_EXPANSION_DE);
  const FEATURE_EU_EXPANSION_BE = yn(env.FEATURE_EU_EXPANSION_BE);
  const FEATURE_EU_EXPANSION_ES = yn(env.FEATURE_EU_EXPANSION_ES);
  const FEATURE_EU_EXPANSION_FR = yn(env.FEATURE_EU_EXPANSION_FR);
  const FEATURE_EU_EXPANSION_DE_LANG = yn(env.FEATURE_EU_EXPANSION_DE_LANG);
  const FEATURE_INTERNATIONAL_LANGUAGE_PREFERENCE = yn(
    env.FEATURE_INTERNATIONAL_LANGUAGE_PREFERENCE
  );

  const countryOptions = useMemo(() => {
    if (!FEATURE_EU_EXPANSION_DE_LANG) {
      const filteresAvailableLanguages = countries.DE.availableLanguages.filter(
        lang => localIdentifierToLanguageCodeMapper[lang] !== "de"
      );
      const defaultLanguage = filteresAvailableLanguages[0];
      countries.DE.availableLanguages = filteresAvailableLanguages;
      countries.DE.defaultValues.language = defaultLanguage;
    }
    let countriesResult = Object.values(countries);

    if (FEATURE_SUPPRESS_UK) {
      countriesResult = countriesResult.filter(
        country => country.value !== "GB"
      );
    }

    if (!FEATURE_EU_EXPANSION_DE) {
      countriesResult = countriesResult.filter(
        country => country.value !== "DE"
      );
    }

    if (!FEATURE_EU_EXPANSION_BE) {
      countriesResult = countriesResult.filter(
        country => country.value !== "BE"
      );
    }
    if (!FEATURE_EU_EXPANSION_ES) {
      countriesResult = countriesResult.filter(
        country => country.value !== "ES"
      );
    }
    if (!FEATURE_EU_EXPANSION_FR) {
      countriesResult = countriesResult.filter(
        country => country.value !== "FR"
      );
    }

    if (!FEATURE_EU_EXPANSION_ES) {
      countriesResult = countriesResult.filter(
        country => country.value !== "ES"
      );
    }

    if (!FEATURE_INTERNATIONAL_LANGUAGE_PREFERENCE) {
      countriesResult?.forEach(country => {
        country.availableLanguages = country.availableLanguages.filter(value =>
          onlyENLanguages.includes(value)
        );
      });
    }
    countriesResult.sort((country1, country2) =>
      country1?.value === "US"
        ? -1
        : country2?.value === "US"
        ? 1
        : country1.labelEN > country2.labelEN
        ? 1
        : -1
    );

    return countriesResult;
  }, [
    FEATURE_EU_EXPANSION_DE_LANG,
    FEATURE_INTERNATIONAL_LANGUAGE_PREFERENCE,
    FEATURE_SUPPRESS_UK,
    FEATURE_EU_EXPANSION_DE,
    FEATURE_EU_EXPANSION_BE,
    FEATURE_EU_EXPANSION_ES,
    FEATURE_EU_EXPANSION_FR
  ]);

  const imageRootProps = {
    style: {
      width: 24,
      height: 15,
      marginRight: 10,
      backgroundColor: "white"
    }
  };

  const localClasses = useStyles();

  const typographyStyles = useTypographyStyles({
    keys: ["rhBaseBody1", "errorText", "reimagineH4Baron"]
  });

  const handleFieldChange = (field: string) => {
    return (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      rest?.[`set${field}`](e.target.value.toUpperCase());
    };
  };

  return (
    <>
      <Typography
        className={classNames(localClasses.dialogHeaderTitle)}
        style={{ textAlign: "center" }}
      >
        {pageContent?.enterPostalCode}
      </Typography>

      <RHStandardSelect
        id="country-zipcode-selection"
        onChange={handleFieldChange("SelectedCountry")}
        value={selectedCountry}
        className={localClasses.selectCountry}
        SelectProps={{
          MenuProps: {
            getContentAnchorEl: null,
            anchorOrigin: { vertical: "bottom", horizontal: "center" },
            transformOrigin: { vertical: "top", horizontal: "center" }
          },
          classes: {
            root: localClasses.select,
            icon: localClasses.selectIcon
          },
          native: false
        }}
        variant="outlined"
        label={pageContent?.SHIPPING_COUNTRY || "Shipping Country"}
      >
        {countryOptions.map(item => (
          <MenuItem key={`qty-${item.value}`} value={item.value}>
            <div className={localClasses.flagConatiner}>
              <RHRFlagIcon
                className={localClasses.flagIcon}
                country={item.value}
              />
              {item?.postal?.labelsByLanguage?.[lang] || item.postal.label}
            </div>
          </MenuItem>
        ))}
      </RHStandardSelect>

      <RHFormTextField
        onInputChange={handleFieldChange("PostalCode")}
        value={value}
        inputProps={{
          onInput: e => {
            (e.target as EventTarget & HTMLInputElement).value = `${
              (e.target as EventTarget & HTMLInputElement).value
            }`?.toUpperCase();
          }
        }}
        rules={{
          required: true
        }}
        control={control}
        name="postalCode"
        label={pageContent?.POSTAL_CODE || "Postal Code"}
        fullWidth
        id="postal-code-international"
        className={localClasses.postalCode}
      />
      {!!error && (
        <Typography
          className={classNames([localClasses.countrySelectError])}
          color="error"
        >
          {error}
        </Typography>
      )}
      <Button
        variant="contained"
        color="primary"
        data-testid={"submit-postal"}
        onClick={() => openConfirmationDialog?.()}
        fullWidth
        autoFocus
        disabled={disableConfirmButton}
      >
        {pageContent?.confirm}
      </Button>
    </>
  );
};

InternationalPostalCodeDialog.defaultProps = {};

export interface InternationalCurrencyWarningDialogProps {
  currencyCode?: string;
  value?: string;
  onClose?: (event: {}) => void;
  onUpdate?: () => void;
  openConfirmationDialog?: () => void;
  loading?: boolean;
  disableButtons: boolean;
}

export const InternationalCurrencyWarningDialog: FC<
  InternationalCurrencyWarningDialogProps
> = ({ onUpdate, openConfirmationDialog, disableButtons }) => {
  const localClasses = useStyles();

  const typographyStyles = useTypographyStyles({
    keys: ["rhBaseBody1", "rhTextLink"]
  });
  const { pageContent } = usePageContent();

  return (
    <>
      <Grid xs={12} container direction="column" alignItems="center">
        <Typography className={classNames(localClasses.dialogHeaderText)}>
          {pageContent?.confirmation}
        </Typography>

        <Typography
          className={classNames([
            localClasses.dialogLastText,
            typographyStyles.rhBaseBody1
          ])}
        >
          {pageContent?.messageText}
        </Typography>

        <Button
          autoFocus
          color="primary"
          fullWidth
          disabled={disableButtons}
          variant="contained"
          onClick={onUpdate}
          className={localClasses.confirmButton}
        >
          {pageContent?.confirmChange}
        </Button>

        <Typography
          onClick={openConfirmationDialog}
          className={classNames([
            localClasses.reEnterCode,
            typographyStyles.rhTextLink,
            { [localClasses.disabled]: disableButtons }
          ])}
        >
          {pageContent?.reEnterCode}
        </Typography>
      </Grid>
    </>
  );
};

export default ({
  open,
  onConfirm,
  refetchGetCart,
  setScrollVal,
  ...rest
}: Omit<InternationalPostalCodeDialogProps, "value" | "onUpdate" | "error"> &
  Omit<DialogProps, "children">) => {
  const env = useEnv();
  const { pathname } = useLocation();
  const { app, setApp } = useAppData();
  const localClasses = useStyles();
  const {
    previousState: { country: countryFromUrl, language: languageFromUrl },
    setCountry,
    setLanguage,
    handleSaveCookies
  } = useUserPreferences();
  const { pageContent } = usePageContent();
  const postalCodeFromCookie = useIsoCookies(["pc"], true)?.pc || "";

  const [postalCodeError, setPostalCodeError] = useState("");
  const [submitLoading, setSubmitLoading] = useState(false);
  const [postalCode, setPostalCode] = useState(postalCodeFromCookie);
  const [selectedCountry, setSelectedCountry] = useState(countryFromUrl);
  const [isOpenConfirmationDialog, setIsOpenConfirmationDialog] =
    useState(false);

  const shouldChanged = useMemo(
    () => countryFromUrl !== selectedCountry,
    [countryFromUrl, selectedCountry]
  );

  /* Method to reset postal code dialog content to default */
  const resetPostalCodeToDefault = () => {
    setPostalCode("");
    setPostalCodeError("");
  };

  const closeDialog = useCallback(
    (reason = "backdropClick") => {
      if (!!rest.onClose) {
        rest.onClose({ postalCode, country: selectedCountry }, reason);
        resetPostalCodeToDefault();
      }
    },
    [postalCode, rest, selectedCountry]
  );

  const { refetch: validatePostalCode, loading: validatePostalCodeLoading } =
    useQuery<Query>(queryValidatePostalCodeByCountryCode, {
      fetchPolicy: "no-cache",
      skip: true
    });

  const onCompleteUpdatePostalCode = useCallback(async () => {
    const language =
      countries[selectedCountry]?.availableLanguages?.filter(language =>
        languageFromUrl?.includes(localIdentifierToLanguageCodeMapper[language])
      )?.[0] || countries[selectedCountry]?.defaultValues?.language;
    document?.body?.dispatchEvent(
      new CustomEvent("cta_click", {
        detail: {
          item: {
            isCountrySwitch: true,
            country: selectedCountry,
            language: language
          }
        }
      })
    );
    const formattedPC = formatePostalCode(postalCode, selectedCountry);
    setCountry(selectedCountry);
    setLanguage(language);
    setSubmitLoading(true);
    handleSaveCookies(
      {
        country: selectedCountry,
        language,
        postalCode: formattedPC
      },
      undefined,
      true,
      pathname?.includes("product.jsp") && !shouldChanged ? false : true
    );
    refetchGetCart?.();

    setPostalCode("");

    EventEmitter.dispatch(`zipcode_change_dialog`, true);
    setSubmitLoading(false);
    setIsOpenConfirmationDialog(false);
    closeDialog("confirmClose");
    !!onConfirm && onConfirm();
    setApp({
      ...app,
      postalCode: formattedPC
    });
  }, [
    selectedCountry,
    postalCode,
    setApp,
    app,
    handleSaveCookies,
    setCountry,
    setLanguage,
    refetchGetCart,
    closeDialog,
    onConfirm
  ]);

  const handleConfirm = useCallback(async () => {
    const {
      data: {
        validatePostalCodeByCountryCode: { isValid }
      }
    } = await validatePostalCode({
      postalCode: postalCode,
      countryCode: selectedCountry
    });
    setScrollVal?.(window.scrollY);

    if (!isValid) {
      const error = yn(env.FEATURE_INTERNATIONAL)
        ? pageContent?.invalidZip
        : pageContent?.constantDefaultError;

      setPostalCodeError(error);
      return;
    }

    if (shouldChanged) {
      setIsOpenConfirmationDialog(true);
    } else {
      onCompleteUpdatePostalCode();
    }
    setPostalCodeError("");
  }, [
    env.FEATURE_INTERNATIONAL,
    onCompleteUpdatePostalCode,
    pageContent?.constantDefaultError,
    pageContent?.invalidZip,
    postalCode,
    selectedCountry,
    shouldChanged,
    validatePostalCode
  ]);

  const handleSetSelectedCountry = (country: string) => {
    setSelectedCountry(country);
    resetPostalCodeToDefault();
  };

  const allLoading = useMemo(() => {
    return submitLoading || validatePostalCodeLoading;
  }, [submitLoading, validatePostalCodeLoading]);

  return (
    <Dialog
      classes={{
        paperFullWidth: localClasses.dialogWrapper,
        root: localClasses.dialogRoot
      }}
      fullWidth
      maxWidth="sm"
      open={open}
      {...rest}
      onClose={closeDialog}
    >
      {allLoading && <RHSpinner style={{ zIndex: 999 }} />}
      <DialogTitle
        classes={{ root: localClasses.dialogTitle }}
        disableTypography
      >
        <IconButton className={localClasses.iconButton} onClick={closeDialog}>
          <RHCloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent className={localClasses.dialogContentContainer}>
        {shouldChanged && isOpenConfirmationDialog ? (
          <InternationalCurrencyWarningDialog
            onUpdate={onCompleteUpdatePostalCode}
            openConfirmationDialog={() => setIsOpenConfirmationDialog(false)}
            disableButtons={allLoading}
          />
        ) : (
          <InternationalPostalCodeDialog
            value={postalCode}
            selectedCountry={selectedCountry}
            setSelectedCountry={handleSetSelectedCountry}
            error={postalCodeError}
            disableConfirmButton={!postalCode.length || allLoading}
            openConfirmationDialog={handleConfirm}
            setPostalCode={input => setPostalCode(input)}
          />
        )}
      </DialogContent>
    </Dialog>
  );
};
