import React, { FC, useCallback, useEffect, useMemo } from "react";
import {
  Button,
  DialogContent,
  useMediaQuery,
  Theme,
  makeStyles,
  createStyles,
  Grid,
  Typography,
  FormControl,
  useTheme
} from "utils/material-ui-core";
import { DrawerProps } from "@material-ui/core/Drawer";
import Confirmed from "./Confirmed";
import AddToGiftRegistryErrorMessage from "./AddToGiftRegistryErrorMessage";
import { useQuery, useMutation } from "@apollo/client";
import RHSpinner from "component-rh-spinner";
import RHDialogTitle from "component-rh-dialog-title";
import useState from "hooks/useState";
import RHTextField from "component-rh-text-field";
import { CustomProductMapper } from "component-custom-product";
import isEmpty from "lodash.isempty";
import { MonogramOrderProps } from "page-monogram-dialog";
import { queryGetGiftRegistryList } from "graphql-client/queries/get-gift-registry-list";
import moment from "moment";
import { useEnv } from "hooks/useEnv";
import { mutationAddToRegsitry } from "graphql-client/queries/get-gift-registry";
import useSite from "hooks/useSite";
import { cleanMonogramOrder } from "utils/monogramUtils";
import Drawer from "component-drawer";
import useTypographyStyles from "hooks/useTypographyStyles";
import classNames from "classnames";
import useButtonStyles from "hooks/useButtonStyles";
import { DATE_FORMAT } from "utils/constants";
import { usePageContent } from "customProviders/LocationProvider";
import yn from "yn";
import useAppData from "hooks/useAppData";
import {
  POSTAL_CODE_COOKIE,
  NORTH_AMERICA_CURRENCY_CODES
} from "utils/constants";
import { useCurrencyCode } from "@RHCommerceDev/hooks/useCurrencyCode";
import { useCountry } from "@RHCommerceDev/hooks/useCountry";
import { useIsoCookies } from "@RHCommerceDev/hooks/useIsoCookies";
import { useRhUserAtomValue } from "hooks/atoms";
import { useGetCartProjectLazyQuery } from "hooks/queries";
import TailwindDrawer from "@RHCommerceDev/component-tailwind-drawer";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      [theme.breakpoints.only("xl")]: {
        width: "30%"
      },
      [theme.breakpoints.down("lg")]: {
        width: "35%"
      },
      [theme.breakpoints.only("sm")]: {
        width: "50%"
      },
      [theme.breakpoints.only("xs")]: {
        width: "80%"
      }
    },
    rhrPaper: {
      [theme.breakpoints.up("md")]: {
        width: "33.3%"
      },
      [theme.breakpoints.only("sm")]: {
        width: "50%"
      },
      [theme.breakpoints.only("xs")]: {
        width: "80%"
      }
    },
    content: {
      overflow: "auto"
    },
    rhrConfirmContent: {
      overflow: "auto",
      [theme.breakpoints.only("xl")]: {
        padding: "88.4px 80px"
      },
      [theme.breakpoints.only("lg")]: {
        padding: "88px 40px"
      },
      [theme.breakpoints.only("md")]: {
        padding: "88px 40px"
      },
      [theme.breakpoints.down("sm")]: {
        padding: "88px 32px"
      }
    },
    rhrContent: {
      overflow: "auto",
      [theme.breakpoints.only("xl")]: {
        padding: "48.4px 80px"
      },
      [theme.breakpoints.only("lg")]: {
        padding: "8px 40px"
      },
      [theme.breakpoints.only("md")]: {
        padding: "8px 40px"
      },
      [theme.breakpoints.down("sm")]: {
        padding: "8px 32px"
      }
    }
  })
);

export interface AddToGiftRegistryDialogProps extends DrawerProps {
  giftRegistryLists;
  onAddToRegistryClick: (id: string) => void;
}
export interface RootAddToGiftRegistryDialogProps extends DrawerProps {
  monogramOrder?: MonogramOrderProps;
  productId: string;
  fullSkuId: string;
  atgSkuId: string;
  qty: number;
  name?: Maybe<string>;
  imageUrl?: Maybe<string>;
  options?: any[];
  onCompleted?: Function;
  confirmed?: boolean;
  loading?: boolean;
  error?: boolean;
  customInfo?: CustomProductMapper | SkuCustomizationType;
  multiSkuComponents?: MultiSkuComponentDetails[];
  isNewPdpLayout?: boolean;
}

export const AddToGiftRegistryDialog: FC<
  RootAddToGiftRegistryDialogProps & AddToGiftRegistryDialogProps
> = ({
  qty,
  confirmed,
  giftRegistryLists,
  onAddToRegistryClick,
  loading,
  error,
  onClose
}) => {
  const { pageContent } = usePageContent();
  const typographyStyles = useTypographyStyles({
    keys: ["rhBaseH4", "rhBaseH2"]
  });
  const buttonStyles = useButtonStyles({
    keys: ["primaryBlackBtn", "secondaryBtn"]
  });
  const [giftRegistryId, setGiftRegistryId] = React.useState("");
  const env = useEnv();
  const theme = useTheme();

  if (loading) {
    return <RHSpinner />;
  }

  if (error) {
    return <AddToGiftRegistryErrorMessage />;
  }

  if (confirmed) {
    return <Confirmed qty={qty} onClose={onClose} />;
  }

  const handleOnAddToRegistryClick = () => {
    onAddToRegistryClick(giftRegistryId);
  };

  return (
    <>
      {env?.FEATURE_PDP ? (
        <>
          <Grid
            item
            xs={12}
            style={{
              paddingBottom: theme.spacing(4.5),
              display: "flex",
              justifyContent: "center"
            }}
          >
            <Typography
              style={{ textTransform: "uppercase" }}
              className={typographyStyles.rhBaseH2}
              data-testid="add-to-registry-title"
            >
              {pageContent?.ADD_TO_REGISTRY}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <FormControl margin="normal" fullWidth>
              <RHTextField
                id="giftRegistryListSelector"
                name="giftRegistryListSelector"
                value={giftRegistryId}
                onChange={event => setGiftRegistryId(event.target.value)}
                select
                dropdowntype={true}
              >
                {!giftRegistryId && (
                  <option data-testid="registry-list-option-item" value="0">
                    Select one
                  </option>
                )}
                {giftRegistryLists.map(({ eventDateTime, event, id }) => (
                  <option
                    data-testid="registry-list-option-item"
                    key={`registry-list-item-${id}`}
                    value={id}
                  >
                    {event} &nbsp;
                    {moment(eventDateTime).format(DATE_FORMAT)}
                  </option>
                ))}
              </RHTextField>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl margin="normal" fullWidth>
              <Button
                onClick={handleOnAddToRegistryClick}
                disabled={!giftRegistryId}
                className={buttonStyles.primaryBlackBtn}
                data-testid="add-to-registry-btn"
              >
                {pageContent?.ADD_TO_REGISTRY}
              </Button>
            </FormControl>
          </Grid>
        </>
      ) : (
        <Grid container>
          <Grid>
            <Typography className={classNames([typographyStyles.rhBaseH4])}>
              {pageContent?.ADD_TO_REGISTRY}
            </Typography>
            <Typography>{pageContent?.DEFAULT_REGISTRY_OPTION}</Typography>
          </Grid>
          <Grid item xs={12}>
            <FormControl margin="normal" fullWidth>
              <RHTextField
                id="giftRegistryListSelector"
                name="giftRegistryListSelector"
                value={giftRegistryId}
                onChange={event => setGiftRegistryId(event.target.value)}
                select
                dropdowntype={true}
              >
                {!giftRegistryId && <option value="0">Select one</option>}
                {giftRegistryLists.map(({ eventDateTime, event, id }) => (
                  <option key={`registry-list-item-${id}`} value={id}>
                    {event} &nbsp;
                    {moment(eventDateTime).format(DATE_FORMAT)}
                  </option>
                ))}
              </RHTextField>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl margin="normal" fullWidth>
              <Button
                onClick={handleOnAddToRegistryClick}
                disabled={!giftRegistryId}
                className={buttonStyles.primaryBlackBtn}
              >
                {pageContent?.ADD_TO_REGISTRY}
              </Button>
            </FormControl>
          </Grid>
        </Grid>
      )}
    </>
  );
};

AddToGiftRegistryDialog.defaultProps = {};

export default ({
  productId,
  fullSkuId,
  atgSkuId,
  qty,
  onCompleted,
  customInfo,
  monogramOrder,
  multiSkuComponents: multiSkuComponentsProp,
  isNewPdpLayout,
  ...rest
}: RootAddToGiftRegistryDialogProps) => {
  const mdUp = useMediaQuery<Theme>(theme => theme.breakpoints.up("md"));
  const classes = useStyles();

  const [confirmed, setConfirmed] = useState(false);
  const [giftRegistryList, setGiftRegistryList] = useState([]) as any;

  const env = useEnv();
  const isMultiSkuPhase4 = yn(env.FEATURE_MULTISKU_PHASE_4);
  const isMultiSkuPhase4NA = yn(env.FEATURE_MULTISKU_PHASE_4_NA);
  const FEATURE_TAILWIND_COMPONENTS = yn(env.FEATURE_TAILWIND_COMPONENTS);
  const DrawerComponent = FEATURE_TAILWIND_COMPONENTS ? TailwindDrawer : Drawer;
  const { userType } = useRhUserAtomValue();
  const { app } = useAppData();
  const country = useCountry();
  const isoCookies = useIsoCookies([POSTAL_CODE_COOKIE]);

  const currencyCode = useCurrencyCode({
    postalCode: app?.postalCode || isoCookies?.pc,
    userType,
    country
  });
  const isNorthAmerica = NORTH_AMERICA_CURRENCY_CODES.includes(currencyCode);

  const theme = useTheme();
  const [getCartProjection] = useGetCartProjectLazyQuery();

  const { email, id: userId } = useRhUserAtomValue();

  const brand = useSite();
  const [addItemsToGiftRegistry, { loading: loadingAddItemToRegistry, error }] =
    useMutation<Mutation>(mutationAddToRegsitry, {
      onCompleted: () => {
        setConfirmed(true);
        onCompleted?.();
        getCartProjection();
      },
      context: {
        fetchOptions: {
          method: "POST"
        }
      },
      onError: () => {}
    });
  const { loading: loadingRegistries, data = {} as Query } = useQuery<Query>(
    queryGetGiftRegistryList,
    {
      fetchPolicy: "no-cache",

      variables: {
        email,
        guestId: userId
      },
      onError: () => {},
      onCompleted: () => {
        setGiftRegistryList(data?.getGiftRegistryList);
      }
    }
  );

  const multiSkuComponents = yn(env.FEATURE_MULTISKU_PHASE_4)
    ? ([] as any)
    : multiSkuComponentsProp;

  const multiskuBundleComponents = useMemo(
    () =>
      multiSkuComponents?.length
        ? multiSkuComponents.map(component => ({
            productId: component?.productId,
            sku: component?.fullSkuId,
            quantity: component?.quantity * qty,
            brand: brand,
            multiSkuId: fullSkuId, // fullSkuId will contain a multiSkuId m1010101
            ...(!isEmpty(customInfo) ? { customInfo } : null),
            ...(monogramOrder?.fontCode ||
            monogramOrder?.fontColorCode ||
            monogramOrder?.borderCode
              ? { monogram: cleanMonogramOrder(monogramOrder) }
              : null)
          }))
        : [],
    [multiSkuComponents, qty, fullSkuId]
  );

  const onAddToRegistryClick = useCallback(
    (id: string) => {
      addItemsToGiftRegistry({
        variables: {
          items:
            multiSkuComponents?.length &&
            !isMultiSkuPhase4 &&
            !(isMultiSkuPhase4NA ? isNorthAmerica : true)
              ? multiskuBundleComponents
              : [
                  {
                    productId: productId,
                    sku: fullSkuId,
                    quantity: qty,
                    brand: brand,
                    ...(!isEmpty(customInfo) ? { customInfo } : null),
                    ...(monogramOrder?.fontCode ||
                    monogramOrder?.fontColorCode ||
                    monogramOrder?.borderCode
                      ? { monogram: cleanMonogramOrder(monogramOrder) }
                      : null)
                  }
                ],
          registryId: id,
          email
        }
      });
    },

    [qty, monogramOrder, multiskuBundleComponents]
  );

  useEffect(() => {
    setConfirmed(false);
  }, [rest.open]);

  return (
    <DrawerComponent
      anchor={"right"}
      {...rest}
      BackdropProps={{
        style: isNewPdpLayout
          ? {
              backgroundColor: "var(--ReimagineWarm-RH-Black, #000)",
              opacity: 0.15
            }
          : {},
        invisible: false
      }}
      PaperProps={{
        className: env.FEATURE_PDP ? classes.rhrPaper : classes.paper,
        ...rest.PaperProps
      }}
      isNewPdpLayout={isNewPdpLayout}
    >
      <RHDialogTitle
        style={{
          backgroundColor: env.FEATURE_BG_GREY
            ? theme.palette.background.default
            : "initial"
        }}
        solidWhite
        onClose={rest.onClose}
      />
      <DialogContent
        className={
          env.FEATURE_PDP
            ? confirmed
              ? classes.rhrConfirmContent
              : classes.rhrContent
            : classes.content
        }
      >
        {giftRegistryList.length > 0 && (
          <AddToGiftRegistryDialog
            productId={productId}
            onAddToRegistryClick={onAddToRegistryClick}
            giftRegistryLists={giftRegistryList}
            fullSkuId={fullSkuId}
            atgSkuId={atgSkuId}
            qty={qty}
            onCompleted={onCompleted}
            loading={loadingRegistries || loadingAddItemToRegistry}
            error={!!error}
            onClose={rest.onClose}
            confirmed={confirmed}
          />
        )}
      </DialogContent>
    </DrawerComponent>
  );
};
