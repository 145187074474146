import React, { FC, useCallback, useState } from "react";
import {
  makeStyles,
  createStyles,
  useMediaQuery,
  Theme,
  Popper,
  Typography,
  ClickAwayListener
} from "@material-ui/core";
import { SORT_SM } from "resources/rhr-product-gallery-resource.json";
import GridColumnIcon from "icon-grid-column";
import { KeyboardArrowDown, KeyboardArrowUp } from "@material-ui/icons";
import { useHistory, useLocation } from "react-router-dom";
import { BREAKPOINT_SM, BREAKPOINT_MD } from "utils/constants";
import useTypographyStyles from "hooks/useTypographyStyles";
import classNames from "classnames";
import RHCheckmarkIcon from "icon-checkmark";
import { usePageContent } from "customProviders/LocationProvider";
import { useCookiesWithPermission } from "hooks/useCookiesWithPermission";

const DEFAULT_GRID_COLUMNS = 6;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    sort: {
      cursor: "pointer",
      display: "flex !important",
      alignItems: "center",
      userSelect: "none",
      textTransform: "uppercase",
      marginRight: 28,
      [theme.breakpoints.up(BREAKPOINT_SM)]: {
        marginRight: 4
      },
      [theme.breakpoints.up(BREAKPOINT_MD)]: {
        marginRight: 0
      }
    },
    arrow: {
      // fontSize: 14,
      color: "#808080",
      "&.active": {
        transform: "rotate(180deg)"
      }
    },
    sortMenu: {
      padding: "17px 22px 17px 22px",
      backgroundColor: "#f9f7f4",
      boxShadow: "0px 1px 4px rgba(0, 0, 0, 0.25)",
      width: 270,
      marginTop: "-15px",
      marginLeft: "-143px"
    },
    sortOption: {
      textTransform: "capitalize",
      marginRight: 12
    },
    sortOpenTitle: {
      display: "flex",
      justifyContent: "space-between",
      marginRight: 0,
      marginBottom: 17,
      alignItems: "center",
      textTransform: "uppercase"
    },
    gridIcon: {
      height: 14,
      width: 14,
      color: "#C4C4C4",
      cursor: "pointer",
      marginLeft: 30,
      transition: "0.5s ease all",
      "&:hover": {
        color: "black"
      },
      "&[data-active='true']": {
        color: "black"
      }
    },
    iconsContainer: {
      height: "100%"
    },
    sortOptionContainer: {
      display: "flex",
      alignItems: "center",
      cursor: "pointer",
      marginBottom: 10,
      marginTop: 10
    }
  })
);

export interface SortMenu {
  value: string;
  label: string;
  selected: boolean;
}
export interface GridControllerProps {
  gridColumns: number;
  sortMenu?: SortMenu[];
  setGridColumns?: (gridColumns: number) => void;
  isCG?: boolean;
  categoryId?: string;
  hide3Column?: boolean;
  hide1Column?: boolean;
  ssr?: boolean;
  facetlength?: number;
  handleSorting: (index: number) => void;
}

const GridController: FC<GridControllerProps> = ({
  gridColumns,
  sortMenu,
  setGridColumns,
  isCG = false,
  categoryId,
  hide3Column = false,
  hide1Column = false,
  ssr = false,
  facetlength,
  handleSorting
}) => {
  const classes = useStyles();
  const smUp = useMediaQuery<Theme>(theme => theme.breakpoints.up("sm"));
  const mdUp = useMediaQuery<Theme>(theme => theme.breakpoints.up("md"));
  const between = useMediaQuery<Theme>(theme =>
    theme.breakpoints.between(992, 1201)
  );
  const [anchorEl, setAnchorEl] = useState(null);
  const history = useHistory();
  const { pathname } = useLocation();
  const [currentSort, setCurrentSort] = useState(null);
  const { pageContent } = usePageContent();

  const LAST_SORTMENU = "lastSortMenu";
  const { setCookieWrapper } = useCookiesWithPermission();

  const handleSortClick = useCallback(event => {
    setAnchorEl(event.currentTarget);
  }, []);

  const applySort = index => {
    handleSorting(index);
  };

  const typographyStyles = useTypographyStyles({
    keys: ["rhBaseCaption", "rhBaseBody2"]
  });

  /*
    this is a hack to make this re render several times
  */
  const key = ssr ? Date.now() : 1;

  const handleGridColumnIconV3 = useCallback(() => {
    setGridColumns?.(4);
  }, []);

  const handleGridColumnIconV2 = useCallback(() => {
    setGridColumns?.(6);
  }, []);

  const handleGridColumnIconV1 = useCallback(() => {
    setGridColumns?.(12);
  }, []);

  return (
    <div
      className={isCG ? classes.iconsContainer : ""}
      style={{ flex: "none", display: "flex", alignItems: "center" }}
    >
      {((mdUp && !between) || (mdUp && between && facetlength <= 6)) &&
        sortMenu &&
        sortMenu.length > 0 && (
          <>
            <div style={{ display: "flex" }} onClick={handleSortClick}>
              <Typography
                className={classNames([
                  typographyStyles.rhBaseCaption,
                  classes.sort
                ])}
                style={{ textTransform: "uppercase" }}
              >
                {SORT_SM}:
              </Typography>
              <span
                className={typographyStyles.rhBaseCaption}
                style={{ marginLeft: 7, textTransform: "uppercase" }}
              >
                {currentSort ?? sortMenu?.find(s => s.selected)?.label}
              </span>
              <KeyboardArrowDown
                className={`${classes.arrow} ${Boolean(anchorEl) && "active"}`}
                style={{ fontSize: 13, marginLeft: 3 }}
              />
            </div>

            <Popper
              open={Boolean(anchorEl)}
              anchorEl={anchorEl}
              placement="bottom-end"
              modifiers={{
                flip: {
                  enabled: false
                },
                preventOverflow: {
                  enabled: false
                }
              }}
              style={{ zIndex: 10 }}
              disablePortal
            >
              <ClickAwayListener
                onClickAway={() => {
                  setAnchorEl(null);
                }}
              >
                <div className={classes.sortMenu}>
                  <Typography
                    className={classNames([
                      typographyStyles.rhBaseBody2,
                      classes.sortOption,
                      classes.sortOpenTitle
                    ])}
                    style={{
                      textTransform: "uppercase"
                    }}
                    onClick={() => setAnchorEl(null)}
                  >
                    {SORT_SM}:
                    <KeyboardArrowUp
                      className={classes.arrow}
                      style={{ fontSize: 14 }}
                    />
                  </Typography>
                  {sortMenu.map((menu, index) => (
                    <div
                      className={classes.sortOptionContainer}
                      key={`sort-${index}`}
                    >
                      <Typography
                        key={`sortOption-${index}`}
                        className={classNames([
                          typographyStyles.rhBaseBody2,
                          classes.sortOption
                        ])}
                        style={{
                          textTransform: "capitalize",
                          marginRight: 12
                        }}
                        onClick={() => handleSorting(index)}
                      >
                        {menu.label}
                      </Typography>
                      <div>
                        {menu.selected && (
                          <RHCheckmarkIcon style={{ fontSize: 15 }} />
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              </ClickAwayListener>
            </Popper>
          </>
        )}

      {!hide3Column && smUp && (
        <GridColumnIcon
          key={key + gridColumns + 3}
          className={classes.gridIcon}
          column={3}
          data-active={gridColumns === 4}
          onClick={handleGridColumnIconV3}
        />
      )}
      <GridColumnIcon
        key={key + gridColumns + 2}
        className={classes.gridIcon}
        column={2}
        data-active={gridColumns === 6}
        onClick={handleGridColumnIconV2}
      />

      {!hide1Column && (
        <GridColumnIcon
          key={key + gridColumns + 1}
          className={classes.gridIcon}
          column={1}
          data-active={gridColumns === 12}
          onClick={handleGridColumnIconV1}
        />
      )}
    </div>
  );
};

GridController.defaultProps = {
  gridColumns: DEFAULT_GRID_COLUMNS,
  sortMenu: []
};

export default GridController;
