import React, { FC, useCallback, useMemo } from "react";
import { Typography } from "utils/material-ui-core";
import { Button, Grid } from "@material-ui/core";
import { usePageContent } from "customProviders/LocationProvider";
import { RHRUniversalPriceRangeDisplay } from "@RHCommerceDev/component-rh-price-range-display";
import { convertSkuResponsePriceInfoToRange } from "component-price-range-display/utils/convertSkuResponsePriceInfoToRange";
import classNames from "classnames";
import Delivery from "@RHCommerceDev/component-sku/Delivery";
import he from "he";
import getCountryFromUrl from "@RHCommerceDev/utils/getCountryFromUrl";
import { getReturnPolicyLink } from "@RHCommerceDev/utils/getReturnPolicyLink";
import cartConfirmationUseStyle from "./cartConfirmationUseStyle";
import memoize from "@RHCommerceDev/utils/memoize";
import { useLocalization } from "@RHCommerceDev/hooks/useLocalization";
import { useHistory } from "react-router";
import Restrictions from "@RHCommerceDev/component-sku/Restrictions";
import { useAppId } from "hooks/useAppId";
import { isDeepEmpty } from "@RHCommerceDev/utils/isDeepEmpty";
import { addOnDataType } from "@RHCommerceDev/component-addon-panel-v2";
import { AddOnDataType } from "@RHCommerceDev/component-addon-checkbox-v2";
import { useRhUserAtomValue } from "hooks/atoms";

export interface CartConfirmationProps {
  onClose?: (event: {}, reason: "backdropClick" | "escapeKeyDown") => void;
  addToCartItemDetails: addToCartItemDetailsProps;
  productAddons?: Array<addOnDataType & ProductAddonsInfo>;
  panelProds?: Array<ProductAddonsInfo>;
  qty?: number;
  panelAddOnsData?: Array<ProductAddonsInfo & AddOnDataType>;
  showPanelAddon?: boolean;
  productAddedFromPanel?: boolean;
  shouldDisplayAddOns?: boolean;
  isAddon?: boolean;
  onPostalCode?: () => void;
}

export const CartConfirmation: FC<CartConfirmationProps> = ({
  addToCartItemDetails,
  productAddons,
  panelProds,
  panelAddOnsData,
  qty,
  shouldDisplayAddOns,
  showPanelAddon,
  productAddedFromPanel,
  onClose,
  isAddon = false,
  onPostalCode
}) => {
  const { isEstore } = useAppId();
  const classes = cartConfirmationUseStyle();
  const { pageContent } = usePageContent();
  const country = getCountryFromUrl();
  const history = useHistory();
  const prefix = useLocalization();
  const { userType } = useRhUserAtomValue();

  const sortedOptions = useMemo(
    () => [
      ...(addToCartItemDetails?.selectedOptions ?? []),
      {
        value: addToCartItemDetails?.productInfo?.qty?.toString(),
        type: pageContent?.QTY
      }
    ],
    [addToCartItemDetails, pageContent]
  );

  const addOnIncludedCartItems = useMemo(
    () =>
      panelAddOnsData?.length && !!productAddedFromPanel
        ? panelAddOnsData?.map(addOn => {
            const optionsSelected =
              addOn?.productConfiguration?.optionDetails
                ?.flatMap(availableOpt => availableOpt.options)
                .filter(
                  option =>
                    addOn?.selectedOptions?.find(
                      opt => opt.id === option.id && option
                    ) && option
                )
                .map(opt => ({
                  value: opt.value,
                  type: opt.name
                })) ?? [];
            return {
              productInfo: {
                displayName: addOn?.sku?.info?.name,
                imageUrl: addOn?.sku?.info?.imageUrl,
                skuPriceInfo: addOn?.sku?.info?.skuPriceInfo,
                qty: addOn?.qty,
                dateString: addOn?.sku?.fulfillmentEta?.inventoryMessage
                  ? `${addOn?.sku?.fulfillmentEta?.inventoryMessage} ${pageContent?.shippingMethod?.message1?.bode2} ${addOn?.sku?.fulfillmentEta?.postalCode}`
                  : "",
                returnPolicyMessage:
                  addOn?.sku?.restrictions?.returnPolicyMessage,
                fullSkuId: addOn?.fullSkuId,
                delivery: addOn?.sku?.delivery,
                skuRestriction: addOn?.sku?.restrictions,
                availabilityStatus: ""
              },
              userType,
              selectedOptionsAndQty: [
                ...optionsSelected,
                {
                  value: addOn?.qty?.toString() || "",
                  type: pageContent?.QTY
                }
              ]
            };
          })
        : [
            {
              productInfo: { ...addToCartItemDetails?.productInfo },
              userType,
              selectedOptionsAndQty: [
                ...(addToCartItemDetails?.selectedOptions ?? []),
                {
                  value:
                    addToCartItemDetails?.productInfo?.qty?.toString() || "",
                  type: pageContent?.QTY
                }
              ]
            },
            ...(productAddons ?? [])?.map(addOn => {
              const optionsSelected =
                addOn?.productConfiguration?.optionDetails
                  ?.flatMap(availableOpt => availableOpt.options)
                  .filter(
                    option =>
                      addOn?.selectedOptions?.find(
                        opt => opt.id === option.id && option
                      ) && option
                  )
                  .map(opt => ({
                    value: opt.value,
                    type: opt.name
                  })) ?? [];
              return {
                productInfo: {
                  displayName: addOn?.sku?.info?.name,
                  imageUrl: addOn?.sku?.info?.imageUrl,
                  skuPriceInfo: addOn?.sku?.info?.skuPriceInfo,
                  qty: addOn?.qty,
                  dateString: addOn?.sku?.fulfillmentEta?.inventoryMessage
                    ? `${addOn?.sku?.fulfillmentEta?.inventoryMessage} ${pageContent?.shippingMethod?.message1?.bode2} ${addOn?.sku?.fulfillmentEta?.postalCode}`
                    : "",
                  returnPolicyMessage:
                    addOn?.sku?.restrictions?.returnPolicyMessage,
                  fullSkuId: addOn?.fullSkuId,
                  delivery: addOn?.sku?.delivery,
                  skuRestriction: addOn?.sku?.restrictions,
                  availabilityStatus: ""
                },
                userType,
                selectedOptionsAndQty: [
                  ...optionsSelected,
                  {
                    value: addOn?.qty?.toString() || "",
                    type: pageContent?.QTY
                  }
                ]
              };
            })
          ],
    [productAddons, panelAddOnsData, pageContent, sortedOptions]
  );

  const renderAddOnConfirmation = useCallback(prods => {
    let headerConfirmation: Array<JSX.Element> = [];
    headerConfirmation.push(
      <>
        <div>
          <div style={{ width: "175px", height: "10px" }}>
            <Typography className={classes.headerTextTypo}>
              {qty === 1
                ? `${qty} ${pageContent?.ITEM} ${pageContent?.ADDED_TO_YOUR_CART}`
                : `${qty} ${pageContent?.ITEMS} ${pageContent?.ADDED_TO_YOUR_CART}`}
            </Typography>
          </div>
        </div>
        <Button
          id="view-cart"
          fullWidth
          className={classNames([classes.buttonRangeRoot])}
          onClick={() => history.push(`${prefix}/checkout/shopping_cart.jsp`)}
        >
          <Typography className={classes.viewDetailsRoot}>
            {pageContent?.VIEW_CART}
          </Typography>
        </Button>
      </>
    );
    let renderedSkus: Array<JSX.Element> = [];
    prods?.forEach((prod, prodIndex) => {
      if (prodIndex === 1 && !productAddedFromPanel) {
        renderedSkus.push(
          <div>
            <Typography className={classes.headerTextTypo}>
              {pageContent?.YOU_ALSO_ADDED}
            </Typography>
          </div>
        );
      }
      renderedSkus.push(
        <div className={classes.productDetailsRoot}>
          <Typography
            className={classNames({
              [classes.productTitleTypo]: true,
              [classes?.smallProducttitle]:
                prodIndex >= 1 && !productAddedFromPanel
            })}
          >
            {he.decode(prod?.productInfo?.displayName ?? "")}
          </Typography>
          <div
            className={
              prodIndex >= 1 && !productAddedFromPanel
                ? classes.addOnPriceRangeRoot
                : classes.priceRangeRoot
            }
          >
            {!isDeepEmpty(prod?.productInfo?.productPriceUi) ? (
              prod?.productInfo?.productPriceUi
            ) : (
              <RHRUniversalPriceRangeDisplay
                centerAlignFlag={true}
                display="inline"
                variant="medium"
                range={convertSkuResponsePriceInfoToRange?.(
                  null,
                  prod?.productInfo?.skuPriceInfo ?? {},
                  prod?.userType
                )}
                currencyLabel={
                  prod?.productInfo?.skuPriceInfo?.currencyLabel || ""
                }
                currencySymbol={
                  prod?.productInfo?.skuPriceInfo?.currencySymbol || "$"
                }
                isSkuLevel={true}
                isConfirmationDialog={true}
              />
            )}
          </div>
        </div>
      );
      let renderedOptions: Array<JSX.Element> = [];
      prod?.selectedOptionsAndQty?.map(option => {
        renderedOptions?.push(
          option && (
            <Grid
              item
              container
              key={`productOption-${prodIndex}-${option.type}-${option.value}`}
            >
              <div style={{ width: "50%", justifyContent: "space-between" }}>
                <Typography className={classes.optionTypeTypo}>
                  {he?.decode(option?.type)}
                </Typography>
              </div>
              <div style={{ width: "50%", justifyContent: "space-between" }}>
                <Typography className={classes.optionLabelTypo}>
                  {he?.decode(option?.value?.toString())}
                </Typography>
              </div>
            </Grid>
          )
        );
      });
      renderedSkus.push(
        <>
          <div
            className={classes.optionsRoot}
            style={{
              width: "211px"
            }}
          >
            {renderedOptions}
          </div>
          <div className={classes.deliveryDetailsRoot}>
            {prod?.productInfo?.dateString?.length ? (
              <div className={classes.deliveryStyles}>
                <Typography className={classes.deliveryDetailRoot}>
                  {prod?.productInfo?.dateString}
                </Typography>
              </div>
            ) : null}
            <Typography className={classes.deliveryTypoStyle}>
              {`${pageContent?.ITEM}# ${prod?.productInfo?.fullSkuId}`}
            </Typography>
          </div>
        </>
      );
      if (panelAddOnsData?.length && !!productAddedFromPanel) {
        if (prodIndex < prods.length - 1) {
          renderedSkus.push(
            <hr
              key={`divider-${prodIndex}`}
              style={{
                height: "1px",
                width: "100%",
                backgroundColor: "var(--reimagine-warm-rh-medium)",
                margin: "0px"
              }}
            />
          );
        }
      } else {
        if (
          prodIndex < prods.length - 1 ||
          (shouldDisplayAddOns &&
            prodIndex === prods.length - 1 &&
            panelProds?.length)
        ) {
          renderedSkus.push(
            <hr
              key={`divider-${prodIndex}`}
              style={{
                height: "1px",
                width: "100%",
                backgroundColor: "var(--reimagine-warm-rh-medium)",
                margin: "0px"
              }}
            />
          );
        }
      }
    });
    return [...headerConfirmation, ...renderedSkus];
  }, []);

  const returnPolicyRHLinkProps = isEstore
    ? {
        to: getReturnPolicyLink(country)
      }
    : {
        href: getReturnPolicyLink(country, { origin: "https://rh.com" }),
        target: "_blank"
      };
  return (
    <div className={classes.root} style={{ flexDirection: "column" }}>
      {isAddon &&
        addOnIncludedCartItems &&
        renderAddOnConfirmation(addOnIncludedCartItems)}
      {!isAddon && (
        <>
          <div>
            <div className={classes.innerRootDiv}>
              <Typography className={classes.headerTextTypo}>
                {pageContent?.ADDED_TO_YOUR_CART}
              </Typography>
            </div>
          </div>
          <div className={classes.productDetailsRoot}>
            <Typography className={classes.productTitleTypo}>
              {he.decode(addToCartItemDetails?.productInfo?.displayName ?? "")}
            </Typography>
            <div className={classes.priceRangeRoot}>
              {!isDeepEmpty(
                addToCartItemDetails?.productInfo?.productPriceUi
              ) ? (
                addToCartItemDetails?.productInfo?.productPriceUi
              ) : (
                <RHRUniversalPriceRangeDisplay
                  centerAlignFlag={true}
                  display="inline"
                  variant="medium"
                  range={convertSkuResponsePriceInfoToRange?.(
                    null,
                    addToCartItemDetails?.productInfo?.skuPriceInfo ?? {},
                    addToCartItemDetails?.userType
                  )}
                  currencyLabel={
                    addToCartItemDetails?.productInfo?.skuPriceInfo
                      ?.currencyLabel || ""
                  }
                  currencySymbol={
                    addToCartItemDetails?.productInfo?.skuPriceInfo
                      ?.currencySymbol || "$"
                  }
                  isSkuLevel={true}
                  isConfirmationDialog={true}
                />
              )}
            </div>

            {addToCartItemDetails?.productInfo?.dateString?.length ? (
              <div className={classes.deliveryStyles}>
                <Typography className={classes.deliveryDetailRoot}>
                  {addToCartItemDetails?.productInfo?.dateString}
                </Typography>
              </div>
            ) : null}
          </div>
          <div
            className={classes.optionsRoot}
            style={{
              width: "211px"
            }}
          >
            {sortedOptions?.map(
              option =>
                option && (
                  <Grid item container key={`productOption-${option.value}`}>
                    <div
                      style={{ width: "50%", justifyContent: "space-between" }}
                    >
                      <Typography className={classes.optionTypeTypo}>
                        {option?.type}
                      </Typography>
                    </div>
                    <div
                      style={{ width: "50%", justifyContent: "space-between" }}
                    >
                      <Typography className={classes.optionLabelTypo}>
                        {he?.decode(option?.value || "")}
                      </Typography>
                    </div>
                  </Grid>
                )
            )}
          </div>

          <Button
            id="view-cart"
            fullWidth
            className={classNames([classes.buttonRangeRoot])}
            onClick={() => history.push(`${prefix}/checkout/shopping_cart.jsp`)}
          >
            <Typography className={classes.viewDetailsRoot}>
              {pageContent?.VIEW_CART}
            </Typography>
          </Button>

          <div className={classes.deliveryDetailsRoot}>
            <div className={classes.unlimitedDetailsRoot}>
              <Delivery
                delivery={addToCartItemDetails?.productInfo?.delivery}
              />
            </div>
            <Typography display="inline" className={classes.deliveryTypoStyle}>
              <Restrictions
                hideCountryRestrictions={false}
                restrictions={addToCartItemDetails?.productInfo?.skuRestriction}
                hideReturnPolicyMessage={
                  addToCartItemDetails?.userType === "CONTRACT"
                }
                loading={false}
                availabilityStatus={
                  addToCartItemDetails?.productInfo?.availabilityStatus
                }
                lineBreak={true}
              />
            </Typography>
            <Typography className={classes.deliveryTypoStyle}>
              {`${pageContent?.ITEM}# ${addToCartItemDetails?.productInfo?.fullSkuId}`}
            </Typography>
          </div>
        </>
      )}
    </div>
  );
};
CartConfirmation.defaultProps = {};
export default memoize(CartConfirmation);
