import React, { FC, useEffect, useState } from "react";
import {
  Dialog,
  IconButton,
  useTheme,
  Typography,
  DialogContent,
  Grid,
  useMediaQuery,
  Theme,
  Hidden,
  makeStyles,
  createStyles,
  CardMedia
} from "utils/material-ui-core";
import RHCloseIcon from "icon-close";
import RHZoomInIcon from "icon-zoomin";
import RHZoomOutIcon from "icon-zoomout";
import { DialogProps } from "@material-ui/core/Dialog";
import RHImage, { ImagePreloader } from "component-rh-image";
import PlayArrowRounded from "@material-ui/icons/PlayArrowRounded";
import IframeDialog from "dialog-iframe";
import ImageThumbnailList from "component-image-thumbnail-list";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import useMediaString from "hooks/useMediaString";
import { ProductImagePresetKeys } from "utils/getImageUrlWithPreset";
import useUnsafeEffect from "hooks/useUnsafeEffect";
import { processEnvServer } from "hooks/useSsrHooks";
import { usePageContent } from "customProviders/LocationProvider";
import { useEnv } from "hooks/useEnv";
import useTypographyStyles from "hooks/useTypographyStyles";
import { useParams2 } from "hooks/useParams";
import { memoryStorage } from "utils/analytics/storage";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    // Added inline style due to SSR FIX: Need to fix this a global way. Styles that don't exist in MUI are not applied
    // close: {
    //   position: "absolute",
    //   top: 0,
    //   right: 0,
    //   zIndex: 2,
    //   backgroundColor: "rgba(255, 255, 255, 0.2)"
    // },
    zoom: {
      "& > div:first-child": {
        overflow: "visible"
      }
    }
  })
);

export interface ZoomViewerDialogContentProps {
  images: ProductAlternateImage[];
  index: number | undefined;
  selectedSwatchImageUrl?: string;
  objectFit?: string;
  imageCaptionText?: string;
  parentBaseId?: string;

  onClose:
    | ((event: {}, reason: "backdropClick" | "escapeKeyDown") => void)
    | undefined;
}

const toggleScroll = (scroll: boolean) =>
  (document.documentElement.style.overflow = scroll ? "visible" : "hidden") &&
  undefined;

export const ZoomViewerDialogContent: FC<ZoomViewerDialogContentProps> = ({
  images,
  index = 0,
  selectedSwatchImageUrl,
  objectFit,
  imageCaptionText,
  onClose,
  parentBaseId
}) => {
  const baseId = `${parentBaseId}-zoomViewerDialog`;
  const theme = useTheme();
  const mdUp = useMediaQuery<Theme>(theme => theme.breakpoints.up("md"));
  const smDown = useMediaQuery<Theme>(theme => theme.breakpoints.down("sm"));
  const mediaString = useMediaString();
  const classes = useStyles();
  const [activeIndex, setActiveIndex] = useState(index);
  const [video, setVideo] = useState("");
  const [activeImages, setActiveImages] = useState([...images]);
  const [activeThumbnailImages, setActiveThumbnailImages] = useState([
    ...images
  ]);
  const { pageContent } = usePageContent();

  const typographyStyles = useTypographyStyles({
    keys: ["rhBaseCaption", "rhBaseBody1"]
  });
  const env = useEnv();

  const { params } = useParams2<{ [key: string]: string }>(
    { version: "" },
    { toLowerCase: true }
  );

  const isNewPDPLayout: boolean =
    params.version === "v2" || memoryStorage.getItem("newPdpLayout");

  useUnsafeEffect(() => {
    if (selectedSwatchImageUrl) {
      // manually set zoom image size for selectedSwatchImageUrl
      const activeImagesArr = [...images];
      const delimeter = selectedSwatchImageUrl.includes("?") ? "&" : "?";
      activeImagesArr.splice(0, 1, {
        imageUrl: `${selectedSwatchImageUrl}${delimeter}wid=1149`
      });
      setActiveImages(activeImagesArr);
      const activeThumbnailImagesArr = [...images];
      // manually set thumbnail image size for selectedSwatchImageUrl
      activeThumbnailImagesArr.splice(0, 1, {
        imageUrl: `${selectedSwatchImageUrl}${delimeter}wid=178`
      });
      setActiveThumbnailImages(activeThumbnailImagesArr);
    } else {
      if (isNewPDPLayout) {
        setActiveThumbnailImages([...images]);
      }
      setActiveImages([...images]);
    }
    setActiveIndex(index);
  }, [selectedSwatchImageUrl]);

  const isVideo = () => !!activeImages[activeIndex]?.video;
  const handleRHImageClick = () => {
    if (isVideo()) {
      setVideo(activeImages[activeIndex].video?.split("&")[0] ?? "");
    }
  };
  return (
    <DialogContent className="!p-0 !overflow-hidden h-full">
      <IconButton
        onClick={event => onClose?.(event, "escapeKeyDown")}
        className="!absolute top-0 right-0 z-[2] !bg-[rgba(255,255,255,0.2)]"
        data-testid={"close-btn"}
      >
        <RHCloseIcon />
      </IconButton>

      <ImagePreloader
        images={images
          .filter(image => !image.video)
          .map(image => image.imageUrl)}
        preset="mobileThumb"
      />
      <ImagePreloader
        images={images
          .filter(image => !image.video)
          .map(image => image.imageUrl)}
        preset="mobileZoom"
      />
      <Grid
        container
        direction={mdUp ? "row" : "column"}
        wrap="nowrap"
        style={{ height: "100%" }}
        id="zoom-container"
        data-testid={"zoom-container"}
        key={"zoom-container"}
      >
        {isVideo() ? (
          <>
            <Grid
              item
              xs={12}
              className={`flex justify-center items-center relative h-full ${classes.zoom}`}
              id="zoom-container-media"
              data-testid={"zoom-container-media"}
              key={"zoom-container-media"}
            >
              {isNewPDPLayout &&
              activeImages[activeIndex]?.video &&
              activeImages[activeIndex]?.autoplay ? (
                <CardMedia
                  component="video"
                  image={
                    (activeImages[activeIndex]?.video
                      ? (activeImages[activeIndex].video || " ").includes(
                          ".com"
                        )
                        ? activeImages[activeIndex].video
                        : `https://www.youtube.com/embed/${
                            (activeImages[activeIndex].video || " ").split(
                              "&"
                            )[0]
                          }?modestbranding=1&rel=0&showinfo=0&autoplay=1&controls=0`
                      : undefined) as string // Type assertion to indicate that image is of type string
                  }
                  autoPlay
                  muted
                  loop
                  data-testid={"video-element"}
                />
              ) : (
                <RHImage
                  parentBaseId={baseId}
                  key={`${baseId}-image`}
                  src={
                    selectedSwatchImageUrl &&
                    isNewPDPLayout &&
                    activeIndex === 0
                      ? selectedSwatchImageUrl
                      : activeImages[activeIndex].imageUrl
                  }
                  preset={`full-width-${mediaString}` as ProductImagePresetKeys}
                  rootProps={{
                    style: {
                      height: "100%"
                    }
                  }}
                  onClick={handleRHImageClick}
                  style={{
                    aspectRatio: "1.8",
                    objectFit: "contain"
                  }}
                  data-testid={"video-image"}
                >
                  <PlayArrowRounded
                    className={`absolute m-auto inset-0 text-white !text-[60px] border-4 border-solid rounded-[30px] border-white pointer-events-none`}
                  />
                </RHImage>
              )}
            </Grid>
          </>
        ) : (
          <TransformWrapper
            panning={{ disabled: false }}
            pinch={{ step: 2 }}
            wheel={{ step: 0.05 }}
            maxScale={2}
          >
            {({ zoomIn, zoomOut }: any) => (
              <>
                <Grid
                  item
                  xs={12}
                  className={`flex justify-center items-center relative h-full ${classes.zoom}`}
                  id="zoom-container-media"
                  data-testid={"zoom-container-media"}
                >
                  <TransformComponent>
                    <RHImage
                      src={activeImages[activeIndex].imageUrl}
                      preset={"mobileZoom"}
                      style={{ height: "100%", width: "auto" }}
                      imgStyle={{
                        maxHeight: "100%",
                        maxWidth: "100%",
                        top: "50%",
                        translate: "translateY(-50%)",
                        aspectRatio: "1.8",
                        objectFit: "contain"
                      }}
                    ></RHImage>
                  </TransformComponent>
                  <Hidden smDown>
                    <div className="absolute bottom-2 right-2">
                      <Grid container direction="column">
                        <Grid item xs={12}>
                          <IconButton
                            className="!bg-transparent"
                            onClick={() => {
                              zoomIn();
                              if (!processEnvServer) {
                                document.body.dispatchEvent(
                                  new CustomEvent("pre-pdp-click", {
                                    detail: {
                                      item: {
                                        action: "Hero Image - Zoom In"
                                      }
                                    }
                                  })
                                );
                              }
                            }}
                            disableRipple
                          >
                            <RHZoomInIcon />
                          </IconButton>
                        </Grid>
                        <Grid item xs={12}>
                          <IconButton
                            className="!bg-transparent"
                            onClick={() => {
                              zoomOut();
                              if (!processEnvServer) {
                                document.body.dispatchEvent(
                                  new CustomEvent("pre-pdp-click", {
                                    detail: {
                                      item: {
                                        action: "Hero Image - Zoom out"
                                      }
                                    }
                                  })
                                );
                              }
                            }}
                            disableRipple
                          >
                            <RHZoomOutIcon />
                          </IconButton>
                        </Grid>
                      </Grid>
                    </div>
                  </Hidden>
                </Grid>
              </>
            )}
          </TransformWrapper>
        )}

        <Grid
          item
          xs={12}
          md={4}
          lg={4}
          className={`bg-white w-full z-[1] shadow-md md:overflow-hidden md:py-[40px] md:px-[80px]`}
          data-testid={"thumbnail"}
        >
          <div
            style={{
              height: "100%",
              padding: theme.spacing(mdUp ? 5 : 1)
            }}
          >
            {
              <ImageThumbnailList
                parentBaseId={baseId}
                key={`${baseId}-thumbnail`}
                direction={mdUp ? "column" : "row"}
                images={activeThumbnailImages}
                index={activeIndex}
                onChangeIndex={index => {
                  setActiveIndex(index);
                }}
              />
            }
          </div>
        </Grid>
        {
          <Hidden mdUp xsDown={isVideo()}>
            <Grid item xs={12}>
              <Typography
                className="absolute left-0 right-0 text-[#666666] m-2"
                variant="caption"
                align="center"
                paragraph
              >
                PINCH & ZOOM TO ENLARGE
              </Typography>
            </Grid>
          </Hidden>
        }
      </Grid>
      <IframeDialog src={video} open={!!video} onClose={() => setVideo("")} />
    </DialogContent>
  );
};

ZoomViewerDialogContent.defaultProps = {};

export default ({
  images,
  index,
  objectFit,
  selectedSwatchImageUrl,
  imageCaptionText,
  parentBaseId,
  ...rest
}: ZoomViewerDialogContentProps & Omit<DialogProps, "children">) => {
  useEffect(() => {
    toggleScroll(!rest?.open);

    return () => toggleScroll(true);
  }, [rest?.open]);
  const env = useEnv();

  return (
    <>
      <Dialog {...rest} fullScreen>
        <ZoomViewerDialogContent
          images={images}
          index={index}
          selectedSwatchImageUrl={selectedSwatchImageUrl}
          onClose={rest.onClose}
          parentBaseId={parentBaseId}
        />
      </Dialog>
    </>
  );
};
