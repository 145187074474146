import React, { FC } from "react";
import FormDialog from "layout-form-dialog";
import { Grid, Typography } from "@material-ui/core";
import RHDivider from "component-rh-divider";
import { queryReturnContentModalFragment } from "graphql-client/queries/content";
import { useQuery } from "@apollo/client";
import RHSpinner from "component-rh-spinner";
import { KEY_GROUP_MAPPER_FAQ } from "utils/customProductHelper";
import memoize from "utils/memoize";
import useTypographyStyles from "hooks/useTypographyStyles";
import { usePageContent } from "customProviders/LocationProvider";

export interface FAQModalProps {
  open: boolean;
  onClose: () => void;
  customProductType: string;
}

export const FAQDialog: FC<FAQModalProps> = ({
  open,
  onClose,
  customProductType
}) => {
  const typographyStyles = useTypographyStyles({
    keys: ["rhBaseH2"]
  });
  const { pageContent } = usePageContent();

  const { loading, data: { contentFragment } = {} as Query } = useQuery(
    queryReturnContentModalFragment,
    {
      variables: {
        group: KEY_GROUP_MAPPER_FAQ[customProductType].group,
        key: KEY_GROUP_MAPPER_FAQ[customProductType].key
      },
      notifyOnNetworkStatusChange: true
    }
  );
  const header =
    customProductType === "drape"
      ? pageContent?.DRAPERY
      : customProductType === "shade"
      ? pageContent?.SHADES
      : "";

  return (
    <FormDialog
      open={open}
      onClose={onClose}
      PaperProps={{
        className: `md:!w-[768px] md:!m-[32px] md:!static w-full max-w-full !m-0 !absolute !top-0`,
        square: true
      }}
    >
      <Grid container>
        {loading && <RHSpinner />}
        {contentFragment && (
          <>
            <Grid item>
              <Typography className={typographyStyles.rhBaseH2}>
                Custom {header} FAQ
              </Typography>
            </Grid>
            <Grid item container className="!mt-4 !mb-4">
              <RHDivider className="!border !border-gray-200 w-full" />
            </Grid>
            <Grid item>
              <Typography
                dangerouslySetInnerHTML={{ __html: contentFragment?.text }}
              />
            </Grid>
          </>
        )}
      </Grid>
    </FormDialog>
  );
};

FAQDialog.defaultProps = {};

export default memoize(FAQDialog);
