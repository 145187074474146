import React, { FC, useState } from "react";
import {
  Button,
  Typography,
  makeStyles,
  createStyles,
  IconButton,
  ClickAwayListener,
  useTheme,
  Grow,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Paper
} from "utils/material-ui-core";
import RHCloseIcon from "icons/RHCloseIcon";
import memoize from "utils/memoize";
import { Tooltip } from "@mui/material";

const useStyles = makeStyles(theme =>
  createStyles({
    popover: {
      minWidth: "343px",
      height: "564px",
      top: "93px !important",
      borderRadius: 12,
      background: "var(--ReimagineWarm-RH-Buff, #F9F7F4)",
      boxShadow: "0px 0px 20px 0px rgba(0, 0, 0, 0.10)"
    },
    tooltip: {
      bottom: "8px !important",
      minWidth: 600,
      // maxHeight: 456,
      padding: 0,
      borderRadius: "12px",
      background: "var(--ReimagineWarm-RH-Buff, #F9F7F4)",
      boxShadow: "0px 0px 20px 0px rgba(0, 0, 0, 0.10)",
      "& .MuiTooltip-tooltip": {
        minWidth: 600,
        background: "transparent",
        padding: "0px !important",
        margin: "0 !important",
        transition: "none !important",
        [theme.breakpoints.down("xs")]: {
          left: "0px !important",
          minWidth: 343,
          marginBottom: "32px !important",
          maxHeight: "none"
        }
      },
      [theme.breakpoints.down("xs")]: {
        minWidth: 343,
        maxHeight: "none"
      }
    },
    content: {
      display: "flex",
      flexDirection: "column",
      // gap: "16px",
      overflow: "hidden",
      height: "auto",
      maxHeight: "336px",
      marginBottom: "48px",
      width: "600px",
      padding: "32px 0px 0px 56px",
      [theme.breakpoints.down("xs")]: {
        maxHeight: "532px",
        marginBottom: "32px",
        width: 343,
        padding: "26px 32px 0px 16px"
      }
    },
    detailDimensionButton: {
      display: "flex",
      minWidth: "191.72px",
      width: "191.72px",
      minHeight: "36px",
      height: "36px",
      flexDirection: "column",
      justifyContent: "center",
      color: "var(--ReimagineWarm-RH-Black, #000)",
      textAlign: "center",
      fontFamily: "RHSans",
      fontSize: "13px",
      fontWeight: 300,
      lineHeight: "100%" /* 13px */,
      letterSpacing: "0.26px",
      border: "0.5px solid #000",
      background: "transparent",
      textTransform: "capitalize",
      padding: "0px 0.292px 0px 0.987px !important",
      "&:hover": {
        background: "transparent",
        borderColor: "inherit",
        color: "inherit"
      },
      [theme.breakpoints.down("xs")]: {
        minWidth: "166.38px",
        padding: "0px 0.254px 0px 0.857px !important"
      }
    },
    title: {
      color: "#000",
      fontFamily: "RHSans",
      fontSize: 22,
      fontWeight: 100,
      lineHeight: "90%" /* 19.8px */,
      letterSpacing: "0.44px",
      textTransform: "uppercase",
      margin: "48px 0px 0px 56px",
      textAlign: "left",
      width: "100%",
      [theme.breakpoints.down("xs")]: {
        margin: "32px 0px 0px 16px"
      }
    },
    ul: {
      padding: 0,
      margin: "16px 0px 0px 0px",
      paddingRight: "75px",
      overflow: "auto",
      listStyle: "initial",
      [`& li`]: {
        textAlign: "left",
        color: "var(--Reimagine-Black, #000)",
        fontFamily: "RHSans",
        fontSize: "11px",
        fontWeight: 300,
        lineHeight: "12.1px",
        letterSpacing: "0.44px",
        marginBottom: "16px",
        "& a": {
          textDecoration: "underline !important"
        }
      },
      "& li:last-child": {
        marginBottom: 0
      },
      [theme.breakpoints.down("xs")]: {
        margin: "16px 0px 0px 0px",
        paddingRight: "32px"
      }
    },
    iconButton: {
      width: 48,
      height: 48,
      padding: 16
    },
    closeIcon: {
      fontSize: 16,
      color: "#000"
    },
    tableContainer: {
      background: "transparent",
      boxShadow: "none",
      width: 544,
      paddingRight: "109px",
      [theme.breakpoints.down("xs")]: {
        width: 327,
        paddingRight: "0px"
      }
    },
    table: {
      minWidth: 435,
      borderCollapse: "collapse",
      [theme.breakpoints.down("xs")]: {
        width: 295,
        minWidth: 295
      }
    },
    cell: {
      borderBottom: "0.775px solid #C6C8CC;", // Border on bottom of each row
      padding: "9.4px 0px 7.2px 0px",
      color: "var(--ReimagineWarm-RH-Black, #000)",
      fontFamily: "RHSans",
      fontSize: "11px",
      fontWeight: 300,
      lineHeight: "14.3px",
      letterSpacing: "0.44px",
      [theme.breakpoints.down("xs")]: {
        padding: "6.7px 0px 6.7px 0px"
      }
    },
    firstColumn: {
      textAlign: "left",
      width: 275,
      [theme.breakpoints.down("xs")]: {
        width: 147
      }
    },
    secondColumn: {
      textAlign: "left",
      [theme.breakpoints.down("xs")]: {
        textAlign: "right"
      }
    },
    centeredTooltip: {
      left: "-110px !important",
      [theme.breakpoints.down("xs")]: {
        left: "-90px !important"
      }
    }
  })
);

interface ProductDimensionsDialogProps {
  buttonText?: string;
  title?: string;
  dimensionsContent?: string[];
  centered?: boolean;
}

const ProductDimensionsDialog: FC<ProductDimensionsDialogProps> = ({
  buttonText,
  title,
  dimensionsContent,
  centered
}) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const theme = useTheme();

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = (event: any) => {
    setOpen(false);
  };

  const handleClickAway = (event: React.MouseEvent<Document>) => {
    const clickedElement = event.target as HTMLElement;
    if (
      !clickedElement.id.includes("configurator_option_value") &&
      !clickedElement.closest("[id*=configurator_option_value]") &&
      !clickedElement.closest("[id*=dimensions-form-dialog-container]")
    ) {
      setOpen(false);
    }
  };
  const openPop = Boolean(anchorEl);
  const dimensionsWithLink =
    dimensionsContent &&
    dimensionsContent?.filter(item => /<a[^>]*>.*<\/a>/.test(item));
  const dimensionsWithoutLink =
    dimensionsContent &&
    dimensionsContent?.filter(item => !/<a[^>]*>.*<\/a>/.test(item));

  return (
    <>
      <ClickAwayListener onClickAway={handleClickAway}>
        <Tooltip
          PopperProps={{
            className: `${classes.tooltip} ${
              centered ? classes.centeredTooltip : ""
            }`,
            disablePortal: true,
            placement: "top",
            modifiers: [
              {
                name: "flip",
                enabled: false
              },
              {
                name: "preventOverflow",
                enabled: false
              },
              {
                name: "offset",
                options: {
                  offset: [0, 10]
                }
              },
              {
                name: "applyTransform",
                enabled: true,
                phase: "beforeWrite",
                fn: ({ state }) => {
                  if (
                    state.elements &&
                    state.elements.reference &&
                    state.styles
                  ) {
                    state.styles.transform = (
                      state.elements.reference as HTMLElement
                    ).style.transform;
                  }
                },
                effect: ({ state }) => {
                  if (state.elements && state.elements.popper) {
                    return () => {
                      state.elements.popper.style.transform = "";
                    };
                  }
                }
              }
            ]
          }}
          id="dimensions-form-dialog-container"
          disableFocusListener
          disableHoverListener
          disableTouchListener
          TransitionComponent={Grow}
          TransitionProps={{ timeout: 0 }}
          title={
            <>
              <div
                style={{
                  width: "100%",
                  justifyContent: "space-between",
                  display: "flex"
                }}
                data-testid="dimensions-modal-popper"
              >
                <Typography variant="h6" className={classes.title}>
                  {title}
                </Typography>
                <IconButton
                  className={classes.iconButton}
                  onClick={handleClose}
                  data-testid="form-dialog-close-button"
                >
                  <RHCloseIcon className={classes.closeIcon} />
                </IconButton>
              </div>
              <div className={classes.content}>
                <TableContainer
                  component={Paper}
                  className={classes.tableContainer}
                >
                  <Table className={classes.table}>
                    <TableBody>
                      {dimensionsWithoutLink?.map((row, index) => {
                        const dimSplited = row.split(":");
                        return (
                          <TableRow key={index}>
                            <TableCell
                              className={`${classes.cell} ${classes.firstColumn}`}
                              dangerouslySetInnerHTML={{
                                __html: dimSplited[0]
                              }}
                            />
                            <TableCell
                              className={`${classes.cell} ${classes.secondColumn}`}
                              dangerouslySetInnerHTML={{
                                __html: dimSplited[1]
                              }}
                            />
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                  <ul className={classes.ul}>
                    {dimensionsWithLink?.map((dimension, index) => (
                      <React.Fragment key={`dimension-${index}`}>
                        <Typography
                          component="li"
                          style={{
                            textTransform: "initial"
                          }}
                          dangerouslySetInnerHTML={{
                            __html: dimension
                          }}
                        />
                      </React.Fragment>
                    ))}
                  </ul>
                </TableContainer>
              </div>
            </>
          }
          open={open}
        >
          <Button
            variant="contained"
            color="inherit"
            fullWidth
            className={classes.detailDimensionButton}
            onClick={handleOpen}
            id="dimensions-form-dialog-button"
          >
            {buttonText}
          </Button>
        </Tooltip>
      </ClickAwayListener>
    </>
  );
};

export default memoize(ProductDimensionsDialog);
