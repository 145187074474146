import React from "react";
import { Grid, Typography } from "utils/material-ui-core";
import useTypographyStyles from "hooks/useTypographyStyles";
import { usePageContent } from "customProviders/LocationProvider";
import getCountryFromUrl from "utils/getCountryFromUrl";
import { NBSP } from "utils/constants";
import { checkCountryIsEu } from "utils/checkCountryIsEu";

const RHShippingDialogTitle = () => {
  const { pageContent } = usePageContent();
  const country = getCountryFromUrl();

  const typographyStyles = useTypographyStyles({
    keys: ["rhBaseH2"]
  });

  if (checkCountryIsEu({ country })) {
    return (
      <Grid item container direction={"row"}>
        <Typography className={typographyStyles.rhBaseH2}>
          {pageContent?.SHIPPING}
          {NBSP}
        </Typography>
        <Typography
          className={typographyStyles.rhBaseH2}
          dangerouslySetInnerHTML={{
            __html: pageContent?.AND_DELIVERY
          }}
        />
      </Grid>
    );
  }

  return (
    <Grid item>
      <Typography className={typographyStyles.rhBaseH2}>
        {pageContent?.SHIPPING}{" "}
      </Typography>
      <Typography className={typographyStyles.rhBaseH2}>
        {pageContent?.AND_DELIVERY}
      </Typography>
    </Grid>
  );
};

export default RHShippingDialogTitle;
