import React, { FC } from "react";
import {
  Button,
  Grid,
  Typography,
  Theme,
  useTheme,
  FormControl
} from "utils/material-ui-core";
import { createStyles, makeStyles } from "@material-ui/core";
import useButtonStyles from "hooks/useButtonStyles";
import useTypographyStyles from "hooks/useTypographyStyles";
import classNames from "classnames";
import { usePageContent } from "customProviders/LocationProvider";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      minHeight: theme.spacing(34)
    },
    addedText: {
      textAlign: "center",
      [theme.breakpoints.up("md")]: {
        textAlign: "left"
      }
    }
  })
);

export interface ConfirmedProps {
  onClose?: (event: {}, reason: "backdropClick" | "escapeKeyDown") => void;
  qty: number;
}

export const Confirmed: FC<ConfirmedProps> = ({ qty, onClose }) => {
  const { pageContent } = usePageContent();
  const theme = useTheme();
  const classes = useStyles();
  const buttonStyles = useButtonStyles({
    keys: ["primaryBlackBtn", "secondaryBtn"]
  });
  const typographyStyles = useTypographyStyles({
    keys: ["rhBaseBody2"]
  });

  return (
    <>
      <Grid
        item
        xs={12}
        alignItems="center"
        style={{
          paddingBottom: theme.spacing(6.5),
          display: "flex",
          justifyContent: "center"
        }}
      >
        <Typography
          component="p"
          className={classNames([
            classes.addedText,
            typographyStyles.rhBaseBody2
          ])}
          style={{ textTransform: "uppercase" }}
        >
          {`${qty} ${qty > 1 ? pageContent?.ITEMS : pageContent?.ITEM} ${
            pageContent?.ADDED_TO_YOUR_REGISTRY
          }`}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <FormControl margin={"normal"} fullWidth>
          <Button
            id="ajax-proceed-to-registry"
            href="/gift-registry/manage-gift-lists.jsp"
            className={buttonStyles.primaryBlackBtn}
            autoFocus
          >
            {pageContent?.MANAGE_REGISTRY}
          </Button>
        </FormControl>
        <FormControl margin={"normal"} fullWidth>
          <Button
            id="ajax-continue-shopping"
            className={buttonStyles.secondaryBtn}
            onClick={event => onClose?.(event, "escapeKeyDown")}
          >
            {pageContent?.KEEP_SHOPPING}
          </Button>
        </FormControl>
      </Grid>
    </>
  );
};

Confirmed.defaultProps = {};

export default Confirmed;
