import React, { FC, useState } from "react";
import {
  Button,
  Typography,
  makeStyles,
  createStyles,
  IconButton,
  ClickAwayListener,
  Popover,
  useTheme,
  useMediaQuery,
  Grow
} from "utils/material-ui-core";
import RHCloseIcon from "icons/RHCloseIcon";
import Prop65 from "component-prop65";
import memoize from "utils/memoize";
import { Tooltip } from "@mui/material";
import { hasPDPWarning } from "utils/pdpHelper";

const useStyles = makeStyles(theme =>
  createStyles({
    popover: {
      minWidth: "343px",
      height: "564px",
      top: "93px !important",
      borderRadius: 12,
      background: "var(--ReimagineWarm-RH-Buff, #F9F7F4)",
      boxShadow: "0px 0px 20px 0px rgba(0, 0, 0, 0.10)"
    },
    tooltip: {
      bottom: "8px !important",
      minWidth: 600,
      maxHeight: 456,
      padding: 0,
      borderRadius: "12px",
      background: "var(--ReimagineWarm-RH-Buff, #F9F7F4)",
      boxShadow: "0px 0px 20px 0px rgba(0, 0, 0, 0.10)",
      "& .MuiTooltip-tooltip": {
        minWidth: 600,
        background: "transparent",
        padding: "0px !important",
        margin: 0,
        transition: "none !important"
      }
    },
    content: {
      display: "flex",
      flexDirection: "column",
      gap: "16px",
      width: "100%",
      overflow: "hidden",
      height: "auto",
      maxHeight: "384px",
      marginBottom: "48px",
      [theme.breakpoints.down("xs")]: {
        height: "532px",
        maxHeight: "532px",
        marginBottom: "32px"
      }
    },
    detailDimensionButton: {
      display: "flex",
      minWidth: "191.72px",
      width: "191.72px",
      minHeight: "36px",
      height: "36px",
      flexDirection: "column",
      justifyContent: "center",
      color: "var(--ReimagineWarm-RH-Black, #000)",
      textAlign: "center",
      fontFamily: "RHSans",
      fontSize: "13px",
      fontWeight: 300,
      lineHeight: "100%" /* 13px */,
      letterSpacing: "0.26px",
      border: "0.5px solid #000",
      background: "transparent",
      textTransform: "capitalize",
      padding: "0px 0.292px 0px 0.987px !important",
      "&:hover": {
        background: "transparent",
        borderColor: "inherit",
        color: "inherit"
      },
      [theme.breakpoints.down("xs")]: {
        minWidth: "166.38px",
        padding: "0px 0.254px 0px 0.857px !important"
      }
    },
    title: {
      color: "#000",
      fontFamily: "RHSans",
      fontSize: 22,
      fontWeight: 100,
      lineHeight: "90%" /* 19.8px */,
      letterSpacing: "0.44px",
      textTransform: "uppercase",
      margin: "48px 0px 0px 56px",
      textAlign: "left",
      width: "100%",
      [theme.breakpoints.down("xs")]: {
        margin: "32px 0px 0px 16px",
        paddingLeft: "6px"
      }
    },
    ul: {
      padding: 0,
      margin: "0px 0px 0px 50px",
      paddingRight: "75px",
      overflow: "auto",
      listStyle: "initial",

      [`& li[style*="display: list-item"]`]: {
        marginLeft: theme.spacing(2),
        textAlign: "left",
        fontFamily: "RHSans",
        color: "var(--ReimagineWarm-RH-Black, #000)",
        fontSize: "11px",
        fontWeight: 100,
        lineHeight: "130%" /* 14.3px */,
        letterSpacing: "0.44px"
      },
      [theme.breakpoints.down("xs")]: {
        margin: "0px 0px 0px 16px",
        paddingRight: "32px"
      }
    },
    iconButton: {
      width: 48,
      height: 48,
      padding: 16
    },
    closeIcon: {
      fontSize: 16,
      color: "#000"
    },
    centeredTooltip: {
      left: "100px !important"
    }
  })
);

interface ProductDetailsDialogProps {
  buttonText?: string;
  title?: string;
  detailsContent?: string[];
  centered?: boolean;
}

const ProductDetailsDialog: FC<ProductDetailsDialogProps> = ({
  buttonText,
  title,
  detailsContent,
  centered
}) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const theme = useTheme();
  const smDown = useMediaQuery(theme.breakpoints.down("xs"));

  const handleClosePopover = () => {
    setAnchorEl(null);
  };
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = (event: any) => {
    setOpen(false);
  };

  const handleClickAway = (event: React.MouseEvent<Document>) => {
    const clickedElement = event.target as HTMLElement;
    if (
      !clickedElement.id.includes("configurator_option_value") &&
      !clickedElement.closest("[id*=configurator_option_value]") &&
      !clickedElement.closest("[id*=details-form-dialog-container]")
    ) {
      setOpen(false);
    }
  };
  const openPop = Boolean(anchorEl);
  const id = openPop ? "detail-mobile-popover" : undefined;

  if (smDown) {
    return (
      <>
        <Button
          variant="contained"
          color="inherit"
          fullWidth
          className={classes.detailDimensionButton}
          onClick={handleClick}
          id="details-form-dialog-button-mobile"
          data-testid="details-form-dialog-button-mobile"
        >
          {buttonText}
        </Button>
        <Popover
          id={id}
          open={openPop}
          anchorEl={anchorEl}
          onClose={handleClosePopover}
          classes={{ paper: classes.popover }}
          anchorOrigin={{
            vertical: "top",
            horizontal: "center"
          }}
          transformOrigin={{
            vertical: "bottom",
            horizontal: "center"
          }}
          role="detail-popover-mobile"
        >
          <div className={classes.content}>
            <div
              style={{
                width: "100%",
                justifyContent: "space-between",
                display: "flex"
              }}
            >
              <Typography variant="h6" className={classes.title}>
                {title}
              </Typography>

              <IconButton
                className={classes.iconButton}
                color="inherit"
                onClick={handleClosePopover}
                data-testid="form-dialog-close-button-mobile"
              >
                <RHCloseIcon className={classes.closeIcon} />
              </IconButton>
            </div>

            <ul className={classes.ul} data-testid="product-details-lists">
              {detailsContent?.map((feature, index) => {
                const warning = hasPDPWarning(feature);
                return (
                  <React.Fragment key={`feature-${index}`}>
                    {warning?.hasWarning || feature.includes("pdpWarning") ? (
                      <li
                        style={{
                          textTransform: "initial",
                          display: "list-item"
                        }}
                      >
                        <Prop65 warning={warning} />
                      </li>
                    ) : (
                      <Typography
                        component="li"
                        style={{
                          textTransform: "initial",
                          display: "list-item"
                        }}
                        dangerouslySetInnerHTML={{
                          __html: feature
                        }}
                      />
                    )}
                  </React.Fragment>
                );
              })}
            </ul>
          </div>
        </Popover>
      </>
    );
  }
  return (
    <>
      <ClickAwayListener onClickAway={handleClickAway}>
        <Tooltip
          PopperProps={{
            className: `${classes.tooltip} ${
              centered ? classes.centeredTooltip : ""
            }`,
            disablePortal: true,
            placement: "top",
            modifiers: [
              {
                name: "flip",
                enabled: false
              },
              {
                name: "preventOverflow",
                enabled: false
              },
              {
                name: "offset",
                options: {
                  offset: [0, 10]
                }
              },
              {
                name: "applyTransform",
                enabled: true,
                phase: "beforeWrite",
                fn: ({ state }) => {
                  if (
                    state.elements &&
                    state.elements.reference &&
                    state.styles
                  ) {
                    state.styles.transform = (
                      state.elements.reference as HTMLElement
                    ).style.transform;
                  }
                },
                effect: ({ state }) => {
                  if (state.elements && state.elements.popper) {
                    return () => {
                      state.elements.popper.style.transform = "";
                    };
                  }
                }
              }
            ]
          }}
          id="details-form-dialog-container"
          disableFocusListener
          disableHoverListener
          disableTouchListener
          TransitionComponent={Grow}
          TransitionProps={{ timeout: 0 }}
          title={
            <>
              <div
                className={classes.content}
                data-testid="details-modal-popper"
              >
                <div
                  style={{
                    width: "100%",
                    justifyContent: "space-between",
                    display: "flex"
                  }}
                >
                  <Typography variant="h6" className={classes.title}>
                    {title}
                  </Typography>
                  <IconButton
                    className={classes.iconButton}
                    onClick={handleClose}
                    data-testid="form-dialog-close-button"
                  >
                    <RHCloseIcon className={classes.closeIcon} />
                  </IconButton>
                </div>

                <ul className={classes.ul} data-testid="product-details-lists">
                  {detailsContent?.map((feature, index) => {
                    const warning = hasPDPWarning(feature);
                    return (
                      <React.Fragment key={`feature-${index}`}>
                        {warning?.hasWarning ||
                        feature.includes("pdpWarning") ? (
                          <li
                            style={{
                              textTransform: "initial",
                              display: "list-item"
                            }}
                          >
                            <Prop65 warning={warning} />
                          </li>
                        ) : (
                          <Typography
                            component="li"
                            style={{
                              textTransform: "initial",
                              display: "list-item"
                            }}
                            dangerouslySetInnerHTML={{
                              __html: feature
                            }}
                          />
                        )}
                      </React.Fragment>
                    );
                  })}
                </ul>
              </div>
            </>
          }
          open={open}
        >
          <Button
            variant="contained"
            color="inherit"
            fullWidth
            className={classes.detailDimensionButton}
            onClick={handleOpen}
            id="details-form-dialog-button"
          >
            {buttonText}
          </Button>
        </Tooltip>
      </ClickAwayListener>
    </>
  );
};

export default memoize(ProductDetailsDialog);
