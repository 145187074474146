import React, {
  FC,
  useEffect,
  useState,
  RefObject,
  useMemo,
  useCallback
} from "react";

import {
  useMediaQuery,
  Theme,
  createStyles,
  makeStyles,
  DialogContent,
  Grid,
  GridSpacing,
  Typography,
  Box,
  FormControl,
  Button,
  IconButton
} from "utils/material-ui-core";
import { useEnv } from "hooks/useEnv";
import yn from "yn";
import { usePageContent } from "customProviders/LocationProvider";
import Drawer from "component-drawer";
import {
  BREAKPOINT_LG,
  BREAKPOINT_MD,
  BREAKPOINT_SM,
  BREAKPOINT_XL,
  BREAKPOINT_XS,
  FONT_BARON_SANS_THIN,
  INTRODUCING,
  RHUK_HOST,
  SKELETON_ANIMATION
} from "utils/constants";
import useResponsiveGridSpacing from "hooks/useResponsiveGridSpacing";
import classNames from "classnames";
import { DialogProps } from "@material-ui/core/Dialog";
import memoize from "utils/memoize";
import useSite from "hooks/useSite";
import useTypographyStyles from "hooks/useTypographyStyles";
import useParams from "hooks/useParams";
import RHPriceRangeDisplay from "component-rh-price-range-display";
import OptionsDetailsList from "component-options-details-list";
import { SkuLabelMobile } from "component-search-result-card";
import CardImages from "component-rhr-product-card/CardImages";
import { useQuery } from "@apollo/client";
import { queryInStockItems } from "graphql-client/queries/product-inStockItems";
import { useCurrencyCode } from "hooks/useCurrencyCode";
import useUserPreferences from "hooks/useUserPreferences";
import { useIsoCookies } from "hooks/useIsoCookies";
import { useAppId } from "hooks-use-app-id";
import RHRProductListSkeleton from "skeleton-rhr-product-list";
import AddToCartDialog, { AddToCartDialogProps } from "dialog-add-to-cart";
import analyticsLoader from "analytics/loader";
import RHStandardSelect from "component-rh-standard-select";
import RHCloseIcon from "icon-close";
import useLocale from "hooks-use-locale/useLocale";
import RefineMenuInstockV2 from "component-refine-menu-instock-v2";
import GridController, { SortMenu } from "./GridController";
import getCountryFromUrl from "utils/getCountryFromUrl";
import { countryNameMapper } from "resources/countries-config.json";
import { useRhUserAtomValue } from "hooks/atoms";
import TailwindDrawer from "@RHCommerceDev/component-tailwind-drawer";

const DEFAULT_INSTOCK_ITEM_COUNT = 50;
const DEFAULT_NRPP: number = 12;
const DEFAULT_GRID_COL = 4;
const PG_GRID_CHOICE = "pgGridChoice";
const DEFAULT_GRID_COL_CONCIERGE = 4;
const SIP_ID = "sipId";
const LAST_SORTMENU = "lastSortMenu";
const WHITE_COLOR = "white";
const BLACK_COLOR = "#666666";
const TRANSPARENT = "transparent";
const DEFAULT_MAX_QUANTITY = 40;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      marginTop: theme.spacing(2)
    },
    delivery: {
      marginTop: theme.spacing(1)
    },
    swatchGroupListItem: {
      marginTop: theme.spacing(1),
      "&:first-child": {
        marginTop: theme.spacing(2)
      }
    },
    containerRoot: {
      [theme.breakpoints.up("xl")]: {
        paddingLeft: theme.gutters.lg,
        paddingRight: theme.gutters.lg
      },
      background: theme?.palette?.common?.white || "#ffffff"
    },

    removeBlur: {
      "& .MuiBackdrop-root ": {
        opacity: "0 !important"
      }
    },
    containerRootV2: {
      paddingLeft: 0,
      paddingRight: 0,
      background: "#f9f7f4"
    },
    tabWrapper: {
      display: "flex",
      width: "100%",
      "& .MuiTabs-flexContainer": {
        gap: "8px",
        display: "flex",
        width: "100%"
      }
    },
    tabItem: {
      flex: 1,
      border: "0.5px solid #898886",
      background: "#fff",
      color: "#000",
      textAlign: "center",
      fontFamily: "RHSans",
      fontSize: "11px",
      lineHeight: "115%",
      textTransform: "uppercase",
      fontStyle: "normal",
      fontWeight: 300,
      opacity: "unset",
      maxWidth: "342px",
      minWidth: "342px",
      minHeight: "44px",
      maxHeight: "44px",
      [theme?.breakpoints?.between(BREAKPOINT_LG, BREAKPOINT_XL)]: {
        maxWidth: "253px",
        minWidth: "253px"
      },
      [theme?.breakpoints?.between(BREAKPOINT_MD, BREAKPOINT_LG)]: {
        maxWidth: "200px",
        minWidth: "200px"
      },
      [theme?.breakpoints?.down(BREAKPOINT_MD)]: {
        maxWidth: "164px",
        minWidth: "164px"
      }
    },
    tabItemActive: {
      background: "#F1F0ED",
      fontWeight: 400,
      border: "0.5px solid #000"
    },

    gridContainer: {
      paddingRight: 40,
      paddingLeft: 40,
      paddingTop: 79,
      [theme.breakpoints.down(BREAKPOINT_XL)]: {
        paddingTop: 90
      },
      [theme.breakpoints.down(BREAKPOINT_MD)]: {
        paddingTop: 75
      },
      [theme.breakpoints.down(BREAKPOINT_SM)]: {
        paddingTop: 71
      }
    },
    swatchHeading: {
      color: "#000",
      fontFamily: "RHSans",
      fontSize: "32px",
      fontStyle: "normal",
      fontWeight: 75,
      lineHeight: "32px",
      letterSpacing: "-0.64px",
      marginBottom: "30px",
      [theme?.breakpoints?.down(BREAKPOINT_MD)]: {
        marginBottom: "36px"
      }
    },
    wrapperBox: {
      margin: "0 -16px",
      paddingLeft: "8px",
      paddingRight: "8px",

      [theme.breakpoints.up(BREAKPOINT_SM)]: {
        margin: "0 -32px",
        paddingLeft: "16px",
        paddingRight: "16px"
      },
      [theme.breakpoints.up(BREAKPOINT_MD)]: {
        margin: "0 -40px",
        paddingLeft: "20px",
        paddingRight: "20px"
      },

      [theme.breakpoints.up(BREAKPOINT_XL)]: {
        margin: "0 -80px",
        paddingLeft: "60px",
        paddingRight: "60px"
      }
    },
    gridTile: {
      paddingLeft: "8px",
      paddingRight: "8px",
      paddingTop: "32px",
      [theme.breakpoints.up(BREAKPOINT_XS)]: {
        paddingBottom: "24px"
      },
      [theme.breakpoints.up(BREAKPOINT_SM)]: {
        paddingLeft: "16px",
        paddingRight: "16px",
        paddingBottom: "36px"
      },
      [theme.breakpoints.up(BREAKPOINT_MD)]: {
        paddingLeft: "20px",
        paddingRight: "20px",
        paddingBottom: "48px"
      },
      [theme.breakpoints.up(BREAKPOINT_LG)]: {
        paddingBottom: "60px"
      },
      [theme.breakpoints.up(BREAKPOINT_XL)]: {
        paddingBottom: "80px"
      }
    },

    rhrImgGridTile: {
      paddingBottom: "24px",
      paddingLeft: "8px",
      paddingRight: "8px",
      [theme.breakpoints.up(BREAKPOINT_SM)]: {
        paddingBottom: "36px",
        paddingLeft: "16px",
        paddingRight: "16px"
      },
      [theme.breakpoints.up(BREAKPOINT_MD)]: {
        paddingBottom: "48px",
        paddingLeft: "20px",
        paddingRight: "20px"
      },
      [theme.breakpoints.up(BREAKPOINT_LG)]: {
        paddingBottom: "60px"
      },
      [theme.breakpoints.up(BREAKPOINT_XL)]: {
        paddingBottom: "80px"
      }
    },
    rhrTitle: {
      paddingTop: "32px",
      paddingLeft: "8px",
      paddingRight: "8px",
      paddingBottom: "36px",
      [theme.breakpoints.up(BREAKPOINT_SM)]: {
        paddingLeft: "16px",
        paddingRight: "16px",
        paddingBottom: "48px"
      },
      [theme.breakpoints.up(BREAKPOINT_MD)]: {
        paddingLeft: "20px",
        paddingRight: "20px",
        paddingBottom: "60px"
      },
      [theme.breakpoints.up(BREAKPOINT_LG)]: {
        paddingBottom: "80px"
      },
      [theme.breakpoints.up(BREAKPOINT_XL)]: {
        paddingBottom: "100px"
      }
    },
    productNamePriceBox: {
      marginTop: 10,
      [theme.breakpoints.down(BREAKPOINT_MD)]: {
        ".cols-6 &": {
          marginTop: 8
        },
        ".cols-4 &": {
          marginTop: 6
        }
      },

      [theme.breakpoints.down(BREAKPOINT_SM)]: {
        ".cols-6 &": {
          marginTop: 6
        },
        ".cols-12 &": {
          marginTop: 6
        }
      }
    },
    newFlag: {
      fontWeight: "bold"
    },
    productNamePrice: {
      "& .priceSpan": {
        display: "block",
        fontFamily: FONT_BARON_SANS_THIN,
        lineHeight: "1.5rem",
        [theme.breakpoints.up(BREAKPOINT_XS)]: {
          display: "inline"
        },
        ".cols-12 &": {
          display: "inline"
        }
      },
      [theme.breakpoints.down(BREAKPOINT_SM)]: {
        ".cols-6 &": {
          fontSize: "11px",
          lineHeight: "13px"
        }
      },
      [theme.breakpoints.down(BREAKPOINT_MD)]: {
        ".cols-4 &": {
          fontSize: "11px",
          lineHeight: "13px"
        }
      },
      "& .priceSeperator": {
        ".cols-12 &": {
          display: "inline"
        },
        display: "none",
        [theme.breakpoints.up(BREAKPOINT_XS)]: {
          display: "inline"
        }
      }
    },

    priceBox: {
      display: "flex",
      flexWrap: "wrap"
    },

    productPrice: {
      display: "inline"
    },

    priceMessageText: {
      display: "inline",
      whiteSpace: "nowrap",
      color: "#000000",

      [theme.breakpoints.down(BREAKPOINT_SM)]: {
        whiteSpace: "normal"
      }
    },

    swatchesNumber: {
      flexGrow: 1
    },

    swatchesBox: {
      display: "grid",
      gridGap: "5px",
      marginTop: 20,
      [theme.breakpoints.down(BREAKPOINT_LG)]: {
        ".cols-6 &": {
          marginTop: 16
        },
        ".cols-4 &": {
          marginTop: 16
        }
      },
      [theme.breakpoints.down(BREAKPOINT_MD)]: {
        ".cols-6 &": {
          marginTop: 14
        },
        ".cols-4 &": {
          marginTop: 14
        }
      },
      [theme.breakpoints.down(BREAKPOINT_SM)]: {
        ".cols-6 &": {
          marginTop: 12
        },
        ".cols-12 &": {
          marginTop: 14
        }
      }
    },
    swatchColor: {
      flexShrink: 0,
      marginRight: 3,
      width: "100%",
      ".cols-12 &": {
        [theme.breakpoints.down(BREAKPOINT_SM)]: {
          height: 12
        }
      },
      ".cols-6 &": {
        height: 12,
        [theme.breakpoints.up(BREAKPOINT_SM)]: {
          height: 12
        },
        [theme.breakpoints.up(BREAKPOINT_MD)]: {
          height: 12
        },
        [theme.breakpoints.up(BREAKPOINT_LG)]: {
          height: 20
        },
        [theme.breakpoints.up(BREAKPOINT_XL)]: {
          height: 20
        },
        [theme.breakpoints.down(BREAKPOINT_SM)]: {
          height: 10
        }
      },
      ".cols-4 &": {
        height: 12,
        [theme.breakpoints.down(BREAKPOINT_SM)]: {
          height: 10
        },
        [theme.breakpoints.up(BREAKPOINT_SM)]: {
          height: 10
        },
        [theme.breakpoints.up(BREAKPOINT_MD)]: {
          height: 12
        },
        [theme.breakpoints.up(BREAKPOINT_LG)]: {
          height: 20
        },
        [theme.breakpoints.up(BREAKPOINT_XL)]: {
          height: 20
        }
      }
    },
    reveal: {
      opacity: 0
    },

    fadeInUp: {
      animationDuration: "0s",
      animationTimingFunction: "easeIn",
      animationDelay: "0s",
      animationIterationCount: 1,
      animationDirection: "normal",
      animationFillMode: "none",
      animationPlayState: "running",
      animationName: "fadeInUp"
    },
    galleryDescription: {
      paddingTop: 2,
      [theme.breakpoints.down(BREAKPOINT_SM)]: {
        ".cols-12 &": {
          paddingTop: 6
        }
      },
      [theme.breakpoints.up(BREAKPOINT_XL)]: {
        ".cols-6 &": {
          paddingTop: 6
        }
      }
    },
    galleryDescriptionNoSwatches: {
      marginTop: 18,
      [theme.breakpoints.down(BREAKPOINT_MD)]: {
        marginTop: 16
      }
    },

    viewSelectSaleLink: {
      marginTop: 10,
      textTransform: "uppercase",
      color: "#CA2022 !important",
      textDecoration: "underline !important",
      textUnderlineOffset: "3px",
      textDecorationThickness: "0.5px",
      [theme.breakpoints.down(BREAKPOINT_MD)]: {
        ".cols-6 &": {
          marginTop: 8
        },
        ".cols-4 &": {
          marginTop: 6,
          fontSize: "11px",
          lineHeight: "13px"
        }
      },
      [theme.breakpoints.down(BREAKPOINT_SM)]: {
        ".cols-6 &": {
          marginTop: 6,
          fontSize: "11px",
          lineHeight: "13px"
        },
        ".cols-12 &": {
          marginTop: 6
        }
      }
    },
    inStockTitle: {
      textTransform: "uppercase",
      backgroundColor: "#f9f7f4",
      [theme.breakpoints.down(BREAKPOINT_SM)]: {
        paddingTop: 36
      }
    },
    inStockSubtitle: {
      paddingTop: 4,
      backgroundColor: "#f9f7f4",
      [theme.breakpoints.down(BREAKPOINT_MD)]: {
        paddingTop: 10
      }
    },
    outlinedPrimary: {
      borderColor: BLACK_COLOR,
      color: BLACK_COLOR,
      backgroundColor: TRANSPARENT,
      "&:hover": {
        backgroundColor: BLACK_COLOR,
        color: WHITE_COLOR,
        borderColor: BLACK_COLOR
      }
    },
    outlinedSecondary: {
      border: `1px solid ${BLACK_COLOR}`,
      color: BLACK_COLOR,
      backgroundColor: TRANSPARENT,
      height: "42px",
      width: "150px",
      [theme.breakpoints.down(BREAKPOINT_MD)]: {
        height: "42px",
        width: "139px"
      },
      "&:hover": {
        color: WHITE_COLOR,
        backgroundColor: BLACK_COLOR
      }
    },
    icon: {
      position: "absolute",
      top: 14,
      right: 14
    },
    rhr_icon_position: {
      fontSize: "20px"
    },
    closeIcon: {
      fontSize: "16px"
    },
    add_to_cart_btn_form: {
      display: "flex",
      alignItems: "center",
      paddingTop: "12px",
      [theme.breakpoints.down(BREAKPOINT_XL)]: {
        paddingTop: "10px"
      },
      [theme.breakpoints.down(BREAKPOINT_MD)]: {
        paddingTop: "3px"
      }
    },
    refinementContainer: {
      padding: "16px 0px 71px 0px",
      justifyContent: "space-between",
      [theme.breakpoints.up(BREAKPOINT_SM)]: {
        padding: "35px 0px 75px 0px"
      },
      [theme.breakpoints.up(BREAKPOINT_MD)]: {
        padding: "34px 0px 90px 0px"
      },
      [theme.breakpoints.up(BREAKPOINT_LG)]: {
        padding: "37px 0px 90px 0px"
      },
      [theme.breakpoints.up(BREAKPOINT_XL)]: {
        padding: "36px 0px 79px 0px"
      }
    },
    sticky: {
      position: "sticky",
      top: 0,
      zIndex: 1
    },

    refinementBar: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center"
    },
    booleanRefinement: {
      marginRight: 30,
      [theme.breakpoints.up(BREAKPOINT_LG)]: {
        marginRight: 40
      },
      [theme.breakpoints.up(BREAKPOINT_XL)]: {
        marginRight: 60
      },

      "& .MuiCheckbox-root": {
        marginLeft: `0 !important`,
        backgroundColor: "transparent !important"
      }
    }
  })
);

interface ProductDetailsProps {
  product: any;
  isStockedFilterActive: boolean;
  isRefinementFilterActive: boolean;
  productSku?: SearchRecordSku;
  gridColumns?: any;
  isSale?: boolean;
  host?: string;
  totalNumRecs: number;
}

interface PriceData {
  currencyLabel: string;
  currencySymbol: string;
  listPrice: number;
  memberPrice: number;
  finalSalePrice: number;
  priceMessage: Maybe<string>;
  priceMessagePrice: Maybe<number>;
  saleMemberPrice: Maybe<number>;
  lowestSkuMemberPrice: Maybe<number>;
  lowestSkuRegularPrice: Maybe<number>;
  lowestSkuSalePrice: Maybe<number>;
}
const getUrl = (
  item,
  host: string = "",
  stocked: boolean,
  totalNumRecs: number,
  isSale: boolean
) => {
  const saleParamString = isSale ? "&sale=true" : "";
  let url = `${host}/catalog/product/product.jsp?productId=${item?.product?.repositoryId}${saleParamString}`;

  if (
    item?.product?.repositoryId !== item?.sku?.fullSkuId &&
    (stocked || totalNumRecs === 1)
  ) {
    url += `&fullSkuId=${item?.sku?.fullSkuId}&categoryId=search`;
  }
  url += `&layout=${item?.product?.pdpImageLayout}`;

  return {
    to: !host ? url : undefined
  };
};

export const getPriceUserType = (userType: string, price) => {
  switch (userType) {
    case "CONTRACT":
      return price?.contractPrice;
    case "TRADE":
      return price?.tradePrice;
    default:
      return price?.memberPrice;
  }
};

const getPriceData = (
  {
    product,
    isStockedFilterActive
  }: Pick<ProductDetailsProps, "product" | "isStockedFilterActive">,
  userType?: string
): PriceData => {
  const { priceInfo, skuPriceInfo } = product;
  const siteId = useSite();

  let isInternational: boolean = false;
  if (siteId === RHUK_HOST && priceInfo?.nextGenDriven) {
    isInternational = true;
  }

  const currencyLabel =
    isStockedFilterActive && typeof skuPriceInfo?.currencyLabel === "string"
      ? skuPriceInfo?.currencyLabel
      : priceInfo?.currencyLabel!;
  const currencySymbol =
    isStockedFilterActive && typeof skuPriceInfo?.currencySymbol === "string"
      ? skuPriceInfo?.currencySymbol
      : priceInfo?.currencySymbol!;

  const listPrice =
    isStockedFilterActive && skuPriceInfo?.listPrice
      ? skuPriceInfo?.listPrice
      : priceInfo?.listPrices?.[0]!;

  const memberPrice =
    isStockedFilterActive && skuPriceInfo?.memberPrice
      ? getPriceUserType(userType!, skuPriceInfo)
      : priceInfo?.memberPrices?.[0]!;

  const saleMemberPrice =
    isStockedFilterActive && skuPriceInfo?.memberPrice
      ? skuPriceInfo?.memberPrice
      : priceInfo?.lowestMemberPrice!;

  const finalSalePrice =
    isStockedFilterActive && skuPriceInfo?.salePrice
      ? skuPriceInfo?.salePrice
      : priceInfo?.lowestSalePrice! || priceInfo?.salePrices?.[0]!;

  const lowestSkuMemberPrice =
    product?.lowestskuPriceInfo !== null &&
    !isStockedFilterActive &&
    !product?.metaProduct &&
    !isInternational
      ? product?.lowestskuPriceInfo?.skuMemberSalePrice!
      : product?.priceInfo?.skulowestMemberPrice;

  const lowestSkuRegularPrice =
    product?.lowestskuPriceInfo !== null &&
    !isStockedFilterActive &&
    !product?.metaProduct &&
    !isInternational
      ? product?.lowestskuPriceInfo?.skuListSaleprice!
      : product?.priceInfo?.skulowestListPrice;

  const lowestSkuSalePrice =
    product?.lowestskuPriceInfo !== null &&
    !isStockedFilterActive &&
    !product?.metaProduct &&
    !isInternational
      ? product?.lowestskuPriceInfo?.skuSalePrice!
      : product?.priceInfo?.skulowestSalePrice;

  const priceMessage = product.priceInfo?.priceMessage;
  const priceMessagePrice = product.priceInfo?.priceMessagePrice;

  return {
    currencyLabel,
    currencySymbol,
    listPrice,
    memberPrice,
    saleMemberPrice,
    finalSalePrice,
    priceMessage,
    priceMessagePrice,
    lowestSkuMemberPrice,
    lowestSkuRegularPrice,
    lowestSkuSalePrice
  };
};

const ProductDetails: FC<ProductDetailsProps> = ({
  product,
  isStockedFilterActive,
  productSku
}) => {
  const { userType } = useRhUserAtomValue();
  const classes = useStyles();
  const mdUp = useMediaQuery((theme: Theme) => theme.breakpoints.up("md"));

  const {
    listPrice,
    finalSalePrice,
    priceMessage,
    lowestSkuMemberPrice,
    lowestSkuSalePrice
  } = getPriceData({ product, isStockedFilterActive }, userType!);

  const lowestSalePrice =
    !lowestSkuSalePrice && isStockedFilterActive
      ? finalSalePrice
      : lowestSkuSalePrice;

  const typographyStyles = useTypographyStyles({
    keys: ["rhBaseBody1", "rhBaseCaption", "priceRangeSale", "rhBaseBody2"]
  });

  let nextGenDrivenOnSale: boolean = product?.priceInfo?.nextGenDrivenOnSale!;

  if (isStockedFilterActive && product?.skuPriceInfo?.onSale) {
    nextGenDrivenOnSale = product?.skuPriceInfo?.onSale;
  }

  const xlUp = useMediaQuery<Theme>(theme => theme.breakpoints.up("xl"));
  const isFinalSale = useMemo(
    () =>
      lowestSalePrice &&
      finalSalePrice &&
      finalSalePrice != 0 &&
      listPrice != finalSalePrice &&
      !priceMessage &&
      nextGenDrivenOnSale,
    [finalSalePrice, listPrice, priceMessage]
  );

  const params = useParams({ sale: "" });

  const hasItemsOnSale =
    (product?.percentSaleSkus ?? 0) > 0 &&
    (product?.percentSaleSkus ?? 0) < 100 &&
    finalSalePrice !== null &&
    finalSalePrice !== undefined &&
    nextGenDrivenOnSale;

  const priceRangeDisplay = useMemo(() => {
    return !isStockedFilterActive && product?.priceInfo !== null
      ? product?.priceInfo
      : product?.skuPriceInfo;
  }, [product?.priceInfo, product?.skuPriceInfo]);

  const isSaleStyling = isFinalSale || hasItemsOnSale;
  const env = useEnv();
  const centerAlignFlag = true;
  const country = getCountryFromUrl();
  const currencyCode = countryNameMapper[country];

  return (
    <>
      <div>
        <Typography
          className={classNames([
            classes.productNamePrice,
            typographyStyles.rhBaseBody1
          ])}
          style={{
            textTransform: "uppercase",
            paddingRight: centerAlignFlag ? "none" : 24
          }}
          align={centerAlignFlag ? "center" : "left"}
        >
          {product.newProduct && (
            <span
              className={classNames([
                typographyStyles.rhBaseBody1,
                classes.newFlag
              ])}
            >
              {INTRODUCING}{" "}
            </span>
          )}
          <span
            dangerouslySetInnerHTML={{
              __html: product?.displayName || ""
            }}
          ></span>
        </Typography>
      </div>
      <RHPriceRangeDisplay
        data={priceRangeDisplay as ProductPriceRangeDisplay}
        display="inline"
        variant={"small"}
        drivedStyles={{
          paddingTop: "10px",
          paddingBottom: xlUp ? "14px" : "10px"
          // fontSize: "11px !important",
          // fontFamily: "baron sans roman !important",
        }}
        centerAlignFlag={centerAlignFlag!}
        onViewSelectItemsOnSaleClick={() => true}
        lowestPgSkuMemberPrice={lowestSkuMemberPrice!}
        lowestPgSkuSalePrice={lowestSkuSalePrice!}
        metaProductComponent={product?.metaProductComponent!}
        inStockPgPrice={product?.skuPriceInfo!}
        isSkuLevel={isStockedFilterActive ? true : false}
        isMetaProduct={product?.metaProduct!}
        topLabel={
          "no-label"
          // !isStockedFilterActive
          //   ? product.priceInfo?.overridePriceLabel || undefined
          //   : ""
        }
        nextGenDrivenOnSale={
          isStockedFilterActive && product?.skuPriceInfo?.onSale
            ? product?.skuPriceInfo?.onSale
            : product?.priceInfo?.nextGenDrivenOnSale!
        }
        isStockedFilterActive={isStockedFilterActive}
        currency={currencyCode}
      />

      <Box mt={1} marginTop={"0px"}>
        {product?.skuPriceInfo && (
          <>
            {mdUp ? (
              <OptionsDetailsList
                itemId={productSku?.fullSkuId || ""}
                options={product?.optionList || []}
                isStocked={isStockedFilterActive}
                centerAlignFlag={centerAlignFlag}
              />
            ) : (
              <>
                {product?.optionList?.map((option: ProductItemOption) => (
                  <SkuLabelMobile
                    centerAlignFlag={centerAlignFlag}
                    label={option.optionType}
                    value={option.label}
                    styles={{ paddingBottom: "10px", textTransform: "none" }}
                  />
                ))}
              </>
            )}
          </>
        )}
      </Box>
    </>
  );
};

const PD = memoize(ProductDetails);
interface ProductCard {
  isStockedFilterActive: boolean;
  isRefinementFilterActive: boolean;
  gridColumns: any;
  view: string;
  totalNumRecs: number;
  noLazy?: boolean;
  host?: string;
  brand?: string;
  productClickHandler?: (sipId: string) => void;
  selectedProductId?: string;
  item?: any;
  index?: number;
  selectedProductRef?: RefObject<HTMLDivElement> | null;
  disableFadeEffect?: boolean;
  isSale?: boolean;
  isSaleFilterEnabled?: boolean;
  pgCropRulesFromCg?: string;
  displayName?: string;
  qty: number;
  handleAddToCartClick: (item: any, displayName: string, qty: number) => void;
  qtyAvailable: number;
  updateQty: (sku: string, qty: number) => void;
}

const ProductCard: FC<ProductCard> = ({
  item,
  index,
  gridColumns,
  isStockedFilterActive,
  isRefinementFilterActive,
  view,
  totalNumRecs,
  host = "",
  brand,
  selectedProductRef,
  isSale,
  displayName,
  handleAddToCartClick,
  qty,
  qtyAvailable,
  updateQty
}) => {
  const classes = useStyles();
  const env = useEnv();

  const typographyStyles = useTypographyStyles({
    keys: [
      "rhBaseBody1",
      "rhBaseH2",
      "rhBaseH4",
      "rhBaseCaption",
      "rhBaseBody2",
      "rhBaseBody3"
    ]
  });

  return (
    <Grid
      item
      key={`${brand}__${item?.sku?.fullSkuId}-${index}`}
      id={`${brand}__${item?.sku?.fullSkuId}__${index}`}
      xs={gridColumns}
      className={
        item && yn(env.FEATURE_RHR_IMAGE)
          ? classes.rhrImgGridTile
          : classes.gridTile
      }
      ref={selectedProductRef}
    >
      <div
      // onClick={e => {
      //   productClickHandler?.(`${item?.sku?.fullSkuId}__${index}`);
      // }}
      >
        <CardImages
          images={[
            item?.imageUrl ?? "",
            item?.altImageUrl ?? "",
            ...(item?.alternateImages ?? []).map(data => data.imageUrl)
          ].filter(Boolean)}
          view={view}
          gridColumns={gridColumns}
          imageFlip={Boolean(item?.imageFlip)}
          cardIndex={index}
          isRHR={item?.rhr ?? false}
        />

        <Typography
          className={classNames([
            classes.galleryDescriptionNoSwatches,
            // typographyStyles.rhBaseH4
            typographyStyles.rhBaseBody1
          ])}
          align={"center"}
          style={{
            textTransform: "uppercase"
          }}
        >
          {displayName}
        </Typography>

        <PD
          product={item}
          isStockedFilterActive={isStockedFilterActive}
          isRefinementFilterActive={isRefinementFilterActive}
          productSku={item?.sku}
          isSale={isSale}
          host={host}
          totalNumRecs={totalNumRecs}
        />
        <FormControl fullWidth className={classes.add_to_cart_btn_form}>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => handleAddToCartClick(item, displayName!, qty)}
            disabled={false}
            fullWidth
            id="inStockProductCardActions_addToCart-btn"
            data-testid="add-to-cart-dialog-opener"
            className={classes.outlinedSecondary}
          >
            ADD TO CART
          </Button>
        </FormControl>
        <Grid container alignContent="center" justify="center">
          <Grid item>
            <RHStandardSelect
              data-testid={"in-stock-qty-select"}
              value={qty}
              dropdowntype={true}
              onChange={e => updateQty(item?.fullSkuId, Number(e.target.value))}
            >
              {Array.from({
                length: Number(qtyAvailable) + 1
              }).map((item, index) => (
                <option key={`qty-${index}`} value={index}>
                  {index}
                </option>
              ))}
            </RHStandardSelect>
          </Grid>
        </Grid>
      </div>
    </Grid>
  );
};

const PC = memoize(ProductCard);

export interface MultiSkuComponentWithRestrictions
  extends MultiSkuComponentDetails {
  associatedMultiSkuBundleId?: string;
  info?: ProductSkuInfo;
  restrictions?: ProductRestrictions;
  delivery?: ProductDeliveryInfo;
  inventory?: ProductSkuInventory;
}
export interface Refine {
  label?: string;
  name?: string;
  isSelected?: boolean;
}
export interface InStockProductsContentProps {
  items: Maybe<InstockItem[]>;
  productId: string;
  postalCode: string;
  onClose?: (event: {}, reason: "backdropClick" | "escapeKeyDown") => void;
  displayName: string;
  isLoading: boolean;
  metaProduct?: boolean;
  salePriceLabel: string;
  maxOrderQty: number;
  refine: Refine[];
  updateQueryAndRefetch: (index: number) => void;
  sort: SortMenu[];
  handleSorting: (index: number) => void;
  resetRefinement: () => void;
  saleSelected?: boolean;
}

export const InStockProductsContent: FC<
  InStockProductsContentProps
> = props => {
  const {
    onClose,
    items,
    productId,
    displayName,
    metaProduct,
    salePriceLabel,
    maxOrderQty,
    refine,
    updateQueryAndRefetch,
    sort,
    handleSorting,
    resetRefinement,
    saleSelected
  } = props;
  const env = useEnv();
  const mdUp = useMediaQuery<Theme>(theme => theme.breakpoints.up("md"));
  const classes = useStyles();
  const gridSpacing = useResponsiveGridSpacing();
  const containerGridSpacing = (gridSpacing * 2) as GridSpacing;
  const cookie = useIsoCookies([SIP_ID, PG_GRID_CHOICE, LAST_SORTMENU]);
  const [qty, setQty] = useState({});
  const { isConcierge } = useAppId();
  const [item, setItem] = useState<InstockItem>();
  const { pageContent } = usePageContent();
  const selectedRefinementContainer = React.useRef<HTMLDivElement | null>(null);

  const [gridColumns, setGridColumns] = useState<number>(() => {
    const defaultGridView =
      cookie[PG_GRID_CHOICE] && parseInt(cookie[PG_GRID_CHOICE]);
    return isConcierge
      ? DEFAULT_GRID_COL_CONCIERGE
      : defaultGridView
      ? defaultGridView
      : DEFAULT_GRID_COL;
  });
  const smDown = useMediaQuery<Theme>(theme =>
    theme.breakpoints.down(BREAKPOINT_SM)
  );
  const smUp = useMediaQuery<Theme>(theme => theme.breakpoints.up("sm"));
  const xlUp = useMediaQuery<Theme>(theme => theme.breakpoints.up("xl"));
  const [cartDialog, setCartDialog] = useState<AddToCartDialogProps | null>(
    null
  );

  const [multiSkuCompnents, setMultiSkuComponents] = useState<
    MultiSkuComponentDetails[] | null
  >(null);
  const [confirmed, setConfirmed] = useState(false);

  useEffect(() => {
    if (smDown == false && smUp == false && mdUp == false) {
      return;
    }
    const defaultGridView = smUp ? (xlUp ? 4 : 6) : 12;
    if (defaultGridView && [4, 6, 12].includes(defaultGridView)) {
      setGridColumns(defaultGridView);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cookie[PG_GRID_CHOICE], xlUp, smUp, smDown]);

  const typographyStyles = useTypographyStyles({
    keys: ["rhBaseBody1", "rhBaseCaption", "priceRangeSale", "rhBaseH2"]
  });

  const handleAddToCartClick = useCallback(
    (data, productDisplayName, qty = 1) => {
      setItem(data);
      if (metaProduct) {
        const payload = data?.bundleComponents?.map(component => {
          return {
            productId: component?.productId!,
            fullSkuId: component?.fullSkuId!,
            atgSkuId: component?.fullSkuId!,
            productDisplayName: component?.name!,
            quantity: qty
          };
        });
        if (payload) {
          setMultiSkuComponents(payload);
        }
      } else {
        setCartDialog({
          productId: productId,
          fullSkuId: data?.fullSkuId,
          atgSkuId: data?.skuId,
          productDisplayName: productDisplayName,
          qty
        });
      }
    },
    [productId, metaProduct, displayName, qty]
  );

  const handleQty = (sku, updatedQty) => {
    setQty({
      ...qty,
      [sku.replace(/\s/g, "")]: updatedQty
    });
  };
  const innerGrid = (
    <Grid container>
      {items?.map((item, index) => (
        <>
          <React.Fragment key={`innerGrid_item_${index}`}>
            <PC
              item={item}
              index={index}
              isStockedFilterActive={true}
              isRefinementFilterActive={true}
              gridColumns={gridColumns}
              totalNumRecs={items?.length}
              view={"vertical"}
              displayName={displayName}
              handleAddToCartClick={handleAddToCartClick}
              qty={qty[item?.fullSkuId.replace(/\s/g, "")] || 1}
              updateQty={handleQty}
              qtyAvailable={
                item.inventoryOnHand || maxOrderQty || item.qtyAvailable
              }
            />
          </React.Fragment>
        </>
      ))}

      {(!!cartDialog || multiSkuCompnents?.length) && item && (
        <AddToCartDialog
          open={!!cartDialog || multiSkuCompnents?.length}
          onClose={() => {
            setCartDialog(null);
            setConfirmed(false);
            setMultiSkuComponents(null);
          }}
          {...(cartDialog as any)}
          multiSkuComponents={multiSkuCompnents}
          qty={qty[item?.fullSkuId.replace(/\s/g, "")] || 1}
          salePriceLabel={salePriceLabel}
          onCompleted={cartDetails => {
            const pricing = item?.skuPriceInfo;
            analyticsLoader(a =>
              a.emitAnalyticsEvent(
                document.querySelector<HTMLInputElement>("#spa-root > *")!,
                a.EVENTS.GA4_ADD_TO_CART.INT_TYPE,
                {
                  cartdetails: {
                    cartId: cartDetails?.id,
                    cartCreationDate: cartDetails?.createdAt,
                    cartUpdationDate: cartDetails?.lastModifiedAt
                  },
                  item: {
                    name: displayName,
                    id: productId,
                    quantity: qty[item?.fullSkuId.replace(/\s/g, "")],
                    sku: item?.skuId ?? "",
                    pricing,
                    currencyCode: cartDetails?.cartPrice?.currencyCode,
                    color: item?.optionList?.find(
                      opt => opt?.optionType === "Color"
                    )?.label
                  },
                  store_number: "Website",
                  item_list_name: "RHLineItemCard"
                }
              )
            );
          }}
          setConfirmed={setConfirmed}
          confirmed={confirmed}
        />
      )}
    </Grid>
  );

  return (
    <div
      style={{
        overflowY: "auto",
        padding: 0,
        marginTop: 0,
        paddingRight: "40px"
      }}
      data-testid="dialog-instock-products"
    >
      <Typography
        variant={smDown ? "h2" : "h1"}
        className={classes.inStockTitle}
        data-testid="inStock-display"
      >
        {saleSelected ? pageContent.SALE : pageContent.IN_STOCK}
      </Typography>
      <Typography
        className={classNames(
          classes.inStockSubtitle,
          typographyStyles.rhBaseBody1
        )}
        style={{ fontSize: smDown ? "11px" : "" }}
      >
        {pageContent.IN_STOCK_ITEM_ARRIVAL}
      </Typography>
      <IconButton
        className={classNames(classes.icon, classes.rhr_icon_position)}
        color="inherit"
        onClick={event => onClose?.(event, "escapeKeyDown")}
        data-testid="dialog-title-close-button"
      >
        <RHCloseIcon className={classes.closeIcon} />
      </IconButton>
      <Grid
        container
        className={classNames(classes.refinementContainer)}
        //key={(reloadKey ? reloadKey : 123) + "refinementContainer"}
      >
        <div className={classes.refinementBar}>
          <Grid
            item
            //key={`${item?.refinements?.[0]?.label}-${index}`}
            className={classes.booleanRefinement}
          >
            <RefineMenuInstockV2
              refinementMenus={[]}
              refine={refine}
              updateQueryAndRefetch={updateQueryAndRefetch}
              showResetRefinement={true}
              selectedRefinementContainer={selectedRefinementContainer.current}
              resetRefinement={resetRefinement}
              sortMenu={sort}
              handleSorting={handleSorting}
            />
          </Grid>
        </div>
        <GridController
          gridColumns={gridColumns}
          setGridColumns={setGridColumns}
          sortMenu={sort}
          hide1Column={mdUp ? true : false}
          ssr={false}
          handleSorting={handleSorting}
        />
        <Grid item xs={12}>
          <div ref={selectedRefinementContainer}></div>
        </Grid>
      </Grid>
      <DialogContent
        style={{ padding: 0, marginTop: 0 }}
        className={classes.container}
      >
        <Grid container spacing={containerGridSpacing}>
          {props.isLoading ? (
            <RHRProductListSkeleton
              hasBanner={true}
              columns={gridColumns}
              numItems={12}
              disableProductInfoSkeleton
              animation={SKELETON_ANIMATION}
              data-testid="inStock-skeleton"
            />
          ) : (
            innerGrid
          )}
        </Grid>
      </DialogContent>
    </div>
  );
};

InStockProductsContent.defaultProps = {};

export interface InStockProductsDrawerProps {
  onClose?: (event: {}, reason: "backdropClick" | "escapeKeyDown") => void;
  postalCode: string;
  productId: string;
  displayName: string;
  metaProduct?: boolean;
  salePriceLabel: string;
  productLineItem: ProductLineItem;
  multiSkuComponents?: MultiSkuComponentWithRestrictions[];
  instockSelected?: boolean;
  saleSelected?: boolean;
}

export default memoize(
  (props: InStockProductsDrawerProps & Omit<DialogProps, "children">) => {
    const { userType } = useRhUserAtomValue();
    const env = useEnv();
    const FEATURE_TAILWIND_COMPONENTS = yn(env.FEATURE_TAILWIND_COMPONENTS);
    const DrawerComponent = FEATURE_TAILWIND_COMPONENTS
      ? TailwindDrawer
      : Drawer;
    const mdUp = useMediaQuery<Theme>(theme => theme.breakpoints.up("md"));
    const xlUp = useMediaQuery<Theme>(theme => theme.breakpoints.up("xl"));
    const lgUp = useMediaQuery<Theme>(theme => theme.breakpoints.up("lg"));
    const classes = useStyles();
    const {
      displayName,
      productId,
      postalCode,
      productLineItem,
      multiSkuComponents,
      instockSelected = false,
      saleSelected = false,
      ...rest
    } = props;
    const {
      previousState: { country }
    } = useUserPreferences();
    let [pli, setPli] = useState(productLineItem);

    const currencyCode = useCurrencyCode({
      postalCode,
      country,
      userType: userType
    });

    const siteId = useSite();
    const locale = useLocale();
    const [instockVariable, setInStockVariable] = useState({
      productId: productId,
      postalCode: postalCode,
      filter: saleSelected && !instockSelected ? "sale" : "instock",
      userType: userType,
      currencyCode,
      siteId,
      fromCount: "0",
      locale,
      toCount: DEFAULT_INSTOCK_ITEM_COUNT.toString(),
      nextgenDriven: true,
      sortBy: "priceAsc"
    });
    const [refine, setRefine] = useState([
      { isSelected: saleSelected, label: "Sale", name: "sale" },
      { isSelected: instockSelected, label: "In stock", name: "instock" }
    ]);
    const [sort, setSort] = useState([
      {
        value: "priceAsc",
        label: "Price Low to High",
        selected: true
      },
      {
        value: "priceDesc",
        label: "Price High to Low",
        selected: false
      }
    ]);
    useEffect(() => {
      setInStockVariable({ ...instockVariable, productId: props.productId });
    }, [props.productId]);

    const handleQueryUpdate = index => {
      let tempRefine = [...refine];
      tempRefine[index].isSelected = !tempRefine[index].isSelected;
      const selectedNames = tempRefine
        .filter(option => option.isSelected)
        .map(option => option.name);
      setRefine([...tempRefine]);
      if (
        selectedNames.length === 2 &&
        selectedNames.includes("sale") &&
        selectedNames.includes("instock")
      ) {
        setInStockVariable({
          ...instockVariable,
          filter: "instock"
        });
      } else {
        setInStockVariable({
          ...instockVariable,
          filter: selectedNames.join(", ")
        });
      }
    };

    const handleSorting = useCallback(
      (selected: number) => {
        let tempSort = [...sort].map((item, index) => ({
          ...item,
          selected: index === selected
        }));
        const selectedSort = tempSort.filter(option => option.selected);
        setSort([...tempSort]);

        setInStockVariable(prev => ({
          ...prev,
          sortBy: selectedSort[0].value
        }));
      },
      [sort, setSort, setInStockVariable]
    );
    const resetRefinement = () => {
      const tempRefine = [...refine].map(option => ({
        ...option,
        isSelected: false
      }));
      setRefine([...tempRefine]);
      setInStockVariable({
        ...instockVariable,
        filter: ""
      });
    };

    const {
      data: { instockItems: instockItemData } = {} as Query,
      fetchMore,
      loading: inStockItemLoading
    } = useQuery<Query>(queryInStockItems, {
      variables: instockVariable,
      skip: !rest.open
    });

    const items = instockItemData?.instockItems ?? [];
    const showViewMore = instockItemData?.showViewMore === "true";

    const getQtyValue = useMemo(() => {
      const inventory =
        pli?.sku?.inventory?.inventoryRemaining === null
          ? DEFAULT_MAX_QUANTITY
          : pli?.sku?.inventory?.inventoryRemaining ?? 0;
      if (
        pli?.sku?.info?.maxOrderQty !== undefined &&
        pli?.sku?.info?.maxOrderQty !== null &&
        pli?.sku?.info?.maxOrderQty > 0 &&
        pli?.sku?.info?.maxOrderQty < Number(inventory)
      ) {
        return pli?.sku?.info?.maxOrderQty;
      }
      if (
        rest?.metaProduct === true &&
        ["m", "M"].includes(pli?.sku?.inventory?.fullSkuId?.[0])
      ) {
        return multiSkuComponents?.reduce(
          (minInventory, multiSkuComponent) =>
            Number(multiSkuComponent?.inventory?.inventoryRemaining) <
            Number(minInventory)
              ? Number(multiSkuComponent?.inventory?.inventoryRemaining)
              : minInventory,
          DEFAULT_MAX_QUANTITY
        );
      }
      return !pli?.sku?.inventory?.inventoryRemaining
        ? DEFAULT_MAX_QUANTITY
        : inventory;
    }, [pli?.sku, multiSkuComponents]);

    const calWidth = () =>
      xlUp ? "1200px" : lgUp ? "900px" : mdUp ? "744px" : "100%";

    return (
      <DrawerComponent
        anchor="right"
        {...rest}
        data-testid="swatch-panel-dialog"
        BackdropProps={{ invisible: true }}
        className={yn(env.FEATURE_RHR) ? classes.removeBlur : ""}
        PaperProps={{
          style: {
            width: calWidth(),
            backdropFilter: yn(env.FEATURE_RHR) ? "none" : "inherit",
            padding: "50px 0px 40px 40px"
          }
        }}
      >
        <InStockProductsContent
          onClose={rest.onClose}
          postalCode={postalCode}
          items={items}
          productId={productId}
          displayName={displayName}
          isLoading={inStockItemLoading}
          metaProduct={rest?.metaProduct}
          salePriceLabel={rest?.salePriceLabel}
          maxOrderQty={Number(getQtyValue)}
          refine={refine}
          updateQueryAndRefetch={handleQueryUpdate}
          sort={sort}
          handleSorting={handleSorting}
          resetRefinement={resetRefinement}
          saleSelected={saleSelected}
        />
      </DrawerComponent>
    );
  }
);
