import { DialogProps } from "@material-ui/core/Dialog";
import classNames from "classnames";
import Drawer from "component-drawer";
import RHDialogTitle from "component-rh-dialog-title";
import RHDivider from "component-rh-divider";
import SwatchGroups, { SwatchGroupsProps } from "component-swatch-groups";
import TailwindDrawer from "@RHCommerceDev/component-tailwind-drawer";
import { usePageContent } from "customProviders/LocationProvider";
import { useEnv } from "hooks/useEnv";
import useResponsiveGridSpacing from "hooks/useResponsiveGridSpacing";
import useTypographyStyles from "hooks/useTypographyStyles";
import React, { FC, useCallback } from "react";
import {
  Container,
  DialogContent,
  Grid,
  GridSpacing,
  Theme,
  Typography,
  useMediaQuery
} from "utils/material-ui-core";
import memoize from "utils/memoize";
import yn from "yn";

export interface SwatchPanelDialogContentProps {
  onClose?: (event: {}, reason: "backdropClick" | "escapeKeyDown") => void;
  product: Product;
  stockedSwatchesLength: number;
  customSwatchesLength: number;
  groupMaterial: string;
  template: "inline" | "panel" | "split-panel";
  selectedSwatches: {
    [groupMaterial: string]: ProductSwatch;
  };

  selectedOptionsFromLineItem?: { [key: string]: any };
  onSwatchClick: SwatchGroupsProps["onClick"];
}

export const SwatchPanelDialogContent: FC<SwatchPanelDialogContentProps> = ({
  onClose,
  product,
  stockedSwatchesLength,
  customSwatchesLength,
  groupMaterial,
  selectedSwatches,
  selectedOptionsFromLineItem,
  onSwatchClick
}) => {
  const { pageContent } = usePageContent();
  const mdUp = useMediaQuery<Theme>(theme => theme.breakpoints.up("md"));
  const typographyClasses = useTypographyStyles({
    keys: ["rhBaseH3", "rhBaseBody1", "uppercaseText", "rhBaseCaption"]
  });
  const gridSpacing = useResponsiveGridSpacing();
  const containerGridSpacing = (gridSpacing * 2) as GridSpacing;
  const onSwatchClickCb = useCallback(
    data => {
      onSwatchClick?.(data);
    },
    [onSwatchClick]
  );
  return (
    <>
      <RHDialogTitle
        solidWhite
        title={!mdUp ? `${groupMaterial} Options` : ""}
        onClose={event => onClose?.(event, "escapeKeyDown")}
        style={{ background: "#fff" }}
      />

      <DialogContent
        style={{ overflowY: "auto", padding: 0, marginTop: 0 }}
        className={"mt-[16px]"}
      >
        <Container className={"!bg-[#ffffff] xl:!px-[40px]"}>
          <Grid container spacing={containerGridSpacing}>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                {!!stockedSwatchesLength && (
                  <>
                    <Grid item xs={12}>
                      {
                        <Typography
                          style={{ textTransform: "uppercase" }}
                          className={typographyClasses.rhBaseH3}
                        >
                          <span className={typographyClasses.uppercaseText}>
                            {stockedSwatchesLength} {pageContent?.IN_STOCK_ONLY}{" "}
                            {groupMaterial}
                            {groupMaterial.toUpperCase() === "FINISH"
                              ? "ES"
                              : "S"}
                          </span>
                        </Typography>
                      }
                      {product.layout === "outdoor" && (
                        <Typography
                          className={classNames([
                            typographyClasses.rhBaseCaption,
                            "!mt-[8px]"
                          ])}
                          data-testid="swatch-panel-dialog-delivery-message"
                        >
                          {pageContent?.SWATCH_DELIVERY_MESSAGE}
                        </Typography>
                      )}
                    </Grid>
                    <Grid item xs={12}>
                      <SwatchGroups
                        listItemClassName={`[&:first-child]:!mt-[16px] !mt-[8px]`}
                        productSwatchImage={product.productSwatchImage}
                        swatchGroups={product.swatchData.swatchGroups}
                        swatches={["stockedSwatches"]}
                        selectedSwatches={selectedSwatches}
                        selectedOptionsFromLineItem={
                          selectedOptionsFromLineItem
                        }
                        onClick={onSwatchClickCb}
                        detail={!mdUp}
                      />
                    </Grid>
                  </>
                )}
                {!!stockedSwatchesLength && !!customSwatchesLength && (
                  <Grid item xs={12}>
                    <RHDivider />
                  </Grid>
                )}
                {!!customSwatchesLength && (
                  <>
                    <Grid item xs={12}>
                      {
                        <Typography
                          className={typographyClasses.rhBaseH3}
                          variant="h3"
                        >
                          <span className={typographyClasses.uppercaseText}>
                            {customSwatchesLength} {pageContent.SPECIAL_ORDER}{" "}
                            {groupMaterial}
                            {groupMaterial.toUpperCase() === "FINISH"
                              ? "ES"
                              : "S"}
                          </span>
                        </Typography>
                      }
                    </Grid>
                    <Grid item xs={12}>
                      <SwatchGroups
                        listItemClassName={
                          "[&:first-child]:!mt-[16px] !mt-[8px]"
                        }
                        productSwatchImage={product.productSwatchImage}
                        swatchGroups={product.swatchData.swatchGroups}
                        swatches={["customSwatches"]}
                        selectedSwatches={selectedSwatches}
                        selectedOptionsFromLineItem={
                          selectedOptionsFromLineItem
                        }
                        onClick={onSwatchClickCb}
                        detail={!mdUp}
                      />
                    </Grid>
                  </>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </DialogContent>
    </>
  );
};

SwatchPanelDialogContent.defaultProps = {};

export default memoize(
  ({
    product,
    stockedSwatchesLength,
    customSwatchesLength,
    groupMaterial,
    template,
    selectedSwatches,
    selectedOptionsFromLineItem,
    onSwatchClick,
    ...rest
  }: SwatchPanelDialogContentProps & Omit<DialogProps, "children">) => {
    const mdUp = useMediaQuery<Theme>(theme => theme.breakpoints.up("md"));

    const env = useEnv();
    const FEATURE_TAILWIND_COMPONENTS = yn(env.FEATURE_TAILWIND_COMPONENTS);
    const DrawerComponent = FEATURE_TAILWIND_COMPONENTS
      ? TailwindDrawer
      : Drawer;
    return (
      <DrawerComponent
        anchor="right"
        {...rest}
        data-testid="swatch-panel-dialog"
        BackdropProps={{ invisible: true }}
        className={
          yn(env.FEATURE_RHR) ? "[&>MuiBackdrop-root]:!opacity-0 " : ""
        }
        PaperProps={{
          ...rest.PaperProps,
          style: {
            width: mdUp ? "calc(50% - 20px)" : "100%",
            backdropFilter: yn(env.FEATURE_RHR) ? "none" : "inherit",

            ...rest.PaperProps?.style
          }
        }}
      >
        <SwatchPanelDialogContent
          onClose={rest.onClose}
          product={product}
          stockedSwatchesLength={stockedSwatchesLength}
          customSwatchesLength={customSwatchesLength}
          groupMaterial={groupMaterial}
          template={template}
          selectedSwatches={selectedSwatches}
          selectedOptionsFromLineItem={selectedOptionsFromLineItem}
          onSwatchClick={onSwatchClick}
        />
      </DrawerComponent>
    );
  }
);
