import React, { FC, useRef, useEffect, useCallback } from "react";
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
  IconButton,
  Theme,
  makeStyles,
  createStyles,
  useTheme
} from "utils/material-ui-core";
import { DialogProps } from "@material-ui/core/Dialog";
import { RHFormTextField } from "component-rh-text-field";
import useState from "hooks/useState";
import RHCloseIcon from "icon-close";
import useAppData from "hooks/useAppData";
import EventEmitter from "utils-event-emitter";
import { getCountryCodeFromPostalCode } from "utils/postalcode-validation";
import { getCookie } from "utils/cookies";
import { parseUserContextCookie } from "utils/parseUserContextCookie";
import { UserContext } from "types";
import { useForm } from "react-hook-form";
import RHSpinner from "component-rh-spinner";
import yn from "yn";
import { useEnv } from "hooks/useEnv";
import useErrorDialog from "hooks/useErrorDialog";
import useTypographyStyles from "hooks/useTypographyStyles";
import { useIsoCookies } from "hooks/useIsoCookies";
import classNames from "classnames";
import { useUpdateCart } from "hooks/useUpdateCart";
import { useCookiesWithPermission } from "hooks/useCookiesWithPermission";
import { POSTAL_CODE_COOKIE } from "utils/constants";
import { usePageContent } from "customProviders/LocationProvider";
import { useRhUserAtomValue } from "hooks/atoms";
import { useUserSessionSetAtom } from "hooks/atoms";

export interface PostalCodeDialogProps {
  value?: string;
  onUpdate: (postalCode: string) => void;
  onClose?: (event: {}) => void;
  onConfirm?: () => void;
  error: string;
  setInStockDrawer?: React.Dispatch<React.SetStateAction<boolean | undefined>>;
  loading?: boolean;
  handleInStockDrawer?: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialogTitle: { padding: 0 },
    dialogContentContainer: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      width: "calc(100% - 80px)",
      margin: "0 auto"
    },
    formContainer: {
      maxWidth: theme.typography.pxToRem(250)
    },
    MuiButton: {
      root: {
        color: "#999999"
      }
    },
    containedPrimary: {
      color: "#FFFFFF",
      backgroundColor: "#000000",
      "&:hover": {
        backgroundColor: "#404040",
        borderColor: "#404040"
      }
    }
  })
);

export const PostalCodeDialog: FC<PostalCodeDialogProps> = ({
  value,
  onUpdate,
  onClose,
  error,
  loading = false
}) => {
  const { pageContent } = usePageContent();
  const { control, handleSubmit } = useForm({
    defaultValues: {
      postalCode: value || ""
    }
  });
  const localClasses = useStyles();
  const typographyStyles = useTypographyStyles({
    keys: ["rhBaseH4", "rhBaseBody1"]
  });

  /** Programmatic auto focus */
  const inputRef = useRef<HTMLInputElement>(null);
  useEffect(() => {
    if (inputRef?.current?.focus) inputRef?.current?.focus();
  }, []);

  return (
    <>
      <DialogTitle
        classes={{ root: localClasses.dialogTitle }}
        disableTypography
      >
        <IconButton
          style={{
            position: "absolute",
            top: 0,
            right: 0
          }}
          onClick={event => !!onClose && onClose(event)}
        >
          <RHCloseIcon />
        </IconButton>
      </DialogTitle>
      {loading && <RHSpinner style={{ zIndex: 999 }} />}
      <DialogContent className={localClasses.dialogContentContainer}>
        <Typography
          className={typographyStyles.rhBaseH4}
          style={{ textAlign: "center" }}
        >
          {pageContent.ENTER_POSTALCODE_LABEL}
        </Typography>
        <form
          className={localClasses.formContainer}
          onSubmit={handleSubmit(({ postalCode }) => {
            onUpdate(postalCode);
          })}
        >
          <RHFormTextField
            control={control}
            disabled={loading}
            name="postalCode"
            placeholder="Postal Code"
            fullWidth
            inputProps={{
              ref: inputRef,
              // Ensure that postal codes are uppercase
              onInput: e => {
                (e.target as EventTarget & HTMLInputElement).value = `${
                  (e.target as EventTarget & HTMLInputElement).value
                }`?.toUpperCase();
              }
            }}
            className="js-postal-code pdp__modal__input--zip"
            id="postal-code-mobile"
          />
          <Button
            variant="contained"
            color="primary"
            data-testid={"submit-postal"}
            type="submit"
            disabled={loading}
            fullWidth
            autoFocus
            className={classNames([
              "button--primary js-submit-postal",
              localClasses.containedPrimary
            ])}
          >
            {pageContent.CONFIRM_BUTTON_LABEL}
          </Button>
        </form>
        {!!error && (
          <Typography
            style={{ paddingTop: 20 }}
            className={typographyStyles.rhBaseBody1}
            color="error"
            align="center"
          >
            {error}
          </Typography>
        )}
      </DialogContent>
    </>
  );
};

PostalCodeDialog.defaultProps = {};

export interface CurrencyWarningDialogProps {
  currencyCode: string;
  onClose?: (event: {}) => void;
}

export const CurrencyWarningDialog: FC<CurrencyWarningDialogProps> = ({
  currencyCode,
  onClose
}) => {
  const message = {
    USA: `
      The shipping address you've entered is in the US.
      With your acceptance, the items in your cart will be updated with US
      prices and your payment will be in US dollars at checkout.
      If you do not want to ship to the US and pay in US dollars,
      please enter a shipping address in Canada.
    `,
    CAD: `
      The shipping address you've entered is in Canada.
      With your acceptance, the items in your cart will be updated with Canadian
      prices and your payment will be in Canadian dollars at checkout.
      Canadian prices reflect adjustments for factors including, but not limited to,
      exchange rate, duties, brokerage and operating costs.
      If you do not want to ship to Canada and pay in Canadian dollars,
      please enter a shipping address in the United States.
    `
  } as { [key: string]: string };

  const theme = useTheme();
  const classes = useStyles();
  const typographyStyles = useTypographyStyles({
    keys: ["rhBaseBody1"]
  });

  return (
    <>
      <DialogTitle disableTypography id="dialog-postal-code-currency-warning">
        <IconButton
          style={{
            position: "absolute",
            top: 0,
            right: 0
          }}
          onClick={onClose}
        >
          <RHCloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Typography
          className={typographyStyles.rhBaseBody1}
          style={{
            paddingBottom: theme.spacing(2)
          }}
        >
          {message[currencyCode]}
        </Typography>
        <Button
          autoFocus
          color="primary"
          fullWidth
          variant="contained"
          className={classes.containedPrimary}
          onClick={onClose}
        >
          OK
        </Button>
      </DialogContent>
    </>
  );
};

export default ({
  open,
  onConfirm,
  setInStockDrawer,
  value,
  onClose,
  ...rest
}: Omit<PostalCodeDialogProps, "onUpdate" | "error"> &
  Omit<DialogProps, "children">) => {
  const { app, setApp } = useAppData();
  const cookieRef = useRef<UserContext>();
  const [error, setError] = useState("");
  const [hasCurrencyChanged, setHasCurrencyChanged] = useState(false);
  const postalCodeRef = useRef("");
  const { showError } = useErrorDialog();
  const pc = useIsoCookies(["pc"])?.pc;

  const closeDialog = useCallback(
    (reason = "backdropClicked") => {
      if (!!onClose) {
        onClose({}, reason);
      }
    },
    [onClose]
  );

  const env = useEnv();
  const setUserSession = useUserSessionSetAtom();
  const { userType } = useRhUserAtomValue();
  const enable = yn(env.FEATURE_PDP_CART_BROKER);

  useEffect(() => {
    if (open) {
      cookieRef.current = parseUserContextCookie(getCookie("userContext"));
    }
  }, [open]);

  useEffect(() => {
    if (!open) {
      setError("");
    }
  }, [open, setError]);

  const { updateCart, loading: loadingUpdateCart } = useUpdateCart({
    callerId: "POSTAL_CODE_DIALOG",
    onError: showError,
    onCompleted: async data => {
      if (yn(env.FEATURE_ADD_ITEM_CREATE_CART))
        setUserSession(prev =>
          prev
            ? {
                ...prev,
                currentCartId: data?.updateCart?.id
              }
            : prev
        );
    }
  });

  const { setCookieWrapper } = useCookiesWithPermission();

  const updatePostalCodeWrapper = async (postalCode: string) => {
    setCookieWrapper(POSTAL_CODE_COOKIE, postalCode);

    await setApp({
      ...app,
      postalCode: postalCodeRef.current
    });

    if (enable) {
      await updateCart({
        postalCode: postalCodeRef.current,
        country: getCountryCodeFromPostalCode(postalCodeRef.current) ?? ""
      });
    }

    const latestCookie = parseUserContextCookie(getCookie("userContext"));
    // handleInStockDrawer();
    const didChanged =
      cookieRef?.current?.currencyCode !== latestCookie.currencyCode;

    cookieRef.current = latestCookie;
    EventEmitter.dispatch(`zipcode_change_dialog`, true);

    if (userType === "CONTRACT") {
      closeDialog("confirmClose");
    } else if (userType !== "CONTRACT") {
      if (didChanged) {
        setHasCurrencyChanged(didChanged);
      } else {
        closeDialog("confirmClose");
      }
    }
  };

  return (
    <Dialog
      fullWidth
      open={open}
      id={"dialog-postal-code"}
      {...rest}
      onClose={onClose}
    >
      {(hasCurrencyChanged && (
        <CurrencyWarningDialog
          currencyCode={cookieRef?.current?.currencyCode || ""}
          onClose={onClose}
        />
      )) || (
        <PostalCodeDialog
          value={app.postalCode || pc}
          error={error}
          onClose={onClose}
          onUpdate={async newPostalCode => {
            postalCodeRef.current =
              newPostalCode.trim().length === 6
                ? newPostalCode.trim().replace(/^(.{3})(.*)$/, "$1 $2")
                : newPostalCode.trim();

            await updatePostalCodeWrapper(postalCodeRef?.current);

            !!onConfirm && onConfirm();
          }}
          loading={loadingUpdateCart}
        />
      )}
    </Dialog>
  );
};
