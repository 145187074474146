import React, { FC, MouseEvent } from "react";
import {
  Button,
  makeStyles,
  createStyles,
  Typography,
  Grid,
  Theme
} from "utils/material-ui-core";
import { useTheme } from "@material-ui/core";
import classNames from "classnames";
import useTypographyStyles from "hooks/useTypographyStyles";
import he from "he";
import { ProductSkuPriceInfoProps } from "component-product-sku-price-info/types";
import { usePageContent } from "customProviders/LocationProvider";
import { useRhUserAtomValue } from "hooks/atoms";
import { useKeycloak } from "utils/Keycloak/KeyCloak";
import RHRPriceDisplay from "@RHCommerceDev/component-rh-price-range-display/RHRPriceDisplay";
import { getPriceUserType } from "component-product-grid";

export interface SpecialOrderConfirmationProps {
  confirmationText: string;
  onConfirmed: (event: MouseEvent) => void;
  productDisplayName: string;
  options: (ProductAvailableOption | CustomOption)[];
  fullSkuId?: string;
  qty?: number;
  salePriceLabel?: string;
  pricing?: ProductSkuPriceInfoProps["data"];
  SPOAddonVariables?: any;
  setAddedFromPanel?: React.Dispatch<React.SetStateAction<boolean>>;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    MuiButton: {
      root: {
        color: "#999999"
      }
    },
    containedPrimary: {
      color: "#FFFFFF",
      backgroundColor: "#000000",
      "&:hover": {
        backgroundColor: "#404040",
        borderColor: "#404040"
      }
    }
  })
);

export const SpecialOrderConfirmation: FC<SpecialOrderConfirmationProps> = ({
  onConfirmed,
  salePriceLabel = "Sale",
  SPOAddonVariables,
  setAddedFromPanel
}) => {
  const theme = useTheme();

  const typographyClasses = useTypographyStyles({
    keys: [
      "skuList",
      "skuListColumn1",
      "skuListColumn2",
      "rhBaseH2",
      "rhBaseH3",
      "uppercaseText"
    ]
  });

  const { keycloak } = useKeycloak();
  const { userType } = useRhUserAtomValue();

  const classes = useStyles();
  const { pageContent } = usePageContent();
  const SPOConfirmationText = SPOAddonVariables?.filter(
    spoItem => spoItem?.spo === true
  )?.[0];

  const spoConfirmOnClick = e => {
    onConfirmed?.(e);
    setAddedFromPanel?.(false);
  };

  return (
    <div
      id={"dialog-add-to-cart_special-order-confirmation"}
      style={{
        minHeight: theme.spacing(44),
        paddingBottom: 80,
        overflow: "hidden"
      }}
    >
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography
            className={classNames([
              typographyClasses.rhBaseH2,
              typographyClasses.uppercaseText
            ])}
          >
            {pageContent?.SPECIAL_ORDER}
          </Typography>
          {keycloak?.authenticated && userType === "CONTRACT" ? (
            <Typography
              className={classNames([
                typographyClasses.rhBaseH3,
                typographyClasses.uppercaseText
              ])}
            >
              {pageContent?.SPO_CONFIRMATION?.CONTRACT_TERMS_OF_SALE}
            </Typography>
          ) : (
            <Typography
              className={classNames([
                typographyClasses.rhBaseH3,
                typographyClasses.uppercaseText
              ])}
            >
              {pageContent?.SPO_CONFIRMATION?.TERM_OF_SALE}
            </Typography>
          )}
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            {SPOAddonVariables?.length && (
              <Grid item xs={12}>
                <Typography
                  component="p"
                  paragraph
                  dangerouslySetInnerHTML={{
                    __html:
                      pageContent?.SPO_CONFIRMATION_PRE_BILL_MESSAGE.toLocaleUpperCase() ??
                      ""
                  }}
                />
              </Grid>
            )}
          </Grid>
        </Grid>
        <Grid item xs={12}>
          {SPOAddonVariables?.length &&
            SPOAddonVariables?.filter(spoItem => spoItem?.spo === true)?.map(
              (item, index) => (
                <>
                  <Grid
                    item
                    xs={12}
                    style={{ paddingTop: "20px", paddingBottom: "24px" }}
                  >
                    <Typography
                      className={typographyClasses.rhBaseH3}
                      dangerouslySetInnerHTML={{
                        __html: item?.displayName?.toLocaleUpperCase()
                      }}
                    ></Typography>
                  </Grid>

                  {item?.fullSkuId && (
                    <Grid item container spacing={2} key={`item-fullSkuId`}>
                      <Grid item xs={3}>
                        <Typography
                          className={classNames(
                            typographyClasses.skuList,
                            typographyClasses.skuListColumn1
                          )}
                        >
                          {pageContent?.SPO_CONFIRMATION?.ITEM}
                        </Typography>
                      </Grid>
                      <Grid item xs={9}>
                        <Typography
                          className={classNames(
                            typographyClasses.skuList,
                            typographyClasses.skuListColumn2
                          )}
                        >
                          {item?.fullSkuId}
                        </Typography>
                      </Grid>
                    </Grid>
                  )}
                  {item?.ProductAddonOptions &&
                    item?.ProductAddonOptions.map((option, index) => (
                      <Grid
                        item
                        container
                        spacing={2}
                        key={`productOption-${option?.value}`}
                      >
                        <Grid item xs={3}>
                          <Typography
                            className={classNames(
                              typographyClasses.skuList,
                              typographyClasses.skuListColumn1
                            )}
                          >
                            {option?.name || option?.type}
                          </Typography>
                        </Grid>
                        <Grid item xs={9}>
                          <Typography
                            className={classNames(
                              typographyClasses.skuList,
                              typographyClasses.skuListColumn2
                            )}
                          >
                            {he.decode(option?.value)}
                          </Typography>
                        </Grid>
                      </Grid>
                    ))}
                  {item?.qty && (
                    <Grid item container spacing={2} key={`item-quantity`}>
                      <Grid item xs={3}>
                        <Typography
                          className={classNames(
                            typographyClasses.skuList,
                            typographyClasses.skuListColumn1
                          )}
                        >
                          {pageContent?.SPO_CONFIRMATION?.QUANTITY}
                        </Typography>
                      </Grid>
                      <Grid item xs={9}>
                        <Typography
                          className={classNames(
                            typographyClasses.skuList,
                            typographyClasses.skuListColumn2
                          )}
                        >
                          {item?.qty}
                        </Typography>
                      </Grid>
                    </Grid>
                  )}
                  {item?.pricing && (
                    <Grid item container spacing={2} key={`item-pricing`}>
                      <Grid item xs={3}>
                        <Typography
                          className={classNames(
                            typographyClasses.skuList,
                            typographyClasses.skuListColumn1
                          )}
                        >
                          {pageContent?.SPO_CONFIRMATION?.PRICE}
                        </Typography>
                      </Grid>
                      <Grid item xs={9}>
                        <RHRPriceDisplay
                          listPrice={item?.pricing?.listPrice!}
                          memberPrice={
                            item?.displayName
                              ?.toLocaleLowerCase()
                              .includes("gift card")
                              ? item?.pricing?.listPrice
                              : getPriceUserType(userType!, item?.pricing)!
                          }
                          topLabel={pageContent?.CONFIGURES_PRICE}
                          isSaleFilterEnabled={false}
                          onSale={item?.pricing?.onSale!}
                          skulowestMemberPrice={
                            getPriceUserType(userType!, item?.pricing)!
                          }
                          showSaleMessage={false}
                          userType={userType! || ""}
                          pageContent={pageContent}
                          variant={"small"}
                          centerAlignFlag={false}
                          isSkuLevel={true}
                          source={"spo"}
                          hideCss={true}
                        />
                      </Grid>
                    </Grid>
                  )}
                </>
              )
            )}
        </Grid>

        <Grid item xs={12}>
          <Button
            id="specialOrderConfirmation_addToCart-btn"
            variant="contained"
            color="primary"
            onClick={spoConfirmOnClick}
            className={classes.containedPrimary}
            fullWidth
          >
            {pageContent?.AGREE_AND_ADD_TO_CART}
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};

SpecialOrderConfirmation.defaultProps = {};

export default SpecialOrderConfirmation;
