import { usePageContent } from "customProviders/LocationProvider";
import React, { FC } from "react";
import { Typography, useTheme } from "utils/material-ui-core";

export interface AddToGiftRegistryErrorMessageProps {}

export const AddToGiftRegistryErrorMessage: FC<
  AddToGiftRegistryErrorMessageProps
> = props => {
  const theme = useTheme();
  const { pageContent } = usePageContent();

  return (
    <section style={{ minHeight: theme.spacing(14) }}>
      <Typography
        style={{ paddingTop: theme.spacing(4) }}
        variant="body2"
        color="error"
      >
        {pageContent?.SOMETHING_WRONG_TRY_AGAIN}
      </Typography>
    </section>
  );
};

AddToGiftRegistryErrorMessage.defaultProps = {};

export default AddToGiftRegistryErrorMessage;
